import { useOutletContext } from "react-router-dom"
import { Ly3dWorkprizeStyle } from "./style"
import { ContestDetailResponse } from "types/contest"

const LybzWorkprize = () => {
    const { detail } = useOutletContext<{ detail: ContestDetailResponse }>()
    const contestJSON = JSON.parse(detail?.pageCfg || "{}")
    const list: { title: string, id: number, work: { title: string, username: string, userId: string, id: string, avatarUrl: string, coverUrl: string }[] }[] = contestJSON.workprize || []

    return <Ly3dWorkprizeStyle>
        {
            detail && <div className="workprize">
                {
                    list.map(item => {
                        return <div key={item.id}>

                            <div className="title">
                                <img className="arrow" src="https://cdn-prd.ggac.com/ggac/submatch/ydbz/ydbz-title-icon2.png" alt="" />
                                <div className="title-word">
                                    {item.title}
                                </div>
                                <img className="arrow" src="https://cdn-prd.ggac.com/ggac/submatch/ydbz/ydbz-title-icon3.png" alt="" />
                            </div>

                            <div className={`work ${item.work.length > 2 ? 'work-5' : ''}`}>
                                {
                                    item.work && item.work.length > 0 && item.work.map(work => {
                                        return <div className="work-item" key={work.id}>
                                            <a href={`https://www.ggac.com/work/detail/${work.id}`} target="_blank" className="cover" style={{ backgroundImage: `url(${work.coverUrl})` }} />
                                            <div className="info-wrap">
                                                <div className="info">
                                                    <a href={`https://www.ggac.com/user/${work.userId}`} target="_blank" className="user_avatar_image" style={{ backgroundImage: `url(${work.avatarUrl})` }}></a>
                                                    <div className="user-name">
                                                        <a href={`https://www.ggac.com/user/${work.userId}`} target="_blank">
                                                            {work.username}
                                                        </a> -
                                                        <a href={`https://www.ggac.com/work/detail/${work.id}`} target="_blank">「{work.title}」</a>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    })
                                }
                            </div>
                        </div>
                    })
                }
            </div>
        }
    </Ly3dWorkprizeStyle>
}

export default LybzWorkprize