import UniCardLayout from '../../layout';
import PrizeIcons from '../../common/prize-icons';
import WorkIcons from '../../common/hot-icons';
import { getWorkTidUrl, translateCardCategoryText } from 'utils/functions/common';
import CardTitleRender from '../../common/card-title/work';
import MediaIconsWork from '../../common/media-icons/work';
import { WorkContestListItem } from 'types/contest';
import ContestWorkCardStatus from './ContestWorkCardStatus';
import ContestVoteCard from './contestVote';
import { WorkExhibitionListItem } from 'types/user/work';


function ContestWorkCard({ item, canVoteTotal, isShowTicket = true, selectVoteItem, t_id }: { t_id?: number, item: WorkContestListItem | WorkExhibitionListItem, canVoteTotal: number, isShowTicket?: boolean, selectVoteItem: (item: any) => void }) {

    const UiObject = {
        coverUrl: item.coverUrl,
        title: <CardTitleRender item={item} />,
        subtitle: <span> {item.dictMap.mediaCategory} |  {translateCardCategoryText(item.categoryList)}</span>,
        view: item.viewCount,
        hot: item.hot,
        // date: item.lastSubmitTime,
        userName: item.userInfo?.username,
        avatarUrl: item.userInfo.avatarUrl,
        userLink: `/user/${item.userId}`,
        icons: WorkIcons({ item }),
        workLink: getWorkTidUrl(item.id, t_id),
        rightTopNode: PrizeIcons({ item }),
        rightBottomNode: MediaIconsWork({ item }),
        leftBottomNode: <ContestWorkCardStatus item={item}></ContestWorkCardStatus>,
        bottomNode: <ContestVoteCard item={item} isShowTicket={isShowTicket} canVoteTotal={canVoteTotal} selectVoteItem={selectVoteItem} />,
        isPublish: true
    }

    return UniCardLayout({ item: UiObject });
}

export default ContestWorkCard
