import { WorkExhibitionListItem } from "types/user/work";
import { ContestWorkCardStatusStyle } from "./style";
import { WorkContestListItem } from "types/contest";

function ContestWorkCardStatus({ item }: { item: WorkContestListItem | WorkExhibitionListItem }) {
  return (
    <ContestWorkCardStatusStyle>
         {item.creatorCount >0 && (
            <div className="subtitle">共创</div>
       )}
      {item.isContest === 1 && item.publishType === 1 && (
        <div className="status">阶段性作品</div>
      )}
    
    </ContestWorkCardStatusStyle>
  );
}

export default ContestWorkCardStatus;
