import styled from "styled-components";

export const SchoolApplicantStyle = styled.div`
    @font-face {
        font-family: 'AlibabaPuHuiTiR';
        src: url('https://cdn-prd.ggac.com/ggac/font/AlibabaPuHuiTi.ttf');
    }
    font-family: 'AlibabaPuHuiTiR';
    .tip {
        margin-bottom: ${props => props.theme.space.margin}px;
    }
    .school-btn {
        display: block;
        margin: ${props => props.theme.space.margin}px auto 0;
    }
    .tip {
        font-size: 18px;
        text-align: center;
        padding-top: ${props => props.theme.space.margin}px;
    }

    .tip2 {
        font-size: 16px;
        text-align: center;
        margin: ${props => props.theme.space.margin}px auto;
    }
`