import styled from "styled-components";

export const InviteCodeStyle = styled.div`
    @font-face {
  font-family: 'ysbt-hei';
  src: url('~@/assets/fonts/ysbt-hei.ttf');
}

.introduce-wrapper {
  width: 100%;
  height: 100%;
  background: rgba($color: #000000, $alpha: 0.6);
  z-index: 9;
  position: fixed;
  left: 50%;
  top: 25px;
  transform: translateX(-50%);
  display: flex;
  align-items: center;
  justify-content: center;

  &-cnt {
    width: 640px !important;
    height: auto !important;
    background: url('https://cdn.ggac.com/img/ylzy-mobie-invitecode-bg.png') center center no-repeat;
    background-size: cover;
    position: relative;
    text-align: center;
    padding: 50px 0;

    .title {
      font-size: 24px;
      font-family: FZLTZHK;
      color: #002a5f;
      margin-bottom: 35px;
    }

    .content {
      font-size: 14px;
      color: #002a5f;
      line-height: 30px;
    }

    .btn {
      background: url("https://cdn.ggac.com/img/ylzy-pc-four-btn.png") center center no-repeat;
      background-size: 100% 100%;
      width: 231px;
      height: 60px;
      display: flex;
      justify-content: center;
      align-items: center;
      font-size: 22px;
      font-weight: bold;
      letter-spacing: 2px;
      color: #e1e4ed;
      font-family: 'XinYiJiXiangSong';
      cursor: pointer;
      margin: 38px auto 0;
    }

    .introduce-close {
      color: #002a5f;
      position: absolute;
      right: 24px;
      top: 24px;
      font-size: 26px;
      cursor: pointer;
    }

    .invitecode-line {
      width: 548px;
      margin: 50px 0;
    }

    .invite-count {
      font-size: 17px;
      color: #002a5f;
      line-height: 40px;
    }

    .invite-list {
      display: flex;
      flex-wrap: wrap;
      padding: 0 40px;
      // width: 589px;
      margin: 0 auto;

      &-item {
        width: 85px;
        margin-right: 32px;
        font-size: 14px;
        color: #002a5f;
        line-height: 40px;
        overflow: hidden;
        text-overflow: ellipsis;
        white-space: nowrap;
      }

      &-item:nth-child(5n) {
        margin-right: 0;
      }

      .more {
        font-weight: bold;
        font-size: 15px;
      }
    }
  }
}
`