import { YlzyRuleStyle } from "./style"

const YlzyRule = () => {

    const toLook = () => {
        
    }

    return <YlzyRuleStyle>
        <div className="rule">
            <div className="title">
                <div className="number">壹</div>
                <div className="content">
                    <img className="title-left" src="https://cdn.ggac.com/img/ylzy-pc-title-left.png" alt=""/>
                    <div className="word">
                        创作要求
                    </div>
                    <img className="title-right" src="https://cdn.ggac.com/img/ylzy-pc-title-left.png" alt=""/>
                </div>
            </div>
            <div className="list one">
                <div>
                    参赛者必须根据大赛主题，自主构想国风妖灵的生存环境（沙漠、海域、森林等），外貌锚点，基础性格，元素属性（风、水、火等），战斗元素（可以是身体部位、器具等，具体不限），并创作与之相匹配的一组3-5个国风妖灵角色设计，性别不限；
                </div>
                <div>角色设计应该特点鲜明、风格统一，至少包含一处明显妖化特征，契合赛事主题，并能够充分展现创作者所设想的美术形态及品质；</div>
                <div>每张提交作品画幅长宽边均不得小于2000px，像素建议300DPI以上；</div>
                <div>符合潮流的审美标准，具备国风文化和韵味，彰显创作者的设计和创造力；</div>
                <div>角色身高比例不限，Q版除外（小于等于2.5头身），美术风格请参照<span onClick={toLook}>【作品参考】</span>；</div>
                <div>不得包含对其它品牌或IP造成侵权的内容，不可使用AI辅助。</div>
            </div>
            <div className="title">
                <div className="number">贰</div>
                <div className="content">
                    <img className="title-left" src="https://cdn.ggac.com/img/ylzy-pc-title-left.png" alt=""/>
                    <div className="word">
                        提交要求
                    </div>
                    <img className="title-right" src="https://cdn.ggac.com/img/ylzy-pc-title-left.png" alt=""/>
                </div>
            </div>
            <div className="list two">
                <div>1张包含所有角色（3-5个）的高清晰度的集体展示效果图；</div>
                <div>需提交3-5张已完成的单体角色立绘图（每个角色各1张）；</div>
                <div>创作思路描述，可在“作品描述”中填写（如有）；</div>
                <div>若干张已完成的单体角色设计的三视图和其它细节拆分说明（如有）；</div>
                <div>角色身高比例不限，Q版除外（小于等于2.若干张创作过程中各阶段的过程稿，包括但不限于概念草图、轮廓剪影、线稿色稿等；</div>
            </div>
            <div className="title">
                <div className="number">叁</div>
                <div className="content">
                    <img className="title-left" src="https://cdn.ggac.com/img/ylzy-pc-title-left.png" alt=""/>
                    <div className="word">
                        参赛规则
                    </div>
                    <img className="title-right" src="https://cdn.ggac.com/img/ylzy-pc-title-left.png" alt=""/>
                </div>
            </div>
            <div className="list three">
                <div>参赛者需以个人形式参赛，每位参赛者可多幅参赛作品，但一副参赛作品仅可提交一次；</div>
                <div>大赛奖项将以作品为评选单位，参赛如创作多幅参赛作品，有机会获得多个奖项；</div>
                <div>创作过程中，可通过GGAC官网分阶段发布已完成的阶段性作品，以显示创作进度。（阶段性不属于正式参赛作品，不可接受海选投票及一票晋级。）</div>
                <div>本赛事任投稿最终提交时间为【2023年11月30日23：59】；</div>
                <div>本赛事不接受任何形式的AI作品投递；</div>
                <div>请妥善保存作品源文件，所有获奖作品届时必须提交源文件用于展示、影印、出版等；源文件必须进行分层，分层至少需要区分每个角色，场景，重要元素等，其余必要的分层效果参赛者可以自行处理。</div>
            </div>
            <div className="title">
                <div className="number">肆</div>
                <div className="content">
                    <img className="title-left" src="https://cdn.ggac.com/img/ylzy-pc-title-left.png" alt=""/>
                    <div className="word">
                        版权说明
                    </div>
                    <img className="title-right" src="https://cdn.ggac.com/img/ylzy-pc-title-left.png" alt=""/>
                </div>
            </div>
            <div className="list four">
                <div>所有参赛者提交的参赛作品需保证本人拥有独立完整的著作权，有权以自己的名义提交参赛作品、参加奖项评选；</div>
                <div>所有参赛者提交的参赛作品，授权赛事组委会（及关联公司）对参赛作品拥有用于有关赛事的推广或者展览活动、各媒体或媒介的宣传等进行使用的权利。如赛事组委会要求，参赛者需承诺提供相应授权文件；</div>
                <div>
                    赛事获奖作品的完整版权将转让至赛事组委会（及关联公司）；赛事组委会享有对获奖作品进行复制、发行、展览、信息网络传播权、汇编权等应当由著作权人享有的财产性权利。作者不得将此权利再次授权任何其他个人或机构。作者保留对其作品的永久署名权；
                </div>
                <div>参赛作品一经提交，将视为参赛作者同意并遵守比赛相关规定，若作品在商业使用中其著作权存在争议，赛事组委会不承担因作品侵犯他人(或单位)的权利而产生的法律责任，由提供作品的参赛者承担全部法律责任；</div>
                <div>若赛事组委会（及关联公司）需对获奖作品进行进一步完善或开发、设计周边产品，获奖作者需协助配合；</div>
                <div>如果获奖作者放弃履行相关版权转让或授权义务，则视为主动放弃相应奖项；</div>
                <div>未获奖作品的版权，仍归原作者所有。大赛组委会（及关联公司）如需对该作品进行开发、生产、销售等商业用途，需取得作者书面许可，与作者协商并支付相应费用；</div>
                <div>赛事展示的全部奖金金额均为税前金额，作品获奖后，将由赛事组委会为获奖作者代扣代缴个人所得税。</div>
            </div>
        </div>
    </YlzyRuleStyle >
}

export default YlzyRule