import { Modal, Select, SelectProps, message } from "antd"
import { apiPostSub } from "api/sub";
import { useState } from "react";
import { useNavigate } from "react-router";
import { ContestDetailResponse } from "types/contest";

const YdbzApplicant = ({ detail, isModalOpen, handleCancel }: { detail?: ContestDetailResponse, isModalOpen: boolean, handleCancel: () => void }) => {
    const contestJSON = JSON.parse(detail?.pageCfg || "{}")
    const defaultOption: SelectProps["options"] = contestJSON.list || []
    const [org, setOrg] = useState<string>()
    const navigate = useNavigate()
    const handleChange = (newValue: string) => {
        newValue && setOrg(newValue[0])
    };

    const filterOption = (
        input: string,
        option?: { label: string; value: string }
    ) => {
        return (option?.label ?? "").toLowerCase().includes(input.toLowerCase());
    };

    const handleOk = () => {
        if (!org) {
            message.error("请填写院校信息")
            return
        }
        org && detail &&
            apiPostSub({
                organization: org,
                organizationType: 2,
                contestId: detail.id,
            }).then(() => {
                handleCancel()
                navigate(`/g-contest/upload/${detail?.id}?org=${org}`)
            });
        //setIsModalOpen(false);
    };
    return <Modal
        title="请选择所属院校"
        open={isModalOpen}
        onOk={handleOk}
        onCancel={handleCancel}
        maskClosable={false}
        centered
    >
        <Select
            filterOption={filterOption as any}
            optionFilterProp="children"
            mode="tags"
            style={{ width: "100%" }}
            placeholder="请输入关键词，选择或按回车选中"
            onChange={handleChange}
            options={defaultOption}
            maxCount={1}
        />
    </Modal>
}

export default YdbzApplicant