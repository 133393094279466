import styled from "styled-components";

export const Billboard2024UserStyle = styled.div`
    @font-face {
        font-family: 'XinYiJiXiangSong';
        src: url('https://cdn.ggac.com/fonts/XinYiJiXiangSong.ttf');
    }
    @font-face {
        font-family: 'AlibabaPuHuiTiR';
        src: url('https://cdn-prd.ggac.com/ggac/font/AlibabaPuHuiTi.ttf');
    }
    padding-bottom: 1px;
    .top {
        margin-bottom: 50px;
        color: rgb(169,60,28);
        .tip {
            text-align: center;
            font-size: 18px;
            font-family: 'AlibabaPuHuiTiR';
        }
    }
    .title-wrap {
        display: flex;
        justify-content: center;
        align-items: center;
        gap: 30px;
        img {
            margin-top: 13px;
        }
        .title {
            text-align: center;
            font-size: 50px;
            font-family: 'XinYiJiXiangSong';
            font-weight: bold;
            letter-spacing: 3px;
            padding-left: 3px;
        }
    }
    
    .user {
        display: flex;
        justify-content: center;
        flex-wrap: wrap;
        max-width: 1400px;
        min-width: 1100px;
        margin: 0 auto 200px;
        position: relative;
        z-index: 5;
        row-gap: 3vw;
        column-gap: 100px;
        &:last-child {
            margin-bottom: 50px !important;
        }
        &-item {
            min-width: 105px;
            width: 175px;
            cursor: pointer;
            .cover-wrap {
                width: 100%;
                aspect-ratio: 202 / 201;
                background-color: red;
                padding: 17px 11px 8px 15px;
                width: 100%;
                background: url("https://cdn-prd.ggac.com/ggac/reg/2024/billboard/2024-billboard-user-border.png") center center no-repeat;
                background-size: cover;
            }
            .user-cover {
                aspect-ratio: 1 / 1;
                width: 100%;
                background-size: cover;
                border-radius: 50%;
            }
            .user-name {
                font-size: 19px;
	            color: #a93c1c;
                text-align: center;
                margin-top: 10px;
            }
        }
    }
`