import styled from "styled-components";
import { dynamicColumnsFullWidth2 } from "utils/functions/common";

export const YdbzShopStyle = styled.div`
    @font-face {
        font-family: 'AlibabaPuHuiTiR';
        src: url('https://cdn-prd.ggac.com/ggac/font/AlibabaPuHuiTi.ttf');
    }
    @font-face {
        font-family: 'XinYiJiXiangSong';
        src: url('https://cdn.ggac.com/fonts/XinYiJiXiangSong.ttf');
    }
    
    font-family: 'AlibabaPuHuiTiR';
    .data-list {
        display: grid;
        grid-template-columns: repeat(${dynamicColumnsFullWidth2()}, 1fr);
        gap: ${(props) => props.theme.space.padding}px;
        margin-top:  ${(props) => props.theme.space.marginLG}px;
        padding: ${(props) => props.theme.space.paddingLG}px;
        padding-top: 0;
        grid-template-rows: max-content;
    }

    .ant-spin-container {
        min-height: 750px;
    }
    .tip1,.tip2 {
        text-align: center;
    }
    .tip2 {
        margin:  ${(props) => props.theme.space.marginLG}px 0;
    }

    .wrap {
        background: url("https://cdn-prd.ggac.com/ggac/submatch/ydbz/ydbz-school-bg.png") center center no-repeat !important;
        background-size: 100% 100% !important;
        position: relative;
        margin-top: -6px;
        padding-bottom: 100px;

        .shop-content {
            padding-top: 50px;
        }
        
        .text {
            background: url("https://cdn-prd.ggac.com/ggac/submatch/ydbz/ydbz-part1-desc.png") center center no-repeat;
            background-size: cover;
            width: 1134px;
            height: 384px;
            margin: 0 auto 100px;
            display: flex;
            flex-direction: column;
            justify-content: center;
            align-items: center;
            font-size: 22px;
	        line-height: 45px;
	        letter-spacing: 3px;
	        color: #ffffff;
            span {
                color: #68f0ff;
                font-style: italic;
            }
        }
    }

    .btn {
        width: 243px;
        height: 68px;
        text-align: center;
        line-height: 62px;
        background: url("https://cdn-prd.ggac.com/ggac/submatch/ydbz/ydbz-banner-tab.png") center center no-repeat;
        background-size: cover;
        font-size: 28px;
	    letter-spacing: 1px;
	    color: #d8e6ff;
        cursor: pointer;
        margin: 0 auto;
        font-family: 'XinYiJiXiangSong';
        margin-bottom:  ${(props) => props.theme.space.marginLG}px;
        &:hover {
	        color: #0c3497 !important;
            background: url("https://cdn-prd.ggac.com/ggac/submatch/ydbz/ydbz-banner-tab-active.png") center center no-repeat !important;
            background-size: cover !important;
        }
    }
`