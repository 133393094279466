import { Outlet } from "react-router-dom"
import Billboard2024Banner from "./banner"
import { Billboard2024Style } from "./style"
import { getContestDetail } from "api/contest"
import { useEffect, useState } from "react"
import { ContestDetailResponse } from "types/contest"
import { FloatButton } from "antd"

const Billboard2024 = () => {
    const [detail, setDetail] = useState<ContestDetailResponse>()
    useEffect(() => {
        getContestDetail({ code: "ggac6" }).then(res => {
            setDetail(res)
        })
    }, [])
    return <Billboard2024Style>
        <div className="content">
            <Billboard2024Banner />
            <Outlet context={{ detail: detail }}></Outlet>
            <div className="bottom">
                <img src="https://cdn-prd.ggac.com/ggac/reg/2024/billboard/2024-billboard-bottom.png" />
            </div>
            <FloatButton.Group shape="circle">
                <FloatButton.BackTop tooltip="回到顶部" />
            </FloatButton.Group>
        </div>
    </Billboard2024Style>
}

export default Billboard2024