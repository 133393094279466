import { useEffect, useState } from 'react'
import { CollectionCarouselStyle } from './style'
import { useKeenSlider } from 'keen-slider/react';
import { RiArrowLeftSLine, RiArrowRightSLine } from 'react-icons/ri';
import Card from './card';
import { EventExhibitionDetailResponse } from 'types/events';
import { getEventExhibitionList } from 'api/events';

function CollectionCarousel() {
  const [currentSlide, setCurrentSlide] = useState(0)
  const [loaded, setLoaded] = useState(false)
  const [list, setList] = useState<EventExhibitionDetailResponse[]>([])
  const [sliderRef, instanceRef] = useKeenSlider<HTMLDivElement>({
    mode: "free-snap",
    initial: 0,
    slides: {
      perView: 7,
      spacing: 15,
    },
    slideChanged(slider) {
      setCurrentSlide(slider.track.details.rel)
    },
    created() {
      setLoaded(true)
    },
  });

  useEffect(() => {
    getEventExhibitionList({}).then(res => {
      const result = res.pageData.filter(item => ![15, 16, 17].includes(item.id)) // 隐藏 蛇展
      setList(result)
    })
  }, [])

  const move = (type: string) => {
    if (type === "prev") {
      instanceRef.current?.prev()
    }
    if (type === "next") {
      console.log(instanceRef.current)
      instanceRef.current?.next()
    }
  }
  return (
    <CollectionCarouselStyle>
      {
        list.length > 0 && <>
          {
            list.length > 7 && false && <>
              <div className="control prev" onClick={() => move('prev')}>
                <RiArrowLeftSLine></RiArrowLeftSLine>
              </div>
              <div className="control next" onClick={() => move('next')}>
                <RiArrowRightSLine></RiArrowRightSLine>
              </div>
            </>
          }
          <div ref={sliderRef} className="keen-slider">
            {list.map((item, index) => {
              return <div className="keen-slider__slide number-slide1" key={index}>
                <Card item={item}></Card>
              </div>
            })}
          </div>


        </>
      }

    </CollectionCarouselStyle>
  )
}

export default CollectionCarousel