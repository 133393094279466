import styled from "styled-components";

export const Ycwl2RuleStyle = styled.div`
@font-face {
  font-family: 'ShuHeiTi';
  src: url('https://cdn.ggac.com/fonts/Alimama_ShuHeiTi_Bold.ttf');
}
@font-face {
  font-family: 'AlibabaPuHuiTiR';
  src: url('https://cdn.ggac.com/v2/fonts/AlibabaPuHuiTir.7fe9f2fe.ttf');
}
.rule {
    margin-top: -30px;
    .title {
        background: url("https://cdn.ggac.com/img/ycwl2-pc-title.png") center center no-repeat;
        background-size: cover;
        width: 1300px;
        height: 62px;
        display: flex;
        margin: 0 auto;
        justify-content: center;
        align-items: center;
        font-size: 43px;
	    letter-spacing: 10px;
	    color: #ffffff;
        font-family: 'ShuHeiTi';
    }
   
    &-one {
        background: url("https://cdn.ggac.com/img/ycwl2-rule-one-bg.png") center center no-repeat;
        background-size: 100% 100%;
        padding: 104px 0 350px;
        position: relative;
        z-index: 10;
        .content {
            margin: 141px 0 158px;
            font-size: 20px;
	        line-height: 36px;
	        letter-spacing: 1px;
	        color: #ffffff;
            text-align: center;
            div{
                display: flex;
                align-items: center;
                justify-content: center;
            }
            .bg {
	            height: 27px;
                background-color: #48c9ff;
                color: #0a1625;
                text-align: center;
                margin: 0 2px;
                font-weight: bold;
            }
            .tip {
                display: flex;
                justify-content: center;
                align-items: center;
                background: url("https://cdn.ggac.com/img/ycwl2-pc-part-one-bg.png") center center no-repeat;
                background-size: cover;
                width: 1038px;
	            height: 47px;
                margin: 0 auto;
                flex-direction: column;
                margin-top: 70px;
                div {
                    font-size: 18px;
	                line-height: 24px;
	                letter-spacing: 1px;
	                color: #48c9ff;
                }
            }
        }
        .tab {
            display: flex;
            justify-content: center;
            gap: 175px;
            margin-top: 100px;
            &-item {
                width: 224px;
	            height: 59px;
                display: flex;
                justify-content: center;
                align-items: center;
                font-size: 18px;
                color: #ffffff;
                background: url("https://cdn.ggac.com/img/ycwl2-rule-one-btn.png") center center no-repeat;
                background-size: 100% 100%;
                cursor: pointer;
            }
            .tab-item:hover,.tab-first {
                background: url("https://cdn.ggac.com/img/ycwl2-rule-one-btn-hover.png") center center no-repeat !important;
                background-size: 100% 100% !important;
            }
        }
        .list {
            width: 1200px;
            /* min-height: 930px; */
            background: url("https://cdn.ggac.com/img/ycwl2-rule-one-border.png") center center no-repeat;
            background-size: 100% 100%;
            margin: 30px auto 0;
            padding: 40px 20px;
        }
        .border2 {
            /* height: 240px !important;
            margin-top: 70px; */
            .border-item {
                width: 195px !important;
	            height: 63px!important;
                background: url("https://cdn.ggac.com/img/ycwl2-pc-two-border4.png") center center no-repeat !important;
                background-size: 100% 100% !important;
                &:hover {
                    background: url("https://cdn.ggac.com/img/ycwl2-pc-two-border4-hover.png") center center no-repeat !important;
                    background-size: 100% 100% !important;
                }
            }
        }
        .border-list {
            display:flex;
            justify-content: center;
            gap: 40px;
            .border-item {
                img {
                    height: 26px;
                    margin-bottom: 5px;
                }
                width: 195px;
	            height: 124px;
                background: url("https://cdn.ggac.com/img/ycwl2-pc-two-border3.png") center center no-repeat;
                background-size: 100% 100%;
                display: flex;
                justify-content: center;
                align-items: center;
                flex-direction: column;
                cursor: pointer;
                .name {
                    font-size: 18px;
	                color: #ffffff;
                }
            }
            .border-item:hover,.tab-second {
                background: url("https://cdn.ggac.com/img/ycwl2-pc-two-border3-hover.png") center center no-repeat !important;
                background-size: 100% 100% !important;
            }
        }
        .bottom {
            padding: 0 60px;
        }
        .list1 {
            margin: 45px 0 30px;
            span {
                color: #48c9ff;
                cursor: pointer;
            }
        }
        .desc {
            font-size: 16px;
	        line-height: 32px;
	        color: #ffffff;
        }
        
        .list2,.list3 {
            margin-top: 25px;
            .label {
	            color: #48c9ff !important;
                margin-left: 0;
            }
            div {
                margin-left: 10px;
                font-size: 16px;
	            line-height: 32px;
	            color: #ffffff;
            }
            .ml {
                margin-left: 50px;
            }
        }
    }
    .rule-two {
        background: url("https://cdn.ggac.com/img/ycwl2-rule-two-bg.png") center center no-repeat;
        background-size: 100% 100%; 
        padding: 416px 0 277px;
        margin-top: -260px;
        position: relative;
        z-index: 9;
        .list{
            background: url("https://cdn.ggac.com/img/ycwl2-rule-two-border.png") center center no-repeat;
            background-size: 100% 100%; 
            width: 1200px;
            height: 1000px;
            margin: 70px auto 0;
            padding: 80px;
            color: #ffffff;
            font-size: 16px;
            &-item {
                margin-bottom: 18px;
                &:last-child{
                    margin-bottom: 0;
                    display: flex;
                    align-items: center;
                &>div:last-child {
                    margin-left: 0 !important;
                }
                }
                &>div:first-child {
                    display:flex;
                }
                &>div:last-child {
                    margin-left: 20px;
                }
                div {
	                line-height: 30px;
                }
                .label {
                    color: #48c9ff;
                }
            }
        }
    }
}
`