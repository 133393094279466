import styled from "styled-components";

export const Billboard2024WorkStyle = styled.div`
    @font-face {
        font-family: 'XinYiJiXiangSong';
        src: url('https://cdn.ggac.com/fonts/XinYiJiXiangSong.ttf');
    }
    padding-bottom: 1px;
    .title-wrap {
        display: flex;
        justify-content: center;
        align-items: center;
        margin-bottom: 50px;
        gap: 30px;
        img {
            margin-top: 13px;
        }
    }
    .title {
        text-align: center;
        font-size: 50px;
        font-family: 'XinYiJiXiangSong';
        color: #a93c1c;
        font-weight: bold;
        letter-spacing: 3px;
        padding-left: 3px;
    }
    .work {
        display: flex;
        justify-content: center;
        flex-wrap: wrap;
        max-width: 1400px;
        min-width: 1100px;
        margin: 0 auto 100px;
        position: relative;
        z-index: 5;
        &-item {
            width: 14.52vw;
            min-width: 200px;
            aspect-ratio: 280 / 202;
            background-color: red;
            background-size: cover;
            background-position: center;
            cursor: pointer;
        }
    }
`