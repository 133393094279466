import styled from "styled-components";

export const Ycwl2DetailStyle = styled.div`
@font-face {
  font-family: 'ShuHeiTi';
  src: url('https://cdn.ggac.com/fonts/Alimama_ShuHeiTi_Bold.ttf');
}
@font-face {
  font-family: 'AlibabaPuHuiTiR';
  src: url('https://cdn.ggac.com/v2/fonts/AlibabaPuHuiTir.7fe9f2fe.ttf');
}
@font-face {
  font-family: 'DINE';
  src: url('https://cdn.ggac.com/v2/fonts/DINEngschriftStd.0420a207.ttf');
}
.detail {
    margin-top: -30px;
    font-family: 'AlibabaPuHuiTiR';
    .title {
        background: url("https://cdn.ggac.com/img/ycwl2-pc-title.png") center center no-repeat;
        background-size: cover;
        width: 1300px;
        height: 62px;
        display: flex;
        margin: 0 auto;
        justify-content: center;
        align-items: center;
        font-size: 43px;
	    letter-spacing: 10px;
	    color: #ffffff;
        font-family: 'ShuHeiTi';
    }
    .part-one {
        background: url("https://cdn.ggac.com/img/ycwl2-pc-bg1.png") center center no-repeat;
        background-size: 100% 100%;
        padding: 128px 0 400px;
        position: relative;
        z-index: 10;
        .content {
            margin: 141px 0 190px;
            font-size: 20px;
	        line-height: 36px;
	        letter-spacing: 1px;
	        color: #ffffff;
            text-align: center;
            div{
                display: flex;
                align-items: center;
                justify-content: center;
            }
            .bg {
	            height: 27px;
                background-color: #48c9ff;
                color: #0a1625;
                text-align: center;
                margin: 0 2px;
                font-weight: bold;
            }
            span {
                text-decoration: underline;
                text-decoration-color: #48c9ff;
                color: #48c9ff;
                text-underline-offset: 6px;
            }
            .tip {
                display: flex;
                justify-content: center;
                align-items: center;
                background: url("https://cdn.ggac.com/img/ycwl2-pc-part-one-bg.png") center center no-repeat;
                background-size: cover;
                width: 1038px;
	            height: 67px;
                margin: 0 auto;
                flex-direction: column;
                margin-top: 70px;
                div {
                    font-size: 18px;
	                line-height: 24px;
	                letter-spacing: 1px;
	                color: #48c9ff;
                }
            }
        }
        .date {
            width: 283px;
	        height: 70px;
            background: url("https://cdn.ggac.com/img/ycwl2-pc-date-title.png") center center no-repeat;
            background-size: cover;
            margin: 0 auto;
            display: flex;
            justify-content: center;
            align-items: center;
            font-size: 34px;
	        letter-spacing: 3px;
	        color: #ffffff;
            font-family: 'AlibabaPuHuiTiR';
            font-weight: bold;
        }
        .date-list {
            display: flex;
            justify-content: center;
            margin-top: 60px;
            gap: 60px;
            &-item {
                width: 205px;
                height: 147px;
                background: url("https://cdn.ggac.com/img/ycwl2-pc-date-border.png") center center no-repeat;
                background-size: 100% 100%;
                display: flex;
                flex-direction: column;
                justify-content: center;
                align-items: center;
                .name {
                    font-size: 18px;
	                line-height: 24px;
	                letter-spacing: 1px;
	                color: #ffffff;
                    font-family: 'ShuHeiTi';
                }
                .time {
                    font-size: 20px;
	                line-height: 48px;
	                color: #ffffff;
                    position: relative;
                    &::after {
                        content: "";
                        position: absolute;
                        width: 92px;
                        height: 1px;
	                    background-color: #ffffff;
	                    opacity: 0.3;
                        top: 5px;
                        left: 50%;
                        transform: translateX(-50%);
                    }
                }
            }
        }
        .date-tip {
            color: #fff;
            text-align: center;
            margin-top: 50px;
            font-size: 18px;
        }
    }
    .part-two {
        background: url("https://cdn.ggac.com/img/ycwl2-pc-two-bg.png") center center no-repeat;
        background-size: 100% 100%;
        padding: 360px 0 289px;
        position: relative;
        z-index: 9;
        margin-top: -220px;
        .border {
            width: 1201px;
	        height: 303px;
            margin: 0 auto;
            background: url("https://cdn.ggac.com/img/ycwl2-pc-two-border1.png") center center no-repeat;
            background-size: 100% 100%;
            display: flex;
            margin: 120px auto 0;
            flex-direction: column;
            padding-top: 40px;
            align-items: center;
            &-title {
                font-size: 25px;
	            letter-spacing: 3px;
	            color: #ffffff;
                margin-bottom: 35px;
            }
            .border-item2 {
                &>div:nth-child(2){
                    font-weight: bold;
                    font-size: 22px !important;
                }
            }
            .border-list {
                display:flex;
                justify-content: center;
                gap: 40px;
                .border-item {
                    img {
                        height: 26px;
                        margin-bottom: 5px;
                    }
                    width: 195px;
	                height: 124px;
                    background: url("https://cdn.ggac.com/img/ycwl2-pc-two-border3.png") center center no-repeat;
                    background-size: 100% 100%;
                    display: flex;
                    justify-content: center;
                    align-items: center;
                    flex-direction: column;
                    cursor: pointer;
                    position: relative;
                    .word {
                        position: absolute;
                        color: #fff;
                        bottom: -48px;
                        width: 150%;
                        text-align: center;
                        div {
                            font-size: 12px !important;
                            font-family: normal !important;
                        }
                    }
                    .name {
                        font-size: 18px;
	                    color: #ffffff;
                    }
                    &:hover {
                        background: url("https://cdn.ggac.com/img/ycwl2-pc-two-border3-hover.png") center center no-repeat !important;
                        background-size: 100% 100% !important;
                    }
                }
            }
        }
        .border2 {
            height: 240px !important;
            margin-top: 70px;
            .border-item {
                width: 195px !important;
	            height: 63px!important;
                background: url("https://cdn.ggac.com/img/ycwl2-pc-two-border4.png") center center no-repeat !important;
                background-size: 100% 100% !important;
                &:hover {
                    background: url("https://cdn.ggac.com/img/ycwl2-pc-two-border4-hover.png") center center no-repeat !important;
                    background-size: 100% 100% !important;
                }
            }
        }
        .border-tip {
            color: #fff;
            text-align: center;
            margin-top: 50px;
            font-size: 18px;
        }
    }
    .part-three {
        background: url("https://cdn.ggac.com/img/ycwl2-pc-three-bg.png") center center no-repeat;
        background-size: 100% 100%;
        padding: 260px 0;
        position: relative;
        z-index: 10;
        margin-top: -150px;
        .prize-one {
            margin-top: 80px;
            width: 325px !important;
	        height: 150px !important;
            background: url("https://cdn.ggac.com/img/ycwl2-pc-three-border.png") center center no-repea !important;
            background-size: 100% 100% !important;
        }
        .prize3 {
            width: 1250px !important;
            gap: 43px !important;
            .prize-list-item {
                width: 205px !important;
	            height: 147px !important;
                background: url("https://cdn.ggac.com/img/ycwl2-pc-three-border2.png") center center no-repea !important;
                background-size: 100% 100% !important;
            }
        }
        .prize4 {
            width: 1250px !important;
            gap: 43px !important;
            .prize-list-item {
                width: 267px !important;
	            height: 147px !important;
                background: url("https://cdn.ggac.com/img/ycwl2-pc-three-border3.png") center center no-repea !important;
                background-size: 100% 100% !important;
            }
        }
        .list {
            display: flex;
            flex-direction: column;
            justify-content: center;
            align-items: center;
            width: 980px;
            margin: 0 auto;
            .prize-name {
                font-family: 'ShuHeiTi';
	            font-size: 28px;
	            letter-spacing: 3px;
	            color: #48c9ff;
                font-weight: bold;
                margin: 90px 0 10px;
                text-align: center;
            }
            .prize-tip {
                width: 747px;
	            height: 36px;
                background: url("https://cdn.ggac.com/img/ycwl2-pc-three-tip.png") center center no-repeat;
                background-size: 100% 100%;
                display: flex;
                justify-content: center;
                align-items: center;
                font-size: 16px;
	            color: #ffffff;
                margin: 0 auto 37px;
            }
            .prize-list {
                display: flex;
                flex-wrap: wrap;
                gap: 90px;
                &-item {
                    width: 265px;
                    height: 150px;
                    display: flex;
                    flex-direction: column;
                    justify-content: center;
                    align-items: center;
                    background: url("https://cdn.ggac.com/img/ycwl2-pc-three-border1.png") center center no-repeat;
                    background-size: 100% 100%;
                    .prize-title {
                        font-size: 18px;
	                    color: #ffffff;
                    }
                    .prize-num {
                        font-family: 'DINE';
                        display: flex;
                        align-items: flex-end;
                        font-size: 40px;
	                    letter-spacing: 0px;
	                    color: #ffffff;
                        position: relative;
                        &::after{
                            content: "";
                            width: 112px;
	                        height: 1px;
	                        background-color: #ffffff;
	                        opacity: 0.3;
                            position: absolute;
                            left: 50%;
                            transform: translateX(-50%);
                            top: 5px;
                        }
                        span {
                            font-size: 20px;
                            position: absolute;
                            bottom: 10px;
                            left: -20px;
                        }
                    }
                    .prize-num2 {
                        font-size: 18px;
	                    color: #ffffff;
                        text-align: center;
                    }
                    .prize-size {
                        font-size: 18px;
	                    color: #ffffff;
                    }
                    .prize-tip2 {
                        font-size: 14px;
	                    color: #ced8e7;
                        margin-top: -10px;
                    }
                    
                }
            }
            .prize-tip3 {
                text-align: center;
                margin-top: 35px;
                font-size: 14px;
	            color: #ced8e7;
                span {
                    color: #58d4fd;
                }
            }
        }
        .prize-tip4 {
            font-size: 16px;
	        color: #ced8e7;
            text-align: center;
            margin-top: 60px;
        }
    }
    .part-four {
        background: url("https://cdn.ggac.com/img/ycwl2-pc-four-bg.png") center center no-repeat;
        background-size: 100% 100%;
        padding: 200px 0;
        position: relative;
        z-index: 9;
        margin-top: -150px;
        .judge {
            width: 1400px;
            display: flex;
            flex-direction: column;
            justify-content: center;
            align-items: center;
            margin: 0 auto;
            
            &-title {
                text-align: center;
                font-size: 28px;
	            letter-spacing: 3px;
	            color: #fff;
                margin: 105px 0 40px;
            }
            &-list {
                display: flex;
                justify-content: center;
                flex-wrap: wrap;
                &-item {
                    width: 265px;
                    text-align: center;
                    margin-bottom: 50px;
                    .cover-image {
                        width: 136px;
                        height: 166px;
                        background-size: 100% 100%;
                        position: relative;
                        margin: 0 auto;
                        cursor: pointer;
                        .second {
                            bottom:15px !important;
                        }
                        .name {
                            position: absolute;
                            left: 50%;
                            transform: translateX(-50%);
                            bottom: 0;
                            width: 100%;
                            display: flex;
                            flex-direction: column;
                            justify-content: center;
                            align-items: center;
                            height: 35px;
                            font-size: 16px;
	                        color: #ffffff;
                            width: 100%;
                        }
                        margin-bottom: 10px;
                    }
                    .desc {
                        font-size: 14px;
	                    line-height: 20px;
	                    letter-spacing: 0px;
	                    color: #ced8e7;
                        text-align: center;
                    }
                }
            }
        }
    }
    .part-five {
        background: url("https://cdn.ggac.com/img/ycwl2-pc-five-bg.png") center center no-repeat;
        background-size: 100% 100%;
        padding: 349px 0 135px;
        position: relative;
        margin-top: -150px;
        z-index: 10;
        .rule {
            background: url("https://cdn.ggac.com/img/ycwl2-pc-five-border.png") center center no-repeat;
            background-size: 100% 100%;
            position: relative;
            z-index: 10;
            width: 1200px;
            /* height: 850px; */
            flex-direction: column;
            align-items: center;
            padding: 50px 95px;
            margin: 40px auto 200px;
            &-item {
                display: flex;
                font-size: 16px;
	            color: #ffffff;
                margin-bottom: 20px;
                &:last-child{
                    margin-bottom: 0;
                }
                .label {
                    margin-right: 3px;
                }
                .name {
                    color: #58d4fd;
                    width: 100px;
                }
                div {
                    line-height: 35px;
                }
            }
        }
        .bottom {
            text-align: center;
            img {
                height: 73px;
            }
        }
    }
}
`