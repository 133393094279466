import styled from "styled-components";

export const WuKongWorkPrizeStyle = styled.div`
    @font-face {
      font-family: 'SourceHanSerifCN';
      src: url('https://cdn.ggac.com/v2/fonts/SourceHanSerifCN-Regular.85b46b6b.otf');
    }

    @font-face {
      font-family: 'sanjixingkaijianti';
      src: url('https://cdn.ggac.com/fonts/sanjixingkaijianti.ttf');
    }
    .workprize {
        background: url("https://cdn-prd.ggac.com/ggac/submatch/wukong/wukong-workprize-bg.jpg") center center no-repeat;
        background-size: cover;
        margin-top: -10px;
        padding: 100px 0;
        font-family: 'alibabaPuHuiTi';
        .title {
            width: 100%;
            display: flex;
            justify-content: center;
            align-items: center;
            position: relative;
            font-family: 'sanjixingkaijianti';

            .left,
            .right {
              width: 316px;
              padding-top: 10px;
            }
        
            span {
              margin: 0 -20px;
              font-size: 60px;
            
              &:last-child {
                margin-right: 0;
              }
            }
            
            &-word {
                font-size: 80px;
                display: flex;
                padding: 0 20px 0 31px;
                letter-spacing: 11px;
                align-items: flex-end;
                background: linear-gradient(0deg, #1F4A56 0%, #0F121E 100%);
                -webkit-background-clip: text;
                -webkit-text-fill-color: transparent;
            }

            .number {
                position: absolute;
                left: 50%;
                top: -50%;
                transform: translateX(-50%);
                width: 122px;
                height: 122px;
                opacity: 0.4;
                display: flex;
                justify-content: center;
                align-items: center;
                color: #3e566e;
                font-size: 50px;
                background: url("https://cdn.ggac.com/img/wukong-pc-title-number-wrap.png") center center no-repeat;
                background-size: 100% 100%;
                display: none;
            }
        }
        .tip {
            text-align: center;
            font-size: 20px;
            margin-bottom: 20px;
        }
        .work {
          display: flex;
          justify-content: center;
          gap: 50px;
          width: 1200px;
          margin: 0 auto 50px;
          flex-wrap: wrap;
          .work-item {
            border: solid 1px rgba(22, 43, 55,.5);
            border-radius: 8px;
            box-sizing: border-box;
          }
          .cover {
            width: 1200px;
	          height: 400px;
            background-size: 100%;
            border-top-right-radius: 8px;
            border-top-left-radius: 8px;
            display: block;
            background-size: 100% 100%;
          }
          .info {
            height: 72px;
            display: flex;
            padding: 0 26px;
            align-items: center;
            gap: 20px;
            .user_avatar_image {
              width: 40px;
	            height: 40px;
              border-radius: 50%;
              background-size: cover;
            }
            .user-name {
              font-size: 16px;
              color: #162b37;
              a {
                color: #162b37;
              }
            }
          }
          .comment {
            padding: 0 30px;
            border-top: solid 1px #162b37;
            &-item {
              border-bottom: solid 1px #162b37;
              color: #162b37;
              padding: 15px 0;
            }
            &-item:last-child {
              border: none;
            }
          }
        }

        .work-5{
            .work-item {
                width: 573px !important;
            }
          .cover {
              width: 571px !important;
              height: 340px !important; 
          }
        }

        &-tip {
          text-align: center;
        }
      }   
`