import { useEffect, useState } from "react";
import { CategoryFilterStyle } from "./style";
import { useSearchParams } from "react-router-dom";
import { MallProductCategoryListItem } from "types/product";
import { apiGetMallProductListCategory } from "api/shop";

function CategoryFilter() {
  const [listOne, setListOne] = useState<MallProductCategoryListItem[]>([]);
  const [list, setList] = useState<MallProductCategoryListItem[]>([]);
  const [searchParams, setSearchParams] = useSearchParams();

  useEffect(() => {
    apiGetMallProductListCategory({ type: 3, level: 1, hideNoProduct: 1, tagName: "2024毕业集市" }).then((res) => {
      const result = res.filter((item) => {
        return ["resources", "shiwu", "xianzhi"].includes(item.code)
      })
      setList(res)
      setListOne(result)
    });
  }, []);

  const onClickPid = (item: MallProductCategoryListItem) => {
    if (item.code === "resources") {
      const result = list.filter((item) => {
        return ["resources", "brush", "avatarWallpaper"].includes(item.code)
      })
      const result2 = result.map(item => item.id)
      searchParams.set("categoryIdList", result2.join(","));
    } else {
      searchParams.set("categoryIdList", item.id.toString());
    }

    console.log(item.code)

    searchParams.set("pageNumber", "1");
    searchParams.set("code", item.code);
    setSearchParams(searchParams);

  };

  return (
    <CategoryFilterStyle className="filter">
      <div className="row-1">
        <div
          className="block"
          onClick={() => {
            searchParams.delete("categoryIdList");
            searchParams.delete("categoryId");
            searchParams.delete("code");
            setSearchParams(searchParams);
          }}
        >
          <div
            className="block-overlay"
            style={{
              backgroundImage: `url(${'https://cdn-prd.ggac.com/ggac/assets/image/shop/all.jpg'})`,
            }}
          ></div>
          <div
            className={
              !searchParams.get("code") ? "text active" : "text"
            }
          >
            全部商品
          </div>
        </div>
        {listOne.map((item, index) => {
          return (
            <div
              className="block"
              onClick={() => onClickPid(item)}
              key={index}
            >
              <div
                className="block-overlay"
                style={{
                  backgroundImage: `url(${item.coverUrl})`,
                }}
              ></div>
              <div
                className={
                  searchParams.get("code") === item.code
                    ? "text active"
                    : "text"
                }
              >
                {item.name}
              </div>
            </div>
          );
        })}
      </div>
    </CategoryFilterStyle>
  );
}

export default CategoryFilter;
