import { useEffect, useState } from "react"
import { ExhibitionListStyle } from "./style"
import { EventExhibitionDetailResponse } from "types/events"
import { getEventExhibitionList } from "api/events"
import { Pagination } from "antd"
import { GuanggaoBannerCarousel } from "components/guanggao-banner-carousel"

const ExhibitionList = () => {
    const [exhibitionData, setExhibitionData] = useState<EventExhibitionDetailResponse[]>([])
    const [total, setTotal] = useState(0)
    const [pageNumber, setPageNumber] = useState(1)
    const pageSize = 10

    useEffect(() => {
        getEventExhibitionList({ pageSize, pageNumber }).then(res => {
            const result = res.pageData.filter(item => ![15, 16, 17].includes(item.id)) // 隐藏 蛇展
            setExhibitionData(result)
            setTotal(res.totalSize)
        })
    }, [pageNumber])

    const changeEventingPageNumber = (e: number) => {
        setPageNumber(e)
    }

    const toDetail = (detail: EventExhibitionDetailResponse) => {
        window.open(`/events/exhibition/${detail.id}`)
    }

    return <ExhibitionListStyle>
        <div className="guanggao-banner-wrapper">
            <GuanggaoBannerCarousel positionCode={9}></GuanggaoBannerCarousel>
        </div>
        <div className="page-title">作品展</div>
        <div className="event-wrap">
            <div className="event">
                {
                    exhibitionData.map(item => {
                        return <div className="event-item" onClick={() => toDetail(item)} key={item.id}>
                            <div className="cover" style={{ backgroundImage: `url(${item.listImage})` }} />
                            <div className="info">
                                <div className="title">{item.name}</div>
                                <div className="desc">
                                    {item?.startTime?.substring(0, 10)} - {item?.endTime?.substring(0, 10)}
                                </div>
                            </div>
                        </div>
                    })
                }
            </div>
            <Pagination
                pageSize={pageSize}
                style={{ textAlign: "center" }}
                hideOnSinglePage={true}
                showSizeChanger={false}
                onChange={changeEventingPageNumber}
                total={total}
                current={pageNumber}
            />

        </div>
    </ExhibitionListStyle>
}

export default ExhibitionList