import styled from "styled-components";

export const Billboard2024BannerStyle = styled.div`
    @font-face {
      font-family: 'HGJXT';
      src: url('https://cdn.ggac.com/fonts/HGJXT.ttf');
    }
    min-width: 1400px;
    .cover {
        img {
            width: 100%;
        }
    }
    .tab {
        display: flex;
        justify-content: center;
        align-items: center;
        gap: 200px;
        padding: 100px 0;
        &-item {
            font-family: 'HGJXT';
            width: 366px;
            aspect-ratio: 366 / 91;
            display: flex;
            justify-content: center;
            align-items: center;
            background: url("https://cdn-prd.ggac.com/ggac/reg/2024/billboard/2024-billboard-tab.png") center center no-repeat;
            background-size: cover;
            font-size: 45px;
	        color: #960d0f;
            cursor: pointer;
            letter-spacing: -9px;
            padding-right: 9px;
        }
    }
    .active,.tab-item:hover {
        color: #ffebb9 !important;
        background: url("https://cdn-prd.ggac.com/ggac/reg/2024/billboard/2024-billboard-tab-active.png") center center no-repeat !important;
        background-size: cover !important;
    }
`