import styled from "styled-components";

export const UserNoticeStyle = styled.div`
    padding: 50px 0;
    .title {
        font-weight: bold;
        font-size: 20px;
        margin-bottom: 10px;
    }
    .btn {
        margin-bottom: 100px;
    }
    .tab {
        display: flex;
        gap: 30px;
        padding: 0 10px;
        padding-top: 0;
        border-bottom: 1px solid #eee;
        margin-bottom: 50px;
        &-item{
            position: relative;
            padding: 10px 0;
            cursor: pointer;
            
        }
       .active::after{
            content: "";
            position: absolute;
            width: 100%;
            height: 2px;
            background-color: ${props => props.theme.colors.colorPrimary};
            bottom: 0;
            left: 0;
        }
    }


    textarea {
        width: 500px;
    }
    
`