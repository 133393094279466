import { CzwjRuleStyle } from "./style"

const CzwjRule = () => {
    const downLoad = (value: string) => {
        window.open(value)
    }
    return <CzwjRuleStyle>
        <div className="rule">
            <div className="rule-top">
                <div className="title">
                    <div className="title-top">创作内容</div>
                    <div className="title-bottom">BRIEF</div>
                </div>
                <div className="border border1">
                    <div>
                        <div>（1）</div>
                        <div>
                            参赛者可使用官方提供基础设计模板进行创作，也可根据个人创意进行创作，官方提供的体型模板不强制要求使用，但需使用
                            官方【模板底图】参赛
                            <span
                                className="bold"
                                onClick={() => downLoad('https://cdn.ggac.com/download/czwj_download.rar')}
                            >（点击下载模板底图）</span
                            >；
                        </div>
                    </div>
                    <div>
                        （2）创作内容需为一个套装4个形象（包括成男、成女、萝莉、正太）；
                    </div>
                    <div>
                        （3）外装设计内容包含游戏内商城售卖、活动赠送等用途，但不包括门派校服；
                    </div>
                    <div>
                        <div>（4）</div>
                        本次赛事仅要求正面设计，参赛作品中如包含完整三视图（正/背/侧）、偏色、配套发型、腰部挂件、背部挂件、马具、脚印等配套设计将视为加分项；
                    </div>
                    <div>
                        （5）参赛者可投稿多套不同的设计方案，评审将以每套作品为单位进行评选，同一参赛者的不同参赛作品可同时获奖；
                    </div>
                    <div>
                        <div>（6）</div>
                        <div>
                            “脱颖而出”奖项需参赛者前往新浪微博发布作品，带tag
                            <span>#剑网3创作无疆##剑网3外装设计大赛#</span
                            >，同时@剑网3有爱同人秀，一经@剑网3有爱同人秀转发翻牌即可获得，本奖项与其他奖项可重复获得。
                        </div>
                    </div>
                    <div className="word">
                        参赛作品不限制美术创作风格，参赛者需创作符合《剑网3》世界观时间线的外装。
                    </div>
                </div>
                <div className="title">
                    <div className="title-top">作品要求</div>
                    <div className="title-bottom">DELIVERABLES</div>
                </div>
                <div className="border border2">
                    <div>（1）文件格式：JPG；文件尺寸：不低于4000X2250；文件大小：每单张作品不超过20M；作品数量：单组设计方案上传作品总数量不超过10张；</div>
                    <div>（2）参赛作品的创作软件、表现形式、创作风格不限。上传作品需要保证画面完整清晰，单份作品需至少包含“成男”、“成女”、“正太”、“萝莉”四体型【完整正面视图】；手绘作品必须保证高分辨率，请务必扫描清晰后再上传；</div>
                    <div>（3）官方提供的体型模板不强制要求使用，但需使用官方【模板底图】参赛；</div>
                    <div style={{ display: "block" }}>（4）参赛作品，不得侵犯他人的著作权、专利权、商标权、肖像权、隐私权等任何法定权利。如使用第三方素材，请标注清楚素材来源并提供相应的授权证明。<span style={{ cursor: "pointer" }} onClick={() => downLoad("https://www.ggac.com/official/372")}>（详见跳转公告第二部分【风险排查】相关要求）；</span></div>
                    <div>
                        （5）参赛作品严禁包含政治、低俗、色情、宗教等内容，不得违反相关法律法规；
                    </div>
                    <div>（6）暂不支持AIGC（人工智能内容生成技术）相关作品参赛，请参赛者保留创作过程。主办方有可能会在评选过程中需要参赛者提供创作源文件（如PSD等）。</div>
                    {/* <div>（7）官方提供的体型模板不强制要求使用，但需使用官方【模板底图】参赛。</div> */}
                    {/* <div className="word">上传作品时间要求：3月22日12:00-6月19日23:59</div> */}
                </div>
                <div className="title">
                    <div className="title-top">作品样例</div>
                    <div className="title-bottom">EXAMPLE</div>
                </div>
                <div className="border border3">
                    <img src="https://cdn.ggac.com/img/czwj_pc_rule_border3.png" alt="" />
                </div>
            </div>
            <div className="rule-bottom">
                <div className="title title2">
                    <div className="title-top">获奖须知</div>
                    <div className="title-bottom">AWARDS INSTRUCTIONS</div>
                </div>
                <div className="border border4">
                    <div>
                        <div>（1）</div>
                        <div>
                            “一代宗师”“名满江湖”获奖作品将会在游戏内实装，获奖者在奖项公布后三个月内需提供源文件并配合官方美术建模要求进行设计调整或补充，包括但不局限于补全三视图&结构细节、异色等内容；
                        </div>
                    </div>
                    <div>
                        <div>（2）</div>
                        <div>
                            “锋芒毕露”、“别具一格”、“奇思妙想”、“脱颖而出”奖项作品均有机会制作成外装在游戏实装。若设计被最终采纳，西山居将额外提供实装费用，获奖者需在确认实装后三个月内提供源文件，并配合官方美术建模要求进行设计调整或补充，包括但不局限于补全三视图&结构细节、异色等内容；
                        </div>
                    </div>
                    <div>
                        <div>（3）</div>
                        <div>
                            针对获奖作品，获奖者知悉并确认：获奖作品的著作权归西山居所有，获奖者依据活动规则获得的奖金及实装费用（如有）已经包含了其将该作品的全部著作权权利转让给西山居所需的全部费用，除此之外，获奖者无权就上述事项向西山居主张任何其它款项；
                        </div>
                    </div>
                    <div>
                        <div>（4）</div>
                        <div>
                            活动获奖名单将于2023年12月22日至2023年12月29日期间（下称公示期）于GGAC赛事活动页面、《剑网3》官网、@剑网3有爱同人秀微博等渠道进行公布。公示期内，参赛者如对公布结果有异议可于@剑网3有爱同人秀公示微博评论区提出异议并提供依据。公示期结束后，GGAC将向获奖用户发送站内消息，联系获奖用户登记相关信息（包括姓名、地址、联系方式等），并于2024年2月起陆续发放相应奖金或奖品；
                        </div>
                    </div>
                    <div>
                        <div>（5）</div>
                        <div>
                            如在2024年1月31日前，获奖用户未予回复，则视为自动放弃奖金及奖品。用户因个人原因未能成功领取奖品，则视为放弃奖金或奖品；
                        </div>
                    </div>
                    <div>
                        <div>（6）</div>
                        <div>
                            所有现金奖励均为税前金额，GGAC平台将按照国家法律正常缴纳个税流程扣税后发放。
                        </div>
                    </div>
                </div>
                <div className="title title2">
                    <div className="title-top">版权说明</div>
                    <div className="title-bottom">COPYRIGHT NOTES</div>
                </div>
                <div className="border border5">
                    <div>
                        <div>（1）</div>
                        <div>
                            参赛者上传作品需有足够程度的独创性和原创性，确保作品不包含侵权内容。如使用第三方素材，请标注清楚素材来源并提供相应的授权证明。建议所有参赛者保留创作过程（例如手绘稿、草图、大色调色块图/底图、分层图、上色步骤、成品图、废稿方案等）。主办方可能在评选过程中需要参赛者提供创作源文件，以验证作品原创性。
                        </div>
                    </div>
                    <div>
                        <div>（2）</div>
                        <div>
                            参赛者上传作品版权若存在归属多人的情况，请保证作品已获得其他版权所有者的授权。如有违反，立即取消参赛资格，由此产生的法律纠纷由参赛者全权负责；
                        </div>
                    </div>
                    <div>
                        <div>（3）</div>
                        <div>
                            参赛者上传作品，即视为同意授权《剑网3》官方及GGAC可在活动期间及后续宣传推广活动中，将该作品及相关设定文字用于与本次活动相关的网站展示、媒体传播（包括但不限于官方网站、官方微博、官方微信、官方抖音、推栏APP、其他合作媒体平台相关新闻展示宣传及官方线下活动展示等途径）。如《剑网3》官方及GGAC需要，参赛者需承诺配合提供相应授权文件；
                        </div>
                    </div>
                    {/* <div>
                        <div>（4）</div>
                        <div>
                            由于参赛作品中使用了《剑网3》相关内容，因此参赛作品一经投稿，则视为参赛者同意参赛作品的著作权及邻接权归参赛者与《剑网3》共同所有，未经授权，参赛者不得将参赛作品自行用于商业用途，或授予任何第三方使用，不得使用参赛作品参与其他同类赛事/活动，否则取消参赛资格及获奖资格；
                        </div>
                    </div> */}
                    <div>
                        <div>（4）</div>
                        <div>
                            “一代宗师”、“名满江湖”、“锋芒毕露”奖项作品著作权及邻接权均归属于西山居所有，作者保有署名权。参赛者需配合签署相应的权利转让文件并提供其他配合义务。
                        </div>
                    </div>
                </div>
                <div className="title title2">
                    <div className="title-top">注意事项</div>
                    <div className="title-bottom">ATTENTION</div>
                </div>
                <div className="border border6">
                    <div>
                        若作品存在如下违规现象，主办方有权对违规作品进行删除、取消参赛及获奖资格，并视影响程度保留进一步追究的权益：
                    </div>
                    <div>
                        （1）所创作作品涉及政治、宗教、暴力、血腥、色情等不良内容，违反国家法律法规；
                    </div>
                    <div>
                        （2）作品通过GGAC平台被举报存在抄袭、过度借鉴，且作者无法提供原创证明，经主办方沟通核查后无法确认原创性；
                    </div>
                    <div>（3）所创作作品不是完整的服装设计作品，如过于简单的草稿、涂鸦或过于抽象的作品；</div>
                    <div>（4）所创作作品包含其他商业推广信息；</div>
                    <div>（5）所创作作品与活动主题无关；</div>
                    <div>（6）所创作作品方案内未包含符合本次活动创作内容要求的作品；</div>
                    <div>（7）所创作作品违反创作规则第五条的版权规定；</div>
                    <div>
                        （8）创作者恶意攻击、诽谤
                        主办方、承办方、评委、《剑网3》玩家、其他参赛选手，并影响恶劣；
                    </div>
                    <div>（9）其他由活动主办方（或活动审核人员）认定违规的作品。</div>
                    <div className="word word3">
                        <div>
                            本次活动相关疑问、授权、举报、奖励发放相关问题请联系微博：@剑网3有爱同人秀，或GGAC官方微信、QQ，
                        </div>
                        <div>美好版权环境由你我共同维护。</div>
                    </div>
                </div>
            </div>
        </div>
    </CzwjRuleStyle>
}

export default CzwjRule