import { InviteCodeStyle } from "./style"
import { RiCloseCircleFill } from "react-icons/ri"
import { message } from "antd"
import copy from "copy-to-clipboard"
import { useEffect, useState } from "react"
import { ContestCodeListRequest } from "types/contest"
import { getcontestCodeList } from "api/contest"

const InviteCode = ({ isShow,close }: { isShow: boolean ,close:()=>void}) => {
    const doCopy = () => {
        let copyUrl = `妖灵志异—游戏角色设计大赛征集中~ 与我一同降伏邪魔，斩秽疏邪！报名填写邀请码，完成参赛即可与我一起瓜分御妖赏金池！我的邀请码：${codeInfo?.invitationCode}， https://www.ggac.com/contest/ylzy/detail`
        message.success("复制成功")
        copy(copyUrl)
    }

    const [codeInfo, setCodeInfo] = useState<ContestCodeListRequest>()

    useEffect(() => {
        if (!isShow) {
            return
        }
        getcontestCodeList("ylzy").then(res => {
            setCodeInfo(res)
        })
    }, [isShow])

    return <InviteCodeStyle>

        {
            isShow && <div className="introduce-wrapper">
                <div className="introduce-wrapper-cnt">
                    <RiCloseCircleFill className="iconfont icon-shanchu1 introduce-close" onClick={close}></RiCloseCircleFill>
                    <div className="title">我的邀请码：{codeInfo?.invitationCode}</div>
                    <div className="content">
                        妖灵志异—游戏角色设计大赛征集中~
                    </div>
                    <div className="content">与我一同降伏邪魔，斩秽疏邪！</div>
                    <div className="content">报名填写邀请码，完成参赛即可与我一起瓜分御妖赏金池！</div>
                    <div className="content">
                        我的邀请码{codeInfo?.invitationCode}
                    </div>
                    <div className="content">https://www.ggac.com/contest/ylzy/detail</div>
                    <div className="btn" onClick={doCopy}>一键复制分享好友</div>
                    <div className="invite-count">我的邀请：{codeInfo?.inviteUsers.length}人</div>
                    <div className="invite-list">
                        {
                            codeInfo?.inviteUsers.map(item => {
                                return <div className="invite-list-item" key={item.id}>
                                    {item.username}
                                </div>
                            })
                        }
                        {
                            codeInfo?.inviteUsers.length === 9 && codeInfo?.inviteUsers.length > 9 && <>…</>
                        }
                    </div>
                </div >
            </div >
        }
    </InviteCodeStyle >
}

export default InviteCode