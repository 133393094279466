import { useCallback, useEffect, useMemo, useRef, useState } from "react"
import { EventExhibitionStyle } from "./style"
import { useLocation, useParams } from "react-router"
import { getEventExhibitionDetail } from "api/events"
import { EventExhibitionDetailResponse } from "types/events"
import { Button, message, Pagination, Spin } from "antd"
import { NoData } from "components/no-data"
import { useNavigate } from "react-router"
import { hasToken, isMobile, queryUrl } from "utils/functions/common"
import { SearchOption } from "components/search-option"
import { getExhibitionWorkList } from "api/work"
import { WorkExhibitionListItem } from "types/user/work"
import ContestWorkCard from "components/uni-card/self-cards/contestWorkCard";
import { getContestUserVoteTotal, postContestUserTicket } from "api/contest"
import VoteTicket from "components/vote-ticket"
import { VoteUserTotal } from "types/contest"

const EventExhibition = () => {
    const id = Number(useParams()?.id)
    const [detail, setDetail] = useState<EventExhibitionDetailResponse>()
    const [list, setList] = useState<WorkExhibitionListItem[]>([])
    const [total, setTotal] = useState(0)
    let sortField = new URLSearchParams(useLocation().search).get("sortField")
    const timeField = Number(new URLSearchParams(useLocation().search).get("timeField") || 0)
    const navigate = useNavigate()
    let pageNumber = Number(new URLSearchParams(useLocation().search).get("pageNumber") || 1)
    const pageSize = 48
    const [loading, setLoading] = useState(false)
    const [voteId, setVoteId] = useState(0)
    const [canVoteTotal, setCanVoteTotal] = useState(0)
    const [currentVoteItem, setCurrentVoteItem] = useState<WorkExhibitionListItem>()
    const t_id = Date.now();
    const [isVoteShow, setIsVoteShow] = useState(false)
    const [voteDetail, setVoteDetail] = useState<VoteUserTotal>()
    const voteRef = useRef() as React.MutableRefObject<any>;
    const [searchOption, setSearchOption] = useState<{ label: string, value: string }[]>([])
    const token = hasToken()

    useEffect(() => {
        getEventExhibitionDetail(id).then(res => {
            setDetail(res)
            if (res.isVoteValid) {
                setVoteId(res.voteId)
            }
        })
    }, [id])

    // 获取用户可使用票数
    const getUserContestVote = () => {
        if (!!voteId && detail?.isClosedVoteButton === 0 && !!token) {
            getContestUserVoteTotal({ id: Number(voteId) }).then(res => {
                setCanVoteTotal(res.validTicketCount)
                setVoteDetail(res)
            })
        }
    }
    useEffect(() => {
        if (!voteId) {
            return
        }
        if (!detail) {
            return
        }
        getUserContestVote()
    }, [detail, voteId])


    const tagNames = useMemo(() => {
        return detail?.tagList.map(item => {
            return item.name
        }) || []
    }, [detail?.tagList])

    const upload = () => {
        window.open(`/work/create?tag=${JSON.stringify(tagNames)}&isShowPrivate=0`)
    }

    const paramsReq = useMemo(() => {
        return {
            id, sortField: sortField || detail?.defaultSort, pageNumber, timeField
        }
    }, [id, sortField, pageNumber, timeField, detail?.defaultSort])

    const getList = useCallback(() => {
        const params: any = {
            pageNumber: paramsReq.pageNumber, pageSize, tagIds: detail?.tagList.map(tag => tag.id).join(','), sortField: paramsReq.sortField
        }
        if (timeField) {
            params.timeField = timeField
        }
        if (!!detail?.timeFlag) {
            params.publishTimeEnd = detail.startTime
            params.publishTimeStart = detail.endTime
        }
        if (voteId) {
            params.voteId = voteId
        }
        setLoading(true)
        getExhibitionWorkList(params).then(res => {
            setList(res.pageData)
            setTotal(res.totalSize)
        }).finally(() => {
            setLoading(false)
        })
    }, [voteId, paramsReq, detail])

    const selectVoteItem = (detail: WorkExhibitionListItem) => {
        setCurrentVoteItem(detail)
        if (voteDetail?.useRule === 1 && voteDetail.validTicketCount > 0) {
            vote(1, detail)
            return
        }
        setIsVoteShow(true)
    }

    useEffect(() => {
        if (!detail) return
        getList()
    }, [paramsReq, detail, getList])

    useEffect(() => {
        const checkIsMobile = isMobile()
        if (checkIsMobile) {
            window.location.href = `https://m.ggac.com` + window.location.pathname
            return
        }
    }, [])

    const changePage = (e: number) => {
        const url = queryUrl({ pageNumber: e })
        navigate(`?${url}`)
    }

    //点分页滚到到最上面
    useEffect(() => {

        const scrollableDiv = document.querySelector("#scrollableDiv");
        console.log(scrollableDiv)
        scrollableDiv && scrollableDiv.scrollIntoView({
            behavior: "smooth",
        });
    }, [pageNumber]);

    const saveWorkIdsForNav = () => {
        list && localStorage.setItem(
            "workIdsForNav",
            JSON.stringify(
                list.map((item) => {
                    return item.id;
                })
            )
        );
    }

    const updateVote = (detail: WorkExhibitionListItem, count: number) => {
        const index = list.findIndex(item => {
            return item.id === detail.id
        })
        list[index].vote.ticketCount = list[index].vote.ticketCount + count
        setList([...list])
    }

    const close = () => {
        setIsVoteShow(false)
    }

    const vote = (value: number, detail?: WorkExhibitionListItem) => {
        const voteId = Number(detail && detail.vote.voteId) || Number(currentVoteItem?.vote.voteId)
        const workId = Number(detail && detail.id) || Number(currentVoteItem?.id)
        postContestUserTicket({ count: value, id: voteId, workId }).then(() => {
            message.success("投票成功")
            updateVote(detail || currentVoteItem!, value)
            getUserContestVote()
            close()
            if (!!detail) {
                const index = list.findIndex(item => item.id === workId)
                list[index].vote.status = 1
                console.log(list[index])
                setList([...list])
            }
        }).finally(() => {
            voteRef.current.resetLoading()
        })
    }

    useEffect(() => {
        if (!detail) {
            return
        }
        const defaultOption = [
            {
                label: "最新",
                value: "lastSubmitTime",
            },
            {
                label: "推荐",
                value: "recommendUpdateTime",
            },
            {
                label: "浏览",
                value: "viewCount",
            },
            {
                label: "点赞",
                value: "likeCount",
            },
            {
                label: "热度",
                value: "threeDaysHot",
            },
        ]
        if (detail?.isClosedVoteButton === 0) {
            defaultOption.push({
                label: "票数",
                value: "ticketCount",
            })
        }

        setSearchOption(defaultOption)
    }, [detail])


    return <EventExhibitionStyle onClick={() => saveWorkIdsForNav()} >
        <div className="banner">
            <div className="banner-image">
                <img src={detail?.topBanner} alt="" />
            </div>

            <div className="content">
                <div className="top">
                    <div className="name">{detail?.name}</div>
                    <div className="date">{detail?.startTime?.substring(0, 10)} - {detail?.endTime?.substring(0, 10)}</div>
                </div>
                <div className="desc" dangerouslySetInnerHTML={{ __html: detail?.content! }} ></div>
                {
                    !!detail?.canUpload && <div className="btn-wrap">
                        <Button type='primary' className="upload" onClick={upload}>上传作品</Button>
                    </div>
                }

                {
                    !!(detail?.status === 3) && <div className="btn-wrap">
                        <Button type='primary' className="upload" disabled>已结束</Button>
                    </div>
                }
            </div>
        </div>
        <div className="work" id="scrollableDiv">
            <div className="work-search">
                <SearchOption option={searchOption} defaultSortField={paramsReq.sortField} isShowTypeList={false}></SearchOption>
            </div>
        </div>
        <VoteTicket ref={voteRef} isShow={isVoteShow} vote={vote} close={close} ticket={canVoteTotal} />
        <Spin spinning={loading}>
            {
                list.length === 0 && <NoData></NoData>
            }
            <div className="list">
                {
                    list.map((item) => {
                        return <ContestWorkCard selectVoteItem={selectVoteItem} canVoteTotal={canVoteTotal} item={item} t_id={t_id}></ContestWorkCard>
                    })
                }
            </div>
        </Spin>
        <Pagination
            style={{ textAlign: "center", marginTop: "15px" }}
            hideOnSinglePage={true}
            current={pageNumber}
            onChange={changePage}
            pageSize={pageSize}
            showSizeChanger={false}
            total={total}
        />
    </EventExhibitionStyle >
}

export default EventExhibition