import { ContestDetailResponse } from "types/contest"
import { WuKongWorkPrizeStyle } from "./style"
import { useOutletContext } from "react-router-dom"

const YlzyWorkPrize = () => {
    const { detail } = useOutletContext<{ detail: ContestDetailResponse }>()
    const contestJSON = JSON.parse(detail?.pageCfg || "{}")
    const list: { title: string, subTitle: string, tip: string, id: number, work: { title: string, publisher: string, publisher_id: string, id: string, user_avatar_image: string, cover_image: string, comment: { name: string, value: string }[] }[] }[] = contestJSON.workprize || []

    return <WuKongWorkPrizeStyle>
        {
            detail && <div className="workprize">
                {
                    list.map(item => {
                        return <div key={item.id}>
                            <div className="title">
                                <img src="https://cdn.ggac.com/img/wukong-pc-title-left.png" className="left" alt="" />
                                <div className="title-word">{item.title}</div>
                                <img src="https://cdn.ggac.com/img/wukong-pc-title-right.png" className="right" alt="" />
                                <div className="number">
                                    {item.subTitle}
                                </div>
                            </div>
                            {
                                item.tip && <div className="tip">{item.tip}</div>
                            }
                            <div className={`work work-${item.id}`}>
                                {
                                    item.work && item.work.length > 0 && item.work.map(work => {
                                        return <div className="work-item" key={work.id}>
                                            <a href={`https://www.ggac.com/work/detail/${work.id}`} target="_blank" className="cover" style={{ backgroundImage: `url(${work.cover_image})` }} />
                                            <div className="info">
                                                <a href={`https://www.ggac.com/user/${work.publisher_id}`} target="_blank" className="user_avatar_image" style={{ backgroundImage: `url(${work.user_avatar_image})` }}></a>
                                                <div className="user-name">
                                                    <a href={`https://www.ggac.com/user/${work.publisher_id}`} target="_blank">
                                                        {work.publisher}
                                                    </a> -
                                                    <a href={`https://www.ggac.com/work/detail/${work.id}`} target="_blank">「{work.title}」</a>
                                                </div>
                                            </div>
                                            {
                                                work.comment && work.comment.length > 0 && <div className="comment">
                                                    {
                                                        work.comment.map((comment, index) => {
                                                            return <div className="comment-item" key={index}>
                                                                <div className="comment-name">{comment.name}</div>
                                                                <div className="comment-content">{comment.value}</div>
                                                            </div>
                                                        })
                                                    }
                                                </div>
                                            }
                                        </div>
                                    })
                                }
                            </div>
                        </div>
                    })
                }

                <div className="workprize-tip">
                    特别说明：原「最佳奖-角色概念设计」《方案1寻找悟空》因作品中部分内容不符合赛事规则，经组委会与参赛者协商，已对该作品及其奖项进行下架处理。
                </div>
            </div>
        }
    </WuKongWorkPrizeStyle>
}

export default YlzyWorkPrize