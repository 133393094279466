import { Select, Cascader, ConfigProvider } from "antd";
import { useCallback, useEffect, useState } from "react";
import { getWorkCategory } from "api/work";
import { SearchOptionStyle } from "./style";
import { useNavigate } from "react-router";
import { queryUrl } from "utils/functions/common";

interface Option {
  value: string | number;
  label: string;
  children?: Option[];
}

const defaultOption = [
  {
    label: "最新",
    value: "lastSubmitTime",
  },
  {
    label: "推荐",
    value: "recommendUpdateTime",
  },
  {
    label: "浏览",
    value: "viewCount",
  },
  {
    label: "点赞",
    value: "likeCount",
  },
  {
    label: "热度",
    value: "threeDaysHot",
  },
]

export const SearchOption = ({
  isShowTypeList = true,
  onChange,
  type,
  defaultSortField,
  option = defaultOption
}: {
  isShowTypeList?: boolean;
  onChange?: () => void;
  type?: string;
  defaultSortField?: string;
  option?: { label: string, value: string }[]
}) => {
  let [options, setOptions] = useState<Option[]>([]);
  const id_string = new URLSearchParams(window.location.search).get(
    "categoryId"
  );
  const [id_arr, setIdArr] = useState<string[]>(id_string ? id_string.split(",") : []);
  // const id_arr = id_string ? id_string.split(",") : [];
  const sortField = new URLSearchParams(window.location.search).get(
    "sortField"
  );
  // const timeField = new URLSearchParams(window.location.search).get('timeField')
  const pathname = window.location.pathname;
  const navigate = useNavigate();
  const getOptions = useCallback(async () => {
    const params: any = {
      type: 1,
    };
    if (type === "work") {
      params.useWork = 1;
    } else if (type === "article") {
      params.useArticleUpload = 1;
      params.level = 2;
    }
    let res = await getWorkCategory(params);
    if (id_string === "全部分类" || !id_string) {
      setIdArr(['全部分类'])
    }

    let options_ = [
      { value: "全部分类", label: "全部分类" },
      ...res.map((item) => {
        return {
          value: String(item.id),
          label: item.name,
          children: item.children
            ? item.children.map((i) => {
              return {
                value: String(i.id),
                label: i.name,
                children: undefined,
              };
            })
            : [],
        };
      }),
    ];
    setOptions(options_);
  }, [type]);

  useEffect(() => {
    console.log("ewqew", id_string)
    setIdArr(id_string ? id_string.split(",") : ['全部分类'])
  }, [id_string]);

  useEffect(() => {
    getOptions();
  }, [getOptions]);

  const handleChange = (value: any) => {
    onChange && onChange();
    let query = "";
    if (value) {
      query = queryUrl({ categoryId: value.join(","), pageNumber: 1 });
    } else {
      query = queryUrl({ categoryId: undefined, pageNumber: 1 });
    }
    setIdArr(value)

    navigate(`${pathname}?${query}`);
  };
  const handleChangeSort = (value: string) => {
    onChange && onChange();
    const query = queryUrl({ sortField: value, pageNumber: 1 });
    navigate(`${pathname}?${query}`);
  };

  return (
    <ConfigProvider
      theme={{
        components: {
          Cascader: {
            dropdownHeight: 200,
          },
        },
      }}
    >
      <SearchOptionStyle>
        {isShowTypeList && id_arr && (
          <Cascader
            changeOnSelect={true}
            expandTrigger="hover"
            options={options}
            onChange={handleChange}
            placeholder="所有分类"
            value={id_arr}
            style={{ width: 200, margin: 0 }}
            allowClear={false}
          />
        )}

        <Select
          style={{ width: 200, margin: 0 }}
          placeholder="最匹配"
          value={sortField || defaultSortField}
          onChange={handleChangeSort}
          options={option}
          allowClear
        />
        {/* <Select
            style={{ width: 200, margin: 0 }}
            placeholder="所有时间"
            value={timeField}
            onChange={handleChangeTime}
            options={[
                {
                    label: "所有时间",
                    value: "0"
                },
                {
                    label: "一天",
                    value: "1"
                },
                {
                    label: "一周",
                    value: "2"
                },
                {
                    label: "一个月",
                    value: "3"
                },
                {
                    label: "一年",
                    value: "4"
                }
            ]}
            allowClear
        /> */}
      </SearchOptionStyle>
    </ConfigProvider>
  );
};
