import styled from "styled-components";

export const CzwjDetailStyle = styled.div`

@font-face {
  font-family: "FZXiJinLJW";
  src: url("https://cdn.ggac.com/v2/fonts/FZXiJinLJW.33937684.ttf");
}

@font-face {
  font-family: "FZXBSJW";
  src: url("https://cdn.ggac.com/v2/fonts/FZXBSJW.63b535d7.ttf");
}

@font-face {
  font-family: "SIMFANG";
  src: url("https://cdn.ggac.com/v2/fonts/SIMFANG.059f3a57.ttf");
}

.challenge {
  font-family: "FZXIJINLJW";
  margin-top: -7px;
  &-top {
    background: url("https://cdn.ggac.com/img/czwj_pc_bg1.jpg") center center
      no-repeat;
    background-size: 100% 100%;
    padding: 100px 0 150px;
    & > div {
      margin: 140px 0;
    }
    & > div:first-child {
      margin-top: 0 !important;
    }
  }
  &-bottom {
    background: url("https://cdn.ggac.com/img/czwj_pc_bg2.png") center center
      no-repeat;
    background-size: 100% 100%;
    margin-top: -430px;
    padding: 530px 0 106px;
  }
  &-one {
    text-align: center;
    position: relative;
    img {
      width: 1038px;
    }
    .btn {
      background: url("https://cdn.ggac.com/img/czwj2-pc-btn.png") center center no-repeat;
      background-size: 100% 100%;
      width: 245px;
      height: 55px;
      position: absolute;
      left: 50%;
      transform: translateX(-50%);
      bottom: 30px;
      display: flex;
      justify-content: center;
      align-items: center;
      font-size: 26px;
	    color: #a93c1d;
      cursor: pointer;
    }
  }

  .word {
    font-size: 22px;
    text-align: center;
    color: #8d5b2b;
  }

  .title {
    width: 854px;
    text-align: center;
    height: 161px;
    background: url("https://cdn.ggac.com/img/czwj_pc_title1.png") center center
      no-repeat;
    background-size: 100% 100%;
    padding-top: 55px;
    margin: 0 auto 36px;
    &-top {
      font-size: 58px;
      font-family: FZXBSJW;
      font-weight: normal;
      color: #a93c1d;
      letter-spacing: 17px;
      text-align: center;
      padding-left: 17px;
    }
    &-bottom {
      font-size: 19px;
      line-height: 38px;
      letter-spacing: 19px;
      color: #8d5b2b;
      padding-left: 19px;
      font-family: "SIMFANG";
    }
  }

  .title2 {
    background: url("https://cdn.ggac.com/img/czwj_pc_title2.png") center center
      no-repeat !important;
    background-size: 100% 100% !important;
    .title-top {
      color: #3e4f40 !important;
    }
    .title-bottom {
      color: #3e4f40 !important;
    }
  }

  &-two {
    text-align: center;
    img {
      width: 1160px;
      margin-top: 50px;
    }
    .date2 {
      height: 193px !important;
      .inner {
        width: 863px;
        margin: 0 auto;
      }
      .date-item {
        text-align: left !important;
      }
    }
    .date {
      width: 1175px;
      height: 142px;
      margin: 60px auto 0;
      display: flex;
      flex-direction: column;
      justify-content: center;
      align-items: center;
      background: url("https://cdn.ggac.com/img/czwj_pc_tips.png") center center
        no-repeat;
      background-size: 100% 100%;
      .date-item {
        font-size: 16px;
        line-height: 26px;
        color: #a93c1d;
        text-align: center;
        display: flex;
      }
    }
  }

  &-four {
    text-align: center;
    .word {
      margin-bottom: 56px;
    }
    .prize {
      width: 1200px;
    }
  }

  &-five {
    margin-bottom: 150px;
    .judge {
      display: flex;
      justify-content: space-between;
      margin: 71px auto 0;
      width: 1200px;
      &-item {
        text-align: center;
        width: 255px;
        img {
          width: 205px;
          cursor: pointer;
        }

        .name {
          font-size: 24px;
          font-weight: normal;
          color: #0c180d;
          margin: 18px 0 13px;
        }

        .job {
          font-size: 18px;
          color: #3e4f40;
        }
      }

      &-item:last-child {
        margin-right: 0;
      }
    }
  }
  &-six {
    text-align: center;
    margin-bottom: 150px;
    .tip {
      font-size: 18px;
      font-weight: normal;
      color: #f7edd0;
      margin-bottom: 50px;
    }
    .content {
      background: url("https://cdn.ggac.com/img/czwj_pc_border1.png") center
        center no-repeat;
      background-size: 100% 100%;
      width: 1120px;
      height: 450px;
      margin: 0 auto;
      display: flex;
      flex-direction: column;
      align-items: center;
      justify-content: center;
      padding: 0 38px;
      & > div {
        display: flex;
        justify-content: flex-start;
        width: 100%;
      }

      div {
        font-size: 18px;
        font-weight: normal;
        color: #0c180d;
        line-height: 42px;
        text-align: left;
      }
    }
  }
  &-seven {
    .code {
      display: flex;
      justify-content: center;

      &-item {
        width: 201px;
        margin-right: 71px;

        .url {
          width: 100%;
        }
        .name {
          font-size: 19px;
          color: #0c180d;
          text-align: center;
          margin-top: 23px;
          font-family: FZXIJINLJW;
        }
      }
      &-item:last-child {
        margin-right: 0;
      }
    }
  }
}
`