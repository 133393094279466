import { useEffect, useState } from "react"
import { Ycwl2RuleStyle } from "./style"
import { useNavigate, useOutletContext } from "react-router-dom"
import { queryUrl } from "utils/functions/common"
import { ContestDetailResponse } from "types/contest"
const Ycwl2Rule = () => {

    const [currentObj, setCurrentObj] = useState<dataType>()
    const [subCurrentObj, setSubCurrentObj] = useState<subDataType>()
    const id = Number(new URLSearchParams(window.location.search).get("id") || 1)
    const subId = Number(new URLSearchParams(window.location.search).get("subId") || 1)


    type subDataType = {
        name: string[]
        id: number
        icon: string
        desc: string[]
        list1: string[]
        list2: string[]
        list3?: string[]
    }

    type dataType = {
        name: string
        id: number
        list: subDataType[]
    }

    const { detail } = useOutletContext<{ detail: ContestDetailResponse }>()
    const contestJSON = JSON.parse(detail?.pageCfg || "{}")
    const dataList: {
        ruleList: dataType[]
        ruleList2: { name: string, id: number, desc: string[] }[]
    } = contestJSON?.rule


    const navigate = useNavigate()

    useEffect(() => {
        const result = dataList?.ruleList.find(item => item.id === id)
        setCurrentObj(result!)
    }, [detail, id])
    useEffect(() => {
        if (!currentObj) {
            return
        }
        const result = currentObj.list.find(item => item.id === subId)
        setSubCurrentObj(result!)
    }, [subId, currentObj])

    const selectId = (value: dataType) => {
        const query = queryUrl({ id: value.id, subId: 1 })
        navigate(`?${query}`)
    }

    const selectSubId = (sId: number) => {
        if (id === 1 && sId === 3) {
            window.open("http://ycwl.cheeringtech.cn:8000/main/home")
        }
        const query = queryUrl({ subId: sId })
        navigate(`?${query}`)
    }

    const toDetail = () => {
        window.open("http://ycwl.cheeringtech.cn:8000/main/home")
    }

    return <Ycwl2RuleStyle>
        <div className="rule">
            <div className="rule-one">
                <div className="content">
                    <div>“元创未来”2023元宇宙设计创意大赛</div>
                    <div>本次大赛将坚持虚实结合、以虚强实价值导向，旨在推动元宇宙更好赋能经济、生活、治理的数字化转型，</div>
                    <div>全方位覆盖元宇宙现有技术的各类应用场景，大赛共设置<div className="bg">“五大方向赛题与五个元宇宙创新实验场”</div>。</div>
                    <div style={{ marginTop: "50px" }}>参赛者请根据各个赛题具体要求与规则发挥创意、创作作品，</div>
                    <div>我们期待与创作者们共同探索未来的可能。</div>
                    <div className="tip">※参赛者可选择任一或多个赛题创作参赛。</div>
                </div>
                <div className="title">参赛规则</div>
                <div className="tab" id="tab">
                    {
                        dataList?.ruleList.map(item => {
                            return <div onClick={() => selectId(item)} className={`tab-item ${item.id === id ? 'tab-first' : ''}`} key={item.id}>{item.name}</div>
                        })
                    }
                </div>
                <div className="list">
                    <div className={`${currentObj?.id === 2 ? 'border2' : ''} border-list`}>
                        {
                            currentObj?.list.map(sub => {
                                return <div onClick={() => selectSubId(sub.id)} className={`border-item ${currentObj.id === id && sub.id === subId ? 'tab-second' : ''}`} key={sub.id}>
                                    {
                                        sub.icon && <img src={sub.icon} alt="" />
                                    }
                                    {
                                        sub.name.map(name => {
                                            return <div className="name" key={name}>{name}</div>
                                        })
                                    }
                                </div>
                            })
                        }
                    </div>
                    <div className={`${currentObj?.id === 2 ? 'bottom2' : ''} bottom`}>
                        {
                            id === 1 && subId === 3 && <div className="list1">
                                <div className="desc">音乐主题赛道需前往元音声律官网报名及提交。<span onClick={toDetail}>点击前往</span>！</div>
                            </div>
                        }
                        {
                            (!(id === 1 && subId === 3)) && <>
                                <div className="list1">
                                    {
                                        subCurrentObj?.desc && subCurrentObj.desc.map(desc => {
                                            return <div className="desc" key={desc}>{desc}</div>
                                        })
                                    }
                                </div>
                                <div className="list2">
                                    {
                                        subCurrentObj?.list1.length! > 0 && <>
                                            {
                                                currentObj?.id === 1 && subId !== 4 && <div className="label">1、作品创作要求</div>
                                            }
                                            {
                                                currentObj?.id === 2 && <div className="label">作品创作及提交要求</div>
                                            }
                                            {
                                                currentObj?.id === 1 && subId === 4 && <div className="label">1、参赛方式</div>
                                            }
                                            {
                                                subCurrentObj?.list1 && subCurrentObj.list1.map(desc => {
                                                    return <div className="desc" key={desc}>{desc}</div>
                                                })
                                            }
                                        </>
                                    }
                                </div>
                                <div className="list2">
                                    {
                                        subCurrentObj?.list2.length! > 0 && <>
                                            {
                                                ((currentObj?.id === 1 && subId !== 4) || currentObj?.id === 2) && <div className="label">2、作品提交要求</div>
                                            }
                                            {
                                                currentObj?.id === 1 && subId === 4 && <div className="label">2、参赛规则</div>
                                            }
                                            {
                                                subCurrentObj?.list2 && subCurrentObj.list2.map(desc => {
                                                    return <div className="desc" key={desc}>{desc}</div>
                                                })
                                            }
                                        </>
                                    }
                                </div>
                                <div className="list3">
                                    {
                                        subCurrentObj?.list3 && subCurrentObj?.list3.length > 0 && <>
                                            {
                                                currentObj?.id === 1 && subId === 4 && <div className="label">3、上传规则</div>
                                            }
                                            {
                                                subCurrentObj?.list3 && subCurrentObj.list3.map(desc => {
                                                    return <div className={`desc ${(desc.search('①') !== -1 || desc.search('②') !== -1 || desc.search('③') !== -1 || desc.search('④') !== -1 || desc.search('注：') !== -1) ? 'ml' : ''}`} key={desc}>{desc}</div>
                                                })
                                            }
                                        </>
                                    }
                                </div>
                            </>
                        }
                    </div>
                </div>
            </div>
            <div className="rule-two" style={{ marginTop: (Number(id) === 1 && (Number(subId) > 2 && Number(subId) < 5)) ? '-233px' : Number(id) === 2 ? '-240px' : '' }}>
                <div className="title">版权说明</div>
                <div className="list">
                    <div className="list-item"></div>
                    <div className="list-item">本赛事规定，凡主动参与本次征集活动的所有参赛者，均视为已经对所提交的作品版权归属作如下不可撤销声明：</div>
                    {
                        dataList?.ruleList2.map(item => {
                            return <div className="list-item" key={item.id}>
                                {item.name && <>
                                    <div>
                                        {item.id}、<div className="label">{item.name}</div>
                                    </div>
                                    <div>{item.desc.map(name => <div key={name}>{name}</div>)}</div>
                                </>}
                                {
                                    !item.name && <>{item.id}、{item.desc.map(name => <div key={name}>{name}</div>)}</>
                                }
                            </div>
                        })
                    }
                </div>
            </div>
        </div>
    </Ycwl2RuleStyle >
}

export default Ycwl2Rule