import styled from "styled-components";

export const CategoryFilterStyle = styled.div`
  width: 100%;
  margin-top: ${(props) => props.theme.space.marginLG}px;
  .row-1 {
    display: inline-flex;
    justify-content: center;
    gap: 18px;
    width: 100%;
    .block {
      width: calc((100% - 136px) / 8);
      height: 51px;
      background-color: ${(props) => props.theme.colors.colorBorderSecondary};
      border-radius: 6px;
      max-width: 140px;
      cursor: pointer;
      font-weight: bold;
      position: relative;
      &:hover {
        .text {
          color: ${(props) => props.theme.colors.colorPrimaryText};
        }
      }
      .block-overlay {
        content: "";
        display: block;
        position: absolute;
        border-radius: 6px;
        top: 0;
        left: 0;
        width: 100%;
        height: 100%;
        background-size: cover;
        background-position:center center;
        background-repeat: no-repeat;
        filter: brightness(50%) saturate(220%);
      }
      .text {
        position: absolute;
        color: #fff;
        top: 50%;
        left: 50%;
        transform: translate(-50%, -50%);
        &.active {
          color: ${(props) => props.theme.colors.colorPrimaryText};
        }
      }
    }
  }
  .row-2 {
    margin: ${(props) => props.theme.space.margin}px 0;
    height: 34px;
    display: flex;
    justify-content: center;
    gap: 12px;
    .tag {
      padding: 6px 20px;
      cursor: pointer;
      &.active {
        background-color: ${(props) => props.theme.colors.colorPrimary};
      }
      &:hover {
        background-color: ${(props) => props.theme.colors.colorPrimary};
      }
      background-color: #f1f1f3;
      border-radius: 26px;
    }
  }
  .meta{
    margin: 0 auto;
    text-align: center;
    height:22px;
    .name{
        font-size: 20px;
        font-weight: 500;
    }
    .desc{
        color: #8C8C8C;
    }
  }
`;
