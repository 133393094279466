import { useLocation, useOutletContext } from "react-router-dom"
import { Ly3dWorkprizeStyle } from "./style"
import { ContestDetailResponse } from "types/contest"
import { useEffect, useState } from "react"

const Ly3dWorkprize = () => {
    const { detail } = useOutletContext<{ detail: ContestDetailResponse }>()
    const contestJSON = JSON.parse(detail?.pageCfg || "{}")
    const [currentHash, setCurrentHash] = useState("")
    const list: { title: string, id: number, title_image: string, work: { title: string, video: string, publisher: string, publisher_id: string, id: string, user_avatar_image: string, cover_image: string, comment: { name: string, value: string }[] }[] }[] = contestJSON.workprize || []

    const language = new URLSearchParams(useLocation().search).get("language") || "zh_CN";

    useEffect(() => {
        if (!detail) {
            return
        }
        const result = JSON.parse(detail?.pageCfg || "{}")
        if (!!["en_GB", "zh_CN"].includes(language)) {
            setCurrentHash(language)
        } else {
            setCurrentHash("zh_CN")
        }
    }, [detail, language])

    const toWorkDetail = (id: number) => {
        window.open(`https://www.ggac.com/work/detail/${id}`)
    }
    return <Ly3dWorkprizeStyle>
        {
            detail && <div className="workprize">
                {
                    list.map(item => {
                        return <div key={item.id}>
                            {
                                currentHash === 'zh_CN' && <div className="title">
                                    <img src={item.title_image} alt="" />
                                </div>
                            }

                            {
                                currentHash === 'en_GB' && <>
                                    {
                                        item.id === 1 && <div className="en-title">1st Prize</div>
                                    }
                                    {
                                        item.id === 2 && <div className="en-title">2st Prize</div>
                                    }
                                    {
                                        item.id === 3 && <div className="en-title">3st Prize</div>
                                    }
                                    {
                                        item.id === 4 && <div className="en-title">Honorable Prizes</div>
                                    }
                                </>
                            }

                            <div className={`work work-${item.id}`}>
                                {
                                    item.work && item.work.length > 0 && item.work.map(work => {
                                        return <div className="work-item" key={work.id}>
                                            {
                                                work.video && <video preload="none" src={work.video} controls poster={work.cover_image} />
                                            }
                                            {
                                                !work.video && <div className="cover" onClick={() => toWorkDetail(Number(work.id))} style={{ backgroundImage: `url(${work.cover_image})` }}></div>
                                            }
                                            <div className="info-wrap">
                                                <div className="info">
                                                    <a href={`https://www.ggac.com/user/${work.publisher_id}`} target="_blank" className="user_avatar_image" style={{ backgroundImage: `url(${work.user_avatar_image})` }}></a>
                                                    <div className="user-name">
                                                        <a href={`https://www.ggac.com/user/${work.publisher_id}`} target="_blank">
                                                            {work.publisher}
                                                        </a> -
                                                        <a href={`https://www.ggac.com/work/detail/${work.id}`} target="_blank">「{work.title}」</a>
                                                    </div>
                                                </div>
                                                {
                                                    work.video && <div className="link" onClick={() => toWorkDetail(Number(work.id))}>查看作品详情</div>
                                                }
                                            </div>
                                        </div>
                                    })
                                }
                            </div>
                        </div>
                    })
                }
            </div>
        }
    </Ly3dWorkprizeStyle>
}

export default Ly3dWorkprize