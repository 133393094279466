import { Pagination, Spin } from "antd";
import { YdbzShopStyle } from "./style"
import { useEffect, useState } from "react";
import MarketProductCard from "components/product-card/market-product-card";
import { NoData } from "components/no-data";
import { PublicProductListItem } from "types/product";
import { apiGetPublicProductList } from "api/shop";
import CategoryFilter from "./category-filter";
import { queryUrl } from "utils/functions/common";
import { useNavigate } from "react-router";

const YdbzShop = () => {

    const [loading, setLoading] = useState(false)
    const [productList, setProductList] = useState<PublicProductListItem[]>([])
    const pageSize = 48
    const [total, setTotal] = useState(0)
    const pageNumber = new URLSearchParams(window.location.search).get("pageNumber") || 1;
    const categoryIdList = new URLSearchParams(window.location.search).get("categoryIdList") || "";
    const code = new URLSearchParams(window.location.search).get("code") || "";
    const navigate = useNavigate()
    const [isShowTab, setIsShowTab] = useState(true)

    // 切换分页
    const changePage = (e: any) => {
        const element = document.getElementById('list');
        const distance = element?.offsetTop;
        window.scrollTo({
            top: Number(distance),
            behavior: "smooth"
        })
        // setCurrentPage(e)
        const query = queryUrl({
            pageNumber: e,
        });
        navigate(`?${query}`);
    }

    useEffect(() => {
        let params: any = {
            pageNumber,
            pageSize,
            sortField: "globalRank",
            tagName: "2024毕业集市",
        };
        if (categoryIdList) {
            params.categoryIdList = categoryIdList
        } else {
            delete params.categoryIdList
        }
        setLoading(true);
        apiGetPublicProductList(params).then((res) => {
            setProductList(res.pageData);
            if (res.pageData.length === 0 && !code) {
                setIsShowTab(false)
            }
            setLoading(false);
            setTotal(res.totalSize);
        }).finally(() => {
            setLoading(false);
        })

    }, [categoryIdList, pageNumber]);

    const toUpload = () => {
        window.open("/user-center/shop/product-list?code=ydbz")
    }


    return <YdbzShopStyle>
        <div id="list">
            <div className="bg-wrap">
                <div className="wrap">
                    <div className="shop-content">
                        <div className="text">
                            <div className="tip1">
                                <div>毕设集市，开店! 投稿毕设之余，也别忘了来赚点零花钱。</div>
                                <div>源文件、头像、壁纸、表情包、手办、文创、饰品、</div>
                                <div>闲置手绘板、闲置画册等统统不限!</div>
                            </div>
                            <div className="tip2">※为商品添加“<span>2024毕业集市 </span>”标签，即可参与本次活动。</div>
                        </div>

                        <div className="btn" onClick={toUpload}>发布商品</div>
                    </div>
                </div>
            </div>
            {
                isShowTab && <>
                    <CategoryFilter></CategoryFilter>
                    <Spin spinning={loading}>
                        <div className="data-list">
                            {productList &&
                                productList.map((item, index) => {
                                    return <MarketProductCard item={item} key={index} />;
                                })}
                        </div>
                        {productList?.length === 0 && (
                            <div style={{ padding: "100px 0" }}>
                                <NoData />
                            </div>
                        )}
                    </Spin>
                    <Pagination
                        style={{ textAlign: "center", marginTop: "28px" }}
                        hideOnSinglePage={true}
                        current={Number(pageNumber) > 0 ? Number(pageNumber) : 1}
                        onChange={changePage}
                        showSizeChanger={false}
                        pageSize={pageSize}
                        total={total}
                    />
                </>
            }
        </div>
    </YdbzShopStyle>
}

export default YdbzShop