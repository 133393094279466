import styled from "styled-components";

export const Workprize2024Style = styled.div`
    @font-face {
      font-family: "DIN";
      src: url("https://cdn.ggac.com/v2/fonts/DINCondensedC-2.e5aeb766.ttf");
    }
    
    @font-face {
        font-family: 'AlibabaPuHuiTiR';
        src: url('https://cdn-prd.ggac.com/ggac/font/AlibabaPuHuiTi.ttf');
    }
    font-family: 'AlibabaPuHuiTiR';
    overflow: hidden;
    padding-bottom: 50px;
    .banner {
        width: 100%;
        min-width: 1400px;
        position: relative;
        img {
            width: 100%;
        }
        .tab {
            display: flex;
            justify-content: center;
            height: 66px;
            width: 100%;
            align-items: center;
	        background-color: #2e1408;
            gap: 40px;
            position: absolute;
            bottom: 0;
            left: 0;
            &-item {
                font-size: 24px;
	            letter-spacing: 1px;
	            color: #ffffff;
                cursor: pointer;
                position: relative;
                line-height: 66px;
                height: 100%;
                &:first-child:hover {
                    .popup1 {
                        display: block;
                    }
                }
                &:nth-child(2):hover {
                    .popup2 {
                        display: block;
                    }
                }
                &:hover {
                    color: #fece73;
                }
                .popup1,.popup2 {
                    position: absolute;
                    display: none;
                    background-color: #fff;
                    width: 120%;
                    left: 50%;
                    transform: translate(-50%);
                    top: 66px;
                    box-shadow: 0 15px 30px rgba(0,0,0,.1);
                    line-height: normal !important;
                    &-item {
                        color: #000;
                        font-size: 16px;
                        text-align: center;
                        padding: 10px 20px;
                        background-color: #fff;
                        &:hover {
                            background-color: #fece73;
                        }
                    }
                }
            }
            .active {
                color: #fece73;
            }
        }
    }

    .list {
        padding-top: 1px;
        width: 1200px;
        margin: 0 auto;
        &-item {
            padding-top: 1px;
            width: 100%;
            .title-wrap {
                text-align: center;
                color: #fece73;
            }
            .prize {
                width: 200px;
                aspect-ratio: 227 / 55;
                background: url("https://cdn-prd-v.ggac.com/ggac/reg/2024/workprize/2024-workprize-icon3.png") center center no-repeat;
                background-size: 100% 100%;
                display: flex;
                justify-content: center;
                align-items: center;
                margin: 50px auto 0;
                .unit {
                    font-size: 25px;
                    margin-top: 15px;
                    line-height: 30px;
                }
                .gift {
                    font-size: 30px !important;
                }
                .money {
                    font-family: "DIN";
                    font-size: 50px;
                    line-height: 30px;
                }
            }
            .prize-word {
                margin-top: 5px;
                font-size: 20px;
            }
            .prize-word-en {
                margin-top: -5px;
            }
            .work  {
                display: flex;
                justify-content: center;
                flex-wrap: wrap;
                margin-top: 30px;
                gap: 30px;
                .more-work {
                    width: 580px !important;
                    .cover {
                        height: 330px;
                    }
                }
                &-item {
                    width: 1200px;
                    border: 1px solid #f4aa2f;
                    display: inline-block;
                    box-shadow: 0px 2px 3px 0px rgba(39, 38, 37, .08);
                    border-radius: 4px;
                    .cover {
                        display: block;
                        width: 100%;
                        height: 350px;
                        background-size: cover;
                        background-position: center;
                    }
                    .work-detail {
                        display: flex;
                        align-items: center;
                        height: 80px;
                        width: 100%;
                        justify-content: space-between;
                        padding: 0 30px;
                        a  {
                            color: #000 !important;
                        }
                        .user {
                            display: flex;
                            gap: 10px;
                            align-items: center;
                            &-avatar {
                                width: 44px;
                                height: 44px;
                                border-radius: 50%;
                                background-size: cover;
                                background-position: center;
                                border: 1px solid #f4aa2f;
                            }
                        }
                    }
                }
            }
        }
    }
`