import { useOutletContext } from "react-router"
import { CzwjDetailStyle } from "./style"
import { ContestDetailResponse } from "types/contest"
import { contestJudgeType } from "types/common"

const CzwjDetail = () => {
    const { detail } = useOutletContext<{ detail: ContestDetailResponse }>()
    const contestJSON = JSON.parse(detail?.pageCfg || "{}")
    const judgeList: contestJudgeType[] = contestJSON.czwjJudgeList || []
    const codeList: any[] = contestJSON.czwjCode || []
    const downLoad = (value: string) => {
        window.open(value)
    }
    const toJudgeDetail = (id: string) => {
        if (!id) {
            return
        }
        window.open(`/user/${id}/works`)
    }

    return <CzwjDetailStyle>
        <div className="challenge">
            <div className="challenge-top">
                <div className="challenge-one">
                    <img src="https://cdn.ggac.com/img/czwj2-pc-top.png" alt="" />
                    <div className="btn" onClick={() => downLoad("https://www.ggac.com/official/372")}>重启说明</div>
                </div>
                <div className="challenge-two">
                    <div className="title">
                        <div className="title-top">赛事时间</div>
                        <div className="title-bottom">SCHEDULE</div>
                    </div>
                    <div className="word">赛事交流群:631201985</div>
                    <img src="https://cdn.ggac.com/img/czwj2-pc-date.png" alt="" />
                    <div className="date date2">
                        <div className="inner">
                            <div className="date-item">
                                <div>1.</div>
                                <div>投稿通道将在9月28日-10月15日期间重新开放，全部作品状态变为【阶段性作品】。请参赛作者根据新规则自行完成作品风险排查，确保无侵权内容，并在GGAC参赛作品提交页面签订承诺书，签订完成后方可将作品状态标为【完成作品】，未签承诺书的作品将无法参与端内投票。</div>
                            </div>
                            <div className="date-item">
                                2.如使用第三方素材，请标注清楚素材来源并提供相应的授权证明。<span style={{ cursor: "pointer",fontWeight:"bold" }} onClick={() => downLoad("https://www.ggac.com/official/372")}>（详见跳转公告第二部分【风险排查】相关要求）</span>。
                            </div>
                            <div className="date-item">
                                3.端内投票入围名额将由30名增加至50名。
                            </div>
                            <div className="date-item">
                                4.创作规则、入围评选标准、版权说明、注意事项均以本次更新为准。入围作品名单以后续公布名单为准。
                            </div>
                        </div>
                    </div>
                    <div className="date">
                        <div className="date-item">
                            *端内投票具体开启时间以《剑网3》游戏客户端维护时间为准。
                        </div>
                        <div className="date-item">
                            *端内投票活动指在《剑网3》游戏客户端内开展的投票活动。
                        </div>
                        <div className="date-item">
                            *除剑网3评委组评选作品外，GGAC评审团将依据大赛评选标准推选10幅作品直接进入端内投票入围名单。
                        </div>
                        <div className="date-item">
                            *赛事获奖名单将于2023年12月22日日于GGAC赛事活动页面、《剑网3》官网、@剑网3有爱同人秀微博等渠道进行公布。
                        </div>
                    </div>
                </div>
                <div className="challenge-four">
                    <div className="title">
                        <div className="title-top">赛事奖项</div>
                        <div className="title-bottom">PRIZES</div>
                    </div>
                    <div className="word">
                        *所有奖金均为税前金额，将按照国家法律纳税后进行发放。
                    </div>
                    <img
                        className="prize"
                        src="https://cdn.ggac.com/img/czwj_pc_part3.png"
                        alt=""
                    />
                </div>
            </div>
            <div className="challenge-bottom">
                <div className="challenge-five">
                    <div className="title title2">
                        <div className="title-top">赛事评委</div>
                        <div className="title-bottom">JUDGES</div>
                    </div>
                    <div className="judge">
                        {
                            judgeList.map(item => {
                                return <div className="judge-item" key={item.avatar}>
                                    <img src={item.avatar} onClick={() => toJudgeDetail(item.id)} alt="" />
                                    <div className="name">{item.name}</div>
                                    {
                                        item.desc.map((job) => {
                                            return <div className="job" key={job}>
                                                {job}
                                            </div>
                                        })
                                    }
                                </div>
                            })
                        }
                    </div>
                </div>
                <div className="challenge-six">
                    <div className="title title2">
                        <div className="title-top">评选标准</div>
                        <div className="title-bottom">CRITERIA</div>
                    </div>
                    <div className="content">
                        <div>
                            赛事奖项评判标准将充分考虑整体效果，包括但不限于以下评审因素，综合决定入围结果。
                        </div>
                        <div>（1）外装款式作品主题鲜明，设计理念表达清晰。</div>
                        <div>（2）外装结构清晰合理、可实现度高。</div>
                        <div>（3）额外加分项：设计方案包含完整的三视图（正面、背面、侧面），或包含配套发型、挂件、特效脚印等配套设计，或详细阐述设计理念与灵感来源等；</div>
                        <div>
                            （4）初选入围作品将由评委组评选并进入游戏投票，其中GGAC将依据大赛评选标准评选出10幅作品直接进入游戏投票，最终通过游戏内票数角逐出“一代宗师”（1名）、“名满江湖”（2名）、“锋芒毕露”（5名）、“别具一格”（22名）最终奖项。
                        </div>
                    </div>
                </div>
                <div className="challenge-seven">
                    <div className="title title2">
                        <div className="title-top">赛事资讯</div>
                        <div className="title-bottom">LEARN MORE</div>
                    </div>
                    <div className="code">
                        {
                            codeList.map(item => {
                                return <div className="code-item" key={item.id}>
                                    <img className="url" src={item.url} alt=""/>
                                    <div className="name">{item.name}</div>
                                </div>
                            })
                        }
                    </div>
                </div>
            </div >
        </div>
    </CzwjDetailStyle>
}

export default CzwjDetail