import { useEffect, useState } from "react"
import { Workprize2024Style } from "./style"
import { getContestDetail } from "api/contest"
import { ContestDetailResponse } from "types/contest"
import { useLocation, useNavigate } from "react-router-dom"
import { FloatButton } from "antd"

type listType = {
    title: string
    titleEn: string
    id: number
    themes: {
        title: string
        titleEn: string
        id: number
        prizes: {
            title: string
            titleEn: string
            id: number
            money: string
            works: {
                id: number
                title: string
                userId: number
                username: string
                coverUrl: string
                coverUrl_m: string
                avatarUrl: string
            }[]
        }[]
    }[]
}

const Workprize2024 = () => {
    const [detail, setDetail] = useState<ContestDetailResponse>()
    const navigate = useNavigate()
    const [currentData, setCurrentData] = useState<listType>()
    let link = new URLSearchParams(useLocation().search).get("link") || ''
    let id = Number(new URLSearchParams(useLocation().search).get("id") || '1')
    const [list, setList] = useState<listType[]>([])
    const [downloadList1, setDownloadList1] = useState<{ label: string, value: string, value2: string }[]>([])
    const [downloadList2, setDownloadList2] = useState<{ label: string, value: string, value2: string }[]>([])
    useEffect(() => {
        getContestDetail({ code: "ggac6" }).then(res => {
            setDetail(res)
            if (res.onSale === 0) {
                navigate("/")
            }

            if (!link) {
                return
            }
            setTimeout(() => {
                const top = document && document?.getElementById(link)
                if (top) {
                    top.scrollIntoView();
                }
            }, 200)
        })
    }, [link])

    useEffect(() => {
        if (!list) {
            return
        }
        if (list.length === 0) {
            return
        }
        const copyList: listType[] = JSON.parse(JSON.stringify(list))
        const result = copyList.find(item => item.id === 1)
        const downLoadlist = result?.themes.map(res => {
            return {
                label: res.title,
                value: "select-" + res.titleEn.replaceAll(" ", "-") + '-' + res.id,
                value2: res.prizes[0].titleEn
            }
        })
        const finalList = downLoadlist?.filter(item => {
            return item.value2 !== 'Special prize' && item.value2 !== "People's Choice Award"
        })
        if (!finalList) {
            return
        }
        setDownloadList1(finalList)
        const result2 = copyList.find(item => item.id === 2)
        const downLoadlist2 = result2?.themes.map(res => {
            return {
                label: res.title,
                value: "select-" + res.titleEn.replaceAll(" ", "-") + '-' + res.id,
                value2: res.prizes[0].titleEn
            }
        })
        const finalList2 = downLoadlist2?.filter(item => {
            return item.value2 !== 'Special prize' && item.value2 !== "People's Choice Award"
        })
        if (!finalList2) {
            return
        }
        setDownloadList2(finalList2)
    }, [list])

    useEffect(() => {
        if (!detail) {
            return
        }
        const contestJSON = JSON.parse(detail?.pageCfg || "{}")
        setList(contestJSON.workprize)
    }, [detail])

    useEffect(() => {
        if (!list) {
            return
        }
        if (list.length === 0) {
            return
        }
        const result = list.find(item => item.id === id)
        setCurrentData(result)
    }, [list, id])

    const toLink = (value: string) => {
        setTimeout(() => {
            let scrollItem = document.querySelector(`.${value.replaceAll(" ", "-")}`) as HTMLElement
            window.scrollTo({
                top: scrollItem.offsetTop,
                behavior: "smooth",
            });
        }, 100)
    }

    const selectTab = (detail: any) => {
        if (detail.themes && detail.themes.length === 0) {
            window.open(`/contest/${detail.titleEn}/workprize`)
            return
        }
        navigate(`?id=${detail.id}`)
    }

    return <Workprize2024Style>
        <div className="banner">
            <img src="https://cdn-prd.ggac.com/ggac/reg/2024/workprize/2024-workprize-banner.png" alt="" />
            <div className="tab">
                {
                    list.map(item => {
                        return <div onClick={() => selectTab(item)} className={`tab-item ${id === item.id ? 'active' : ''}`} key={item.id}>
                            {item.title}
                            <div className="popup1">
                                {
                                    downloadList1.map(item => {
                                        return <div onClick={() => toLink(item.value)} className="popup1-item" key={item.value}>{item.label}</div>
                                    })
                                }
                            </div>
                            <div className="popup2 popup2">
                                {
                                    downloadList2.map(item => {
                                        return <div onClick={() => toLink(item.value)} className="popup2-item" key={item.value}>{item.label}</div>
                                    })
                                }
                            </div>
                        </div>
                    })
                }
            </div>
        </div>
        {
            !!currentData && <div className="list">
                <div className="list-item">
                    {
                        currentData.themes.map(item => {
                            return <div key={item.id}>
                                {
                                    item.prizes.map(prize => {
                                        return <div key={prize.id}>
                                            <div className={`title-wrap select-${item.titleEn.replaceAll(" ", "-")}-${item.id}`}>
                                                <div className="prize">
                                                    {
                                                        prize.works.length < 5 && <span className="unit">¥</span>
                                                    }
                                                    <span className={`money ${prize.works.length > 5 ? 'gift' : ''}`}>{prize.money}</span>
                                                </div>
                                                <div className="prize-word">
                                                    {item.title} {prize.title} {prize.works.length}名
                                                </div>
                                                <div className="prize-word-en">
                                                    {item.titleEn}{prize.titleEn}
                                                </div>
                                            </div>
                                            <div className="work">
                                                {
                                                    prize.works.map(work => {
                                                        return <div className={`work-item ${prize.works.length > 5 ? 'more-work' : ''}`} key={work.id}>
                                                            <a href={`https://www.ggac.com/work/detail/${work.id}`} target="_blank" className="cover" style={{ backgroundImage: `url(${work.coverUrl})` }}></a>
                                                            <div className="work-detail">
                                                                <a href={`https://www.ggac.com/user/${work.userId}`} className="user" target="_blank">
                                                                    <div className="user-avatar" style={{ backgroundImage: `url(${work.avatarUrl})` }}></div>
                                                                    <div className="user-name">{work.username}</div>
                                                                </a>
                                                                <a href={`https://www.ggac.com/work/detail/${work.id}`} target="_blank" className="work-name">{work.title}</a>
                                                            </div>
                                                        </div>
                                                    })
                                                }
                                            </div>
                                        </div>
                                    })
                                }
                            </div>
                        })
                    }
                </div>
            </div>
        }
        <FloatButton.Group shape="circle">
            <FloatButton.BackTop tooltip="回到顶部" />
        </FloatButton.Group>
    </Workprize2024Style >
}

export default Workprize2024