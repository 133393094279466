import { WorkDetailType } from "types/work"
import { WorkVoteStyle } from "./style"
import { useEffect, useRef, useState } from "react"
import { Button, message } from "antd"
import { ContestDetailResponse, ContestWorkTotalType, VoteUserTotal } from "types/contest"
import { getContestDetail, getContestUserVoteTotal, getContestWorkTotal, postAwardCandidateVote, postContestUserTicket, postOneVote } from "api/contest"
import { UserBaseInfo } from "types/user"
import VoteTicket from "components/vote-ticket"
import { useNavigate } from "react-router-dom"
import { loginPathWithRedirect } from "utils/functions/user"

const WorkVote = ({ detail, updateDetail }: { detail: WorkDetailType, updateDetail: () => void }) => {
    const { isContest, contestWork, exhibitionWork } = detail
    const [voteLoading, setVoteLoading] = useState(false)
    const [contestDetail, setContestDetail] = useState<ContestDetailResponse>()
    const [canVoteTotal, setCanVoteTotal] = useState(0)
    const [isShow, setIsShow] = useState(false)
    let userInfo: UserBaseInfo = JSON.parse(localStorage.getItem("user_info")!)
    const voteRef = useRef() as React.MutableRefObject<any>;
    const navigate = useNavigate()
    const [workTicketDetail, setWorkTicketDetail] = useState<ContestWorkTotalType>()
    const [oneVoteLoading, setOneVoteLoading] = useState(false)
    const [voteDetail, setVoteDetail] = useState<VoteUserTotal>()

    const toVote = () => {
        if (!userInfo?.id) {
            navigate(loginPathWithRedirect())
            return
        }
        if (!voteDetail) {
            return
        }
        if (voteDetail.useRule === 1 && voteDetail.voteStatus === 0 && voteDetail.validTicketCount > 0) {
            vote(1)
        } else if (canVoteTotal > 0) {
            setIsShow(true)
        }

    }

    const close = () => {
        setIsShow(false)
    }

    useEffect(() => {
        if (!!isContest) {
            getContest()
            return
        }
        if (!isContest && !!exhibitionWork) {
            getWorkContestVote()
        }
    }, [detail, isContest])

    const getContest = () => {
        getContestDetail({ id: Number(contestWork.contestId) }).then(res => {
            setContestDetail(res)
            getWorkContestVote()
        })
    }

    const getUserContestVote = () => {
        getContestUserVoteTotal({ id: Number(detail?.exhibitionWork?.voteId) || Number(contestDetail?.voteId), workId: detail.id }).then(res => {
            if (res.useRule === 1 && res.voteStatus === 1) {
                setCanVoteTotal(0)
            } else {
                setCanVoteTotal(res.validTicketCount)
            }
            setVoteDetail(res)
        })
    }

    useEffect(() => {
        if (!(contestDetail?.voteId || (detail?.exhibitionWork?.voteId))) {
            return
        }
        if (userInfo?.id) {
            getUserContestVote()
        }
    }, [contestDetail?.voteId, detail?.exhibitionWork?.voteId])

    const getWorkContestVote = () => {
        const params: any = {
            workId: detail?.id
        }
        if (!!detail?.exhibitionWork?.voteId) {
            params.type = 2
            params.eventExhibitionId = detail?.exhibitionWork.eventExhibitionId
        }
        getContestWorkTotal(params).then(res => {
            setWorkTicketDetail(res)
        })
    }

    const vote = (value: number) => {
        if (!userInfo?.id) {
            navigate(loginPathWithRedirect())
            return
        }
        let voteId
        if (!!detail?.exhibitionWork?.voteId) {
            voteId = Number(detail.exhibitionWork.voteId)
        } else {
            voteId = Number(contestDetail?.voteId)
        }
        setVoteLoading(true)
        postContestUserTicket({ count: value, id: voteId, workId: detail?.id }).then(res => {
            getUserContestVote()
            getWorkContestVote()
            message.success("投票成功")
            close()
        }).finally(() => {
            voteRef.current?.resetLoading()
            setVoteLoading(false)
        })
    }


    // 一票晋级
    const oneVote = () => {
        setOneVoteLoading(true)
        postOneVote(detail?.id).then(res => {
            message.success(`${detail?.contestWork?.oneVoteName}成功`)
            updateDetail()
        }).finally(() => {
            setOneVoteLoading(false)
        })
    }

    // 获奖获选
    const twoVote = () => {
        setOneVoteLoading(true)
        postAwardCandidateVote(detail?.id).then(() => {
            message.success(`${detail?.contestWork?.oneVoteName}成功`)
            updateDetail()
        }).finally(() => {
            setOneVoteLoading(false)
        })
    }


    return <WorkVoteStyle>
        {
            detail.publishType === 2 && workTicketDetail && workTicketDetail?.status > 0 && <>
                <Button
                    disabled={(canVoteTotal === 0 && (!!userInfo?.id)) || workTicketDetail?.status === 1}
                    loading={voteLoading}
                    style={{ width: '100%' }}
                    onClick={toVote}
                    type="primary"
                >
                    投票 {workTicketDetail?.ticketCount}
                </Button>
                {workTicketDetail?.status === 2 && <div className="tip">当前可使用票数：{voteDetail?.validTicketCount} </div>}
            </>
        }
        {
            !!detail?.exhibitionWork?.voteId && <>
                <Button
                    disabled={(canVoteTotal === 0 && (!!userInfo?.id)) || workTicketDetail?.status === 1}
                    loading={voteLoading}
                    style={{ width: '100%' }}
                    onClick={toVote}
                    type="primary"
                >
                    投票  {workTicketDetail?.ticketCount}
                </Button>
                {workTicketDetail?.status === 2 && <div className="tip">当前可使用票数：{voteDetail?.validTicketCount} </div>}
            </>
        }
        {
            detail?.contestWork?.oneVoteFlag === 1 && <Button
                className="onVoteBtn"
                loading={oneVoteLoading}
                style={{ width: '100%' }}
                onClick={oneVote}
                type="primary"
            >
                {detail?.contestWork?.oneVoteName}
            </Button>
        }
        {
            detail?.contestWork?.oneVoteFlag === 2 && <Button
                className="onVoteBtn"
                loading={oneVoteLoading}
                disabled={!!detail?.contestWork?.isCandidateVote}
                style={{ width: '100%' }}
                onClick={twoVote}
                type="primary"
            >
                {detail?.contestWork?.oneVoteName}
            </Button>
        }
        <VoteTicket ref={voteRef} isShow={isShow} vote={vote} close={close} ticket={canVoteTotal} />
    </WorkVoteStyle>
}

export default WorkVote