import { useOutletContext } from "react-router-dom"
import { Billboard2024UserStyle } from "./style"
import { useEffect, useState } from "react"
import { ContestDetailResponse } from "types/contest"

const Billboard2024Work = () => {
    const { detail } = useOutletContext<{ detail: ContestDetailResponse }>()
    const [userList, setUserList] = useState<{ name: string, tip: string, id: number, list: { id: number, username: string, avatarUrl: string, top: number }[] }[]>([])
    useEffect(() => {
        if (!detail) {
            return
        }
        const pageCfg = JSON.parse(detail.pageCfg)
        const work = pageCfg.billboard.user
        setUserList(work)
    }, [detail])

    const toWorkDetail = (id: number) => {
        window.open(`https://www.ggac.com/user/${id}`)
    }
    return <Billboard2024UserStyle>
        <div className="inner">
            <div>
                {
                    userList.map((item) => {
                        return <div key={item.id}>
                            <div className="top">
                                <div className="title-wrap">
                                    <img src="https://cdn-prd.ggac.com/ggac/reg/2024/billboard/2024-billboard-title-left.png" />
                                    <div className="title">{item.name}</div>
                                    <img src="https://cdn-prd.ggac.com/ggac/reg/2024/billboard/2024-billboard-title-right.png" />
                                </div>
                                <div className="tip">{item.tip}</div>
                            </div>

                            <div className="user">
                                {
                                    item.list.map(user => {
                                        return <div onClick={() => toWorkDetail(user.id)} className="user-item" key={user.id}>
                                            <div className="cover-wrap">
                                                <div className="user-cover" style={{ backgroundImage: `url(${user.avatarUrl})` }}></div>
                                            </div>
                                            <div className="user-name">{user.username}</div>
                                        </div>
                                    })
                                }
                            </div>
                        </div>
                    })
                }
            </div>
        </div>
    </Billboard2024UserStyle>
}

export default Billboard2024Work