import styled from "styled-components";
export const Ceremony2024Style = styled.div`
    @font-face {
        font-family: 'AlibabaPuHuiTiR';
        src: url('https://cdn-prd.ggac.com/ggac/font/AlibabaPuHuiTi.ttf');
    }
    font-family: 'AlibabaPuHuiTiR';
    .banner {
        img {
            width: 100%;
        }
    }
    .tab-wrap {
        position: sticky;
        top: 0;
        left: 0;
        z-index: 201;
    }
    .tab {
        width: 100%;
	    height: 70px;
	    background-image: linear-gradient(100deg, #6669cf 0%, #4c79d8 100%), 
	    linear-gradient(#3664a1, #3664a1);
	    background-blend-mode: normal, normal;
        display: flex;
        justify-content: center;
        gap: 50px;
        align-items: center;
        margin-top: -6px;
        &-item {
            font-size: 22px;
	        color: #fff;
            cursor: pointer;
            position: relative;
        }
        .active::after,&-item:hover::after {
            content: "";
            height: 4px;
            width: 100%;
            position: absolute;
            left: 50%;
            transform: translateX(-50%);
            background-color: #fff;
            bottom: -11px;
        }
    }
    

    .bg  {
        width: 100%;
        background: url("https://cdn-prd.ggac.com/ggac/ceremony/2024/2024-ceremony-bg-2.jpg") center center / 100% 100% no-repeat;
        padding: 1px 0 200px;

        .btn3 {
            background: url("https://cdn-prd.ggac.com/ggac/ceremony/2024/2024-ceremony-part2-btn.png") center center / 100% 100% no-repeat; 
            height: 60px;
            aspect-ratio: 312 / 71;
            display: flex;
            justify-content: center;
            align-items: center;
            font-size: 26px;
	        color: #fff;
            margin: 50px auto 0;
            cursor: pointer;
            margin-bottom: 50px;
            &:hover {
                background: url("https://cdn-prd.ggac.com/ggac/ceremony/2024/2024-ceremony-part2-btn-hover.png") center center / 100% 100% no-repeat !important;
            }
        }

        iframe {
            width: 1200px;
            object-fit: cover;
            height: 600px;
            display: block;
            margin: 0 auto 50px;
        }

        video  {
            margin: 0 auto 50px;
            width: 1200px;
            display: block;
            object-fit: fill;
        }

        .title {
            text-align: center;
            margin: 110px 0 43px;
        }
        .sub-title  {
            font-size: 35px;
	        color: #1f212c;
            text-align: center;
            margin: 60px 0 30px;
            font-weight: bold;
        }
        .third-title {
            text-align: center;
            font-weight: bold;
            font-size: 25px !important;
            margin-bottom: 20px;
        }
        .image {
            text-align: center;
            margin-bottom: 30px;
            img {
                width: 1200px;
            }
        }
        .part1 {
            &-border {
                display: flex;
                flex-direction: column;
                justify-content: center;
                align-items: center;
                gap: 10px;
                &-top,&-bottom {
                    background: url("https://cdn-prd.ggac.com/ggac/ceremony/2024/2024-ceremony-part1-bg1.png") center center / 100% 100% no-repeat !important;
                    width: 1200px;
                    height: 270px;
                    display: flex;
                    position: relative;
                    .person {
                        position: absolute;
                        right: -85px;
                        top: -50px;
                        width: 222px;
                    }
                    & > div  {
                        flex: 1;
                        display: flex;
                        justify-content: center;
                        align-items: center;
                        color: #fff;
                        flex-direction: column;
                        div:first-child {
                            font-size: 38px;
                        }
                        div:nth-child(2) {
                            font-size: 24px;
                            color: rgba(255,255,255,.8);
                            text-align: center;
                        }
                    }
                }
                &-bottom {
                    background: url("https://cdn-prd.ggac.com/ggac/ceremony/2024/2024-ceremony-part1-bg2.png") center center / 100% 100% no-repeat !important;
                    width: 1200px;
                    height: 289px;
                }
            }
            .date {
                width: 1200px;
                margin: 0 auto;
                .date-line {
                    .date-top {
                        display: flex;
                        flex-direction: column;
                        position: relative;
                        .icon {
                            position: absolute;
                            width: 49px;
                            height: 48px;
                            top: 50%;
                            transform: translateY(-50%);
                            right: 0;
                            background: url("https://cdn-prd.ggac.com/ggac/ceremony/2024/2024-ceremony-part1-icon2.png") center center / 100% 100% no-repeat;
                        }
                        .time {
                            font-size: 34px;
	                        color: #1f212c;
                        }
                        .line  {
                            width: 1144px;
	                        height: 1px;
	                        background-color: #000;
                            margin: 2px 0 5px;
                        }
                        .date-tip {
                            font-size: 18px;
	                        letter-spacing: 1px;
	                        color: #1f212c;
                        }
                    }
                }
                .dashed  {
                    border-bottom: 1px dashed #000;
                }
                .date-content {
                    display: flex;
                    padding: 20px 0;
                    justify-content: space-between;
                    .left {
                        .word {
                            font-size: 34px;
	                        color: #1f212c;
                            line-height: 40px;
                            font-weight: bold;
                        }
                    }
                    .right  {
                        .item {
                            display: flex;
                            width: 817px;
                            gap: 56px;
                            align-items: flex-start;
                            border-bottom: 1px dashed #000;
                            padding-top: 25px;
                            &:last-child {
                                border-bottom: none !important;
                            }
                            &:first-child {
                               padding: 0 !important; 
                            }
                            .label {
                                display: flex;
                                align-items: center;
                                gap: 10px;
                                font-size: 20px;
	                            color: #1f212c;
                                &-icon {
                                    width: 8px;
                                    height: 16px;
                                    background: url("https://cdn-prd.ggac.com/ggac/ceremony/2024/2024-ceremony-part1-icon3.png") center center / 100% 100% no-repeat;
                                }
                            }
                            .value {
                                flex-grow: 1;
                                .date-title {
                                    font-size: 24px;
	                                line-height: 37px;
	                                color: #1f212c;
                                    font-weight: bold;
                                    display: flex;
                                    gap: 30px;
                                    margin-top: -5px;
                                }
                                .judge-sub-title {
                                    font-size: 16px;
                                    margin-top: 5px;
                                }
                                .date-prize {
                                    font-size: 24px;
	                                line-height: 37px;
	                                color: #1f212c;
                                    display: flex;
                                    align-items: center;
                                    gap: 19px;
                                    font-weight: bold;
                                    span {
                                        font-size: 18px;
                                        font-weight: normal !important;
                                    }
                                }
                                .mb0 {
                                    margin-bottom: 0 !important;
                                }
                                .third-judge-info {
                                    &>div {
                                        width: calc((100% - 10px) / 2) !important;
                                    }
                                    
                                }
                                .judge-info {
                                    display: flex;
                                    gap: 5px;
                                    flex-wrap: wrap;
                                    & > div {
                                        width: calc((100% - 10px) / 3);
                                    }
                                    .info-tip {
                                        div {
                                            font-size: 13px;
                                        }
                                    }
                                    .info-name {
                                        font-size: 17px !important;
                                    }
                                    .prize-judge {
                                        gap: 12px !important;
                                    }
                                }
                                .prize-judge {
                                    display: flex;
                                    align-items: center;
                                    gap: 23px;
                                    margin: 15px 0 25px;
                                    img {
                                        width: 80px;
                                    }
                                    
                                    .info {
                                        &-name {
                                            font-size: 24px;
	                                        color: #1f212c;
                                            line-height: 30px;
                                            margin-top: -5px;
                                        }
                                        &-tip {
                                            font-size: 14px;
	                                        line-height: 20px;
	                                        color: #1f212c;
                                        }
                                    }
                                }
                            }
                        }
                    }
                }
            }
            .judge {
                display: flex;
                justify-content: center;
                gap: 120px;
                align-items: flex-end;
                width: 1200px;
                margin: 0 auto;
                flex-wrap: wrap;
                row-gap: 46px;
                &-tip {
                    text-align: center;
                    margin-top: 30px;
                }
                &-item {
                    padding-bottom: 50px;
                    position: relative;
                    .judge-top {
                        position: relative;
                        img {
                            width: 210px;
                        }
                        .name {
                            background: url("https://cdn-prd.ggac.com/ggac/ceremony/2024/2024-ceremony-judge-name.png") center center / 100% 100% no-repeat !important;
                            width: 84px;
                            height: 85px;
                            position: absolute;
                            bottom: 6px;
                            right: 0;
                            display: flex;
                            justify-content: center;
                            align-items: center;
                            flex-direction: column;
                            font-size: 22px;
	                        line-height: 24px;
	                        color: #ffffff;
                        }
                    }
                    .judge-desc {
                        position: absolute;
                        bottom: 0;
                        left: 50%;
                        transform: translateX(-50%);
                        div {
                            width: max-content;
                            margin: 0 auto;
                            line-height: 20px;
                        }
                    }
                }
            }
        }

        .part2 {
            width: 1200px;
            margin: 0 auto;
            .activity-title {
                color: #1f212c;
                text-align: center;
                div:first-child {
                    font-size: 24px;
                }
                div:nth-child(2) {
                    font-size: 40px;
                    line-height: 40px;
                }
                &-tip {
                    font-size: 24px;
	                color: #1f212c;
                    margin-top: 10px;
                }
            }
            .border {
                background: url("https://cdn-prd.ggac.com/ggac/ceremony/2024/2024-ceremony-part2-border1.png") center center / 100% 100% no-repeat;
                width: 1200px;
                height: 574px;
                margin: 40px 0 100px;
                padding: 65px 0;
                .prize {
                    display: flex;
                    gap: 70px;
                    justify-content: center;
                    text-align: center;
                    .name {
                        font-size: 22px;
                        color: #1f212c;
                        font-weight: bold;
                    }
                    .tip {
                        div {
                            line-height: 22px;
                            font-size: 18px;
                        }
                    }
                }
            }
            .btn {
                background: url("https://cdn-prd.ggac.com/ggac/ceremony/2024/2024-ceremony-part2-btn.png") center center / 100% 100% no-repeat; 
                height: 60px;
                aspect-ratio: 312 / 71;
                display: flex;
                justify-content: center;
                align-items: center;
                font-size: 26px;
	            color: #fff;
                margin: 50px auto 0;
                cursor: pointer;
                &:hover {
                    background: url("https://cdn-prd.ggac.com/ggac/ceremony/2024/2024-ceremony-part2-btn-hover.png") center center / 100% 100% no-repeat !important;
                }
            }
            .border2 {
                &-top, &-bottom {
                    background: url("https://cdn-prd.ggac.com/ggac/ceremony/2024/2024-ceremony-part2-bg2.png") center center / 100% 100% no-repeat !important;
                    width: 1200px;
                    height: 276px;
                    display: flex;
                    margin-top: 40px;
                    &-item {
                        flex: 1;
                        display: flex;
                        justify-content: center;
                        align-items: center;
                        gap: 20px;
                        flex-direction: column;
                        text-align: center;
                        .name {
                            font-size: 30px;
	                        line-height: 28px;
	                        color: #fff;
                        }
                        .tip {
                            font-size: 18px;
                            line-height: 28px;
                            color: rgba(255,255,255,.8);
                            span {
                                color: #fff000;
                            }
                        }
                    }
                }
                &-bottom {
                    background: url("https://cdn-prd.ggac.com/ggac/ceremony/2024/2024-ceremony-part2-bg3.png") center center / 100% 100% no-repeat !important;
                    width: 1200px;
                    height: 276px;
                    margin: 15px 0 40px;
                    &-item:nth-child(2) {
                        img {
                            transform: translate(-13px,-10px);
                        }
                        .name {
                            margin-top: -25px;
                        }
                    }
                }
            }
            .btn2 {
                margin: 0 auto 50px !important;
            }
            .sub-title {
                line-height: 30px;
            }
            .part-tip  {
                font-size: 24px;
	            line-height: 28px;
	            color: #1f212c;
                text-align: center;
            }
            .border3 {
                background: url("https://cdn-prd.ggac.com/ggac/ceremony/2024/2024-ceremony-part2-bg4.png") center center / 100% 100% no-repeat !important;
                width: 1200px;
                height: 270px;
                display: flex;
                margin: 40px 0;
                &-item {
                    flex: 1;
                    display: flex;
                    flex-direction: column;
                    justify-content: center;
                    align-items: center;
                    img {
                        margin-bottom: 30px;
                    }
                    .name {
                        div {
                            font-size: 24px;
	                        line-height: 28px;
                        }
                    }
                }
            }
        }
        .third-title {
            font-size: 50px;
            display: flex;
            justify-content: center;
            align-items: center;
            gap: 20px;
            img {
                width: 27px;
            }
            div {
                background: -webkit-linear-gradient(90deg, #854FFF, #4958FF); /* Chrome, Safari */
                background: linear-gradient(90deg, #854FFF, #4958FF); /* 标准语法 */
                -webkit-background-clip: text; /* Chrome, Safari */
                background-clip: text;
                -webkit-text-fill-color: transparent; /* Chrome, Safari */
            }
        }
        .part3  {
            text-align: center;
            .list {
                display: flex;
                justify-content: center;
                gap: 25px;
                width: 1200px;
                margin: 0 auto 60px;
                &-item {
                    flex: 1;
                    .cover {
                        width: 100%;
                        aspect-ratio: 450 / 250;
                        background-color: #e2e3f1;
                    }
                    &-name {
                        font-size: 20px;
	                    line-height: 22px;
	                    color: #252834;
                        /* overflow: hidden;
                        text-overflow: ellipsis;
                        white-space: nowrap; */
                        margin-top: 20px;
                        width: 100%;
                    }
                }
            }
        }
        .part4 {
            .third-title {
                margin-bottom: 30px;
            }
            .cooperation {
                background: url("https://cdn-prd.ggac.com/ggac/ceremony/2024/2024-ceremony-part4-border.png") center center / 100% 100% no-repeat;
                width: 1200px;
                height: 861px;
                margin: 0 auto;
                padding: 110px 55px 0;
                display: flex;
                flex-direction: column;
                gap: 15px;
                position: relative;
                &-icon {
                    position: absolute;
                    top: 85px;
                    right: -100px;
                }
                &-item {
                    &-title {
                        font-size: 20px;
	                    line-height: 28px;
	                    color: #262626;
                    }
                    &-tip {
                        font-size: 14px;
	                    line-height: 25px;
	                    color: #262626;
                        opacity: 0.8;
                    }
                }
            }
        }
        .part5 {
            .third-title {
                margin-bottom: 30px;
            }
            .about {
                background: url("https://cdn-prd.ggac.com/ggac/ceremony/2024/2024-ceremony-part5-border.png") center center / 100% 100% no-repeat;
                width: 1200px;
                height: 618px;
                margin: 0 auto 50px;
                padding: 150px 60px 0;
                display: flex;
                flex-direction: column;
                gap: 35px;
                font-size: 18px;
                &-item {
                    line-height: 28px;
                    &:last-child {
                        font-size: 26px;
	                    font-style: italic;
	                    line-height: 28px;
	                    color: #262626;
                        font-weight: bold;
                    }
                }
            }
            .contest {
                display: flex;
                flex-direction: column;
                gap: 30px;
                justify-content: center;
                align-items: center;
                &-item {
                    position: relative;
                    img {
                        width: 1200px;
                    }
                    .btn {
                        width: 140px;
	                    height: 34px;
	                    border-radius: 17px;
	                    border: solid 1px #fff;
                        position: absolute;
                        bottom: 40px;
                        left: 50%;
                        transform: translateX(-50%);
                        font-size: 16px;
	                    color: #ffffff;
                        line-height: 30px;
                        text-align: center;
                        cursor: pointer;
                    }
                }
                
            }
        }

        .code {
            display: flex;
            gap: 50px;
            justify-content: center;
            &-item {
                width: 260px;
                height: 312px;
                background: url("https://cdn-prd.ggac.com/ggac/ceremony/2024/2024-ceremony-code-image.png") center center / 100% 100% no-repeat;
                display: flex;
                flex-direction: column;
                justify-content: space-between;
                align-items: center;
                .code-top {
                    height: 67px;
                    width: 100%;
                    display: flex;
                    align-items: center;
                    justify-content: center;
                    font-size: 26px;
                    color: #fff;
                    gap: 13px;
                }
                .code-bottom {
                    width: 100%;
                    justify-content: center;
                    padding-bottom: 26px;
                    text-align: center;
                }
            }
        }

        .code-tip {
            font-size: 25px;
            text-align: center;
            margin-top: 100px;
            color: #1f212c;
            a{
                color: #4a58ff;
            }
        }
    }

    .absolute {
        position: fixed;
        top: 50%;
        transform: translateY(-50%);
        right: 30px;
        display: flex;
        flex-direction: column;
        &-item {
            cursor: pointer;
            position: relative;
            &:first-child {
                &:hover {
                    .popup {
                        display: block;
                    }
                }
            }
            &:nth-child(2) {
                &:hover {
                    .popup {
                        display: block;
                    }
                }
            }
            &:nth-child(3) {
                &:hover {
                    .popup {
                        display: block;
                    }
                }
            }
            .popup  {
                position: absolute;
                left: -110px;
                top: 50%;
                transform: translateY(-50%);
                background-color: #fff;
                padding: 1px 2px;
                display: none;
                img {
                    width: 100px;
                    transform: translateY(4px);
                }
            }
        }
    }
`