import { Empty } from 'antd'
import { NoDataStyle } from './style'

export const NoData = (props: { tips?: string }) => {


    return <NoDataStyle>
        {/* <div className="no-data-container">
            <div className="img">
                <img src="https://cdn-prd.ggac.com/icon/empty.svg" alt="" width={80} />
            </div>
            <div className="tips">{props.tips ?? '暂无数据'}</div>
        </div> */}
        <Empty image={Empty.PRESENTED_IMAGE_SIMPLE} description={props.tips} />
    </NoDataStyle>

}