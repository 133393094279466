import styled from "styled-components";

export const VideoContentDisplayStyle =styled.div`
.video-wrap {
    position: relative;
    margin: '0 auto';
    .tag {
        position: absolute;
        top: 30px;
        right: 30px;
        color: #fff;
        z-index: 50;
        cursor: pointer;
    }
}
video{
    max-height: 100vh;
}
`