import { Navigate, RouteObject } from "react-router-dom";
import Regtulation2024 from "views/contest/2024";
import Regtulation2024About from "views/contest/2024/about";
import Billboard2024 from "views/contest/2024/billboard";
import Billboard2024Work from "views/contest/2024/billboard/work";
import Billboard2024User from "views/contest/2024/billboard/user";
import CereMony2024 from "views/contest/2024/ceremony";
import Regulatioin2024Detail from "views/contest/2024/detail";
import Regulation2024Previous from "views/contest/2024/previous";
import Regultion2024Rule from "views/contest/2024/rule";
import Regulation2024Work from "views/contest/2024/work";
import Workprize2024 from "views/contest/2024/workprize";
import Layout from "views/layout";

export const match2024Routes: RouteObject[] = [
    {
        path: '/2024/regulation',
        element: <Navigate to="/2024/regulation/detail"></Navigate>
    },
    {
        path: '/2024/regulation/workprize',
        element: <Workprize2024 />,
    },
    {
        path: '/2024/billboard',
        element: <Navigate to="/2024/billboard/work"></Navigate>
    },
    {
        path: '/2024',
        element: <Layout></Layout>,
        children: [
            {
                path: 'billboard',
                element: <Billboard2024 />,
                children: [
                    {
                        path: 'work',
                        element: <Billboard2024Work />
                    },
                    {
                        path: 'user',
                        element: <Billboard2024User />
                    },
                ]
            },
            {
                path: 'regulation',
                element: <Regtulation2024></Regtulation2024>,
                children: [
                    {
                        path: 'detail',
                        element: <Regulatioin2024Detail></Regulatioin2024Detail>
                    },
                    {
                        path: 'rule',
                        element: <Regultion2024Rule />,
                    },
                    {
                        path: 'previous',
                        element: <Regulation2024Previous />,
                    },
                    {
                        path: 'about',
                        element: <Regtulation2024About />,
                    },
                    {
                        path: 'work',
                        element: <Regulation2024Work name='ggac6' />,
                    }
                ]
            },
            {
                path: "ceremony",
                element: <CereMony2024 />
            }
        ]
    }
]