import { getV2, postV2 } from "api/old-api";
import { get, post } from "api/index";
import { UserBaseInfo, userDetail, UpdateUserPasswordRequest, UpdateUserMobileRequest, followUserRequest, UserViewHistoryRequest, UserViewHistoryItem, getAuthorItem, UserListRequest, userIdentification, UserIdentificationDetailResponse, studentIdentification, studentIdentificationDetailResponse, CouponDrawRequest, CouponDrawResponse, CouponCanUseListRequest, CouponCanUseListItem, AllUserRedDot, UserUserAccountList, EmailBindRequest, InfoCollectConfigItem, InfoCollectSaveRequest } from "types/user";
import {
  ModifyMobileRequest,
  PwdLoginRequest,
  SecurityInformation,
  SmsLoginRequest,
  UserVerCodeEmailLoginRequest,
  UserVerCodeEmailRegister,
  UserVerCodeEmailRegisterRequest,
} from "types/user/security";
import { CompleteUserInfoRequest } from "types/user/auth";
import { PaginationResponse } from "types/common/axios";
// 获取用户详情
export const apiGetUserDetail = async (uid: number) =>
  await getV2<{ uid: number }, userDetail>(`/api/v2/user/info`);

type userFocusType = {
  uid: number;
};

// 关注用户
export const ApiPostFocusUser = async (params: userFocusType) =>
  await postV2<userFocusType, { focused: number }>(
    `/api/v2/user/focus`,
    params
  );

/* 安全中心*/
export const apiGetSecurityInformation = async () => {
  return await get<{}, SecurityInformation>(
    "/api/v6/neo/web/user/security_information"
  );
};

/* 安全中心-修改手机号*/
export const apiModifyMobile = async (params: ModifyMobileRequest) => {
  await post<ModifyMobileRequest, number>(
    "/api/v6/neo/web/user/modify_mobile",
    params
  );
};

/* 用户登录 */

/* 用户登录 - 密码登录*/
export const apiLoginUserByPwd = async (params: PwdLoginRequest) => {
  return await post<PwdLoginRequest, string>(
    "/api/user/password_login",
    params
  );
};

/* 用户登录/注册 - 验证码登录/注册*/
export const apiLoginUserBySms = async (params: SmsLoginRequest) => {
  return await post<SmsLoginRequest, {
    "isNew": number,
    "token": string
  }>(
    "/api/user/ver_code_login",
    params
  );
};

export const postLoginByEmail = async (params: UserVerCodeEmailLoginRequest) =>
  await post<UserVerCodeEmailLoginRequest, UserVerCodeEmailRegister>("/api/user/ver_code_email_login", params)

export const postRegisterByEmail = async (params: UserVerCodeEmailRegisterRequest) =>
  await post<UserVerCodeEmailRegisterRequest, UserVerCodeEmailRegister>("/api/user/ver_code_email_register", params)




//获取用户基础信息
export const apiGetUserBaseInfo = async () => {
  return await get<any, UserBaseInfo>(`/api/user/base_info`);
}

// 我的第三方账户列表
export const apiGetUserAccountList = async () =>
  await get<{}, UserUserAccountList>(`/api/user/user_account_list`);


//获取随机用户名
export const apiGetRandomUserName = async () => {
  return await post<any, string>(`/api/user/random_username`);
}

//补全用户信息
export const apiCompleteUserInfo = async (params: CompleteUserInfoRequest) => {
  return await post<any, any>(`/api/user/first_update`, params);
}


//用户退出登录
export const apiUserLogout = async () => {
  return await post<any, any>(`/api/user/logout`);
}


// 设置用户新密码
export const apiUpdatePassword = async (params: UpdateUserPasswordRequest) => {
  return await post<UpdateUserPasswordRequest, {}>(`/api/user/password_update`, params);
}

// 修改用户手机号
export const apiUpdateMobile = async (params: UpdateUserMobileRequest) => {
  return await post<UpdateUserMobileRequest, {}>(`/api/user/mobile_update`, params);
}

// 绑定邮箱
export const apiBindEmail = async (params: EmailBindRequest) => {
  return await post<EmailBindRequest, {}>(`/api/email/bind`, params);
}

// 关注用户
export const postFollowUser = (params: followUserRequest) =>
  post<followUserRequest, {}>("/api/follow/flag", params)

// 个人主页浏览记录
export const getViewUserHistoryList = (params: UserViewHistoryRequest) =>
  get<UserViewHistoryRequest, UserViewHistoryItem[]>(`/api/view/user_homepage/${params.userId}`, params)

// 首页推荐作者
export const getHomeRecommendAuthorList = (count: number) =>
  get<{}, getAuthorItem[]>(`/api/recommend/user_list/${count}`)


export const getAuthorList = (params: UserListRequest) =>
  get<UserListRequest, PaginationResponse<getAuthorItem>>("/api/all_user/list", params)

export const postUpdateUsername = (username: string) =>
  post<{ username: string }, {}>("/api/user/username_update", { username })

// 用户化身登陆
export const masklogin = async (params: { accessCode: string, userId: number }) => {
  return post<{ accessCode: string, userId: number }, string>("/api/user/mask_login", params)
}

export const apiUpdateUserCfg = (params: {
  isCopyright?: number
  isHandoff?: number
}) => {
  return post<{
    isCopyright?: number
    isHandoff?: number
  }, any>("/api/user/cfg_update", params)
}


// 用户身份证实名
export const postUserIdentification = (params: userIdentification) =>
  post("/api/user_identification/save", params)

// 获取实名信息
export const getUserIdentification = () =>
  get<{}, UserIdentificationDetailResponse>(`/api/user_identification/detail`)

// 用户学生证实名
export const postStudentIdentification = (params: studentIdentification) =>
  post("/api/student_card/save", params)

// 获取实名信息
export const getStudentIdentification = (userId: number) =>
  get<{ id: number }, studentIdentificationDetailResponse>(`/api/student_card/detail/${userId}`)


//领取优惠券
export const getUserCoupon = (params: CouponDrawRequest) =>
  post<CouponDrawRequest, CouponDrawResponse>("/api/coupon/draw", params)

//用户可用优惠券
export const getUserAvailableCoupons = (params: CouponCanUseListRequest) =>
  get<CouponCanUseListRequest, CouponCanUseListItem[]>(`/api/coupon/can_use_list`, params)


//用户公开-红点

export const getUserHomepageRedDots = () =>
  get<any, AllUserRedDot>(`/api/all_user/red_dot`)

//用户公开-清除红点

export const clearUserHomepageRedDots = (params: { type: number }) =>
  get<{ type: number }, AllUserRedDot>(`/api/all_user/clear_red_dot/${params.type}`)

// 信息收集-配置
export const getCollectNotice = () =>
  get<{}, InfoCollectConfigItem[]>(`/api/info_collect/config`)

// 信息收集-配置
export const postSaveNotice = (params: InfoCollectSaveRequest) =>
  post<InfoCollectSaveRequest, {}>(`/api/info_collect/save`, params)