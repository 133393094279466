import { WorkContestListItem } from "types/contest"
import { ContestVoteCardStyle } from "./style"
import { Button } from "antd"
import { UserBaseInfo } from "types/user"
import { useNavigate } from "react-router-dom"
import { WorkExhibitionListItem } from "types/user/work"
let userInfo: UserBaseInfo = JSON.parse(localStorage.getItem("user_info")!)

const ContestVoteCard = ({ item, canVoteTotal, isShowTicket = true, selectVoteItem }: { item: WorkContestListItem | WorkExhibitionListItem, canVoteTotal: number, isShowTicket?: boolean, selectVoteItem: (item: any) => void }) => {
    const navigate = useNavigate()
    const toVote = () => {
        if (!userInfo?.id) {
            navigate("/auth/login")
            return
        }
        selectVoteItem(item)
    }

    return <ContestVoteCardStyle>
        {
            item?.vote && item?.vote.status && item?.vote.status > 0 && <>
                <Button
                    disabled={(canVoteTotal === 0 && !!(userInfo && userInfo.id)) || item.vote.status === 1}
                    style={{ width: '100%' }}
                    onClick={(e) => { e.stopPropagation(); toVote() }}
                    type="primary"
                    className="btn"
                >
                    投票 {isShowTicket ? item?.vote.ticketCount : ""}
                </Button>

            </>
        }
    </ContestVoteCardStyle>
}

export default ContestVoteCard