import { CzwjWorkprizeStyle } from "./style"

const CzwjWorkprize = () => {

    const prizeList = [
        {
            id: 1,
            title: "一代宗师",
            work: [
                {
                    title: '千山呈景',
                    publisher: '刀爹的心肝儿',
                    publisher_id: '460375',
                    id: '518960',
                    user_avatar_image:
                        'https://cdn.ggac.com/media/images/user_avatar.jpg',
                    cover_image: 'https://cdn-prd.ggac.com/ggac/submatch/czwj/czwj-pc-workprize-1.jpg',
                }
            ]
        },
        {
            id: 2,
            title: "名满江湖",
            work: [
                {
                    title: '问刀',
                    publisher: '怎么没有奇遇',
                    publisher_id: '109635',
                    id: '485752',
                    user_avatar_image:
                        'https://cdn.ggac.com/media/images/user_avatar.jpg',
                    cover_image: 'https://cdn-prd.ggac.com/ggac/submatch/czwj/czwj-pc-workprize-2.jpg',
                },
                {
                    title: '浮光归云',
                    publisher: '桃沉沉ccc',
                    publisher_id: '458827',
                    id: '527074',
                    user_avatar_image:
                        'https://cdn.ggac.com/media/avatar/2022/06/bc64b7c4-f146-11ec-9222-0242c0a88002.jpg',
                    cover_image: 'https://cdn-prd.ggac.com/ggac/submatch/czwj/czwj-pc-workprize-3.jpg',
                }
            ]
        },
        {
            id: 3,
            title: "锋芒毕露",
            work: [
                {
                    title: '镜中影',
                    publisher: 'Merfil',
                    publisher_id: '3497',
                    id: '529125',
                    user_avatar_image:
                        'https://cdn.ggac.com/media/images/user_avatar.jpg',
                    cover_image: 'https://cdn-prd.ggac.com/ggac/submatch/czwj/czwj-pc-workprize-4.jpg',
                },
                {
                    title: '客归陇西',
                    publisher: '猩红TAIZI',
                    publisher_id: '499156',
                    id: '1574620',
                    user_avatar_image:
                        'https://cdn.ggac.com/media/avatar/2022/11/34e80d56-5a13-11ed-84ec-0242c0a88002.jpg',
                    cover_image: 'https://cdn-prd.ggac.com/ggac/submatch/czwj/czwj-pc-workprize-5.jpg',
                },
                {
                    title: '钟馗嫁妹',
                    publisher: '牧之先生',
                    publisher_id: '513810',
                    id: '525023',
                    user_avatar_image:
                        'https://cdn.ggac.com/media/images/default_180/4.jpg',
                    cover_image: 'https://cdn-prd.ggac.com/ggac/submatch/czwj/czwj-pc-workprize-6.jpg',
                },
                {
                    title: '走阴阳',
                    publisher: '可食用银稚',
                    publisher_id: '640235',
                    id: '487554',
                    user_avatar_image:
                        'https://cdn.ggac.com/media/avatar/2023/04/641e2412-d587-11ed-a555-0242ac120002.jpg',
                    cover_image: 'https://cdn-prd.ggac.com/ggac/submatch/czwj/czwj-pc-workprize-7.jpg',
                },
                {
                    title: '芥子明尘',
                    publisher: '赈川',
                    publisher_id: '451703',
                    id: '514558',
                    user_avatar_image:
                        'https://cdn.ggac.com/media/avatar/2023/05/a36790ce-f7d6-11ed-8830-0242ac120002.jpg',
                    cover_image: 'https://cdn-prd.ggac.com/ggac/submatch/czwj/czwj-pc-workprize-8.jpg',
                }
            ]
        },
        {
            id: 4,
            title: "别具一格",
            work: [
                {
                    title: '别打我鸭',
                    publisher: '薯条条',
                    publisher_id: '460672',
                    id: '494543',
                    user_avatar_image:
                        'https://cdn.ggac.com/media/images/user_avatar.jpg',
                    cover_image: 'https://cdn.ggac.com/media/work/cover/2023/04/8fa021b0-d444-11ed-bf42-0242c0a88002.jpg',
                },
                {
                    title: '聆羽飞音',
                    publisher: '过期意大利面',
                    publisher_id: '550647',
                    id: '486040',
                    user_avatar_image:
                        'https://cdn.ggac.com/media/avatar/2023/03/447b33be-c8c8-11ed-a367-0242ac120002.jpg',
                    cover_image: 'https://cdn.ggac.com/media/work/cover/2023/06/52c091e2-0a83-11ee-9681-0242ac120002.jpg',
                },
                {
                    title: '寒楼犴影',
                    publisher: '江无雪',
                    publisher_id: '657619',
                    id: '525067',
                    user_avatar_image:
                        'https://cdn-prd.ggac.com/ggac/user/detail/url/2sQbzBKMZ3pQ8W8KXTi5APkty2kiziin1702093144578-500x.webp',
                    cover_image: 'https://cdn.ggac.com/media/work/cover/2023/06/4a64b09c-0dd9-11ee-a5f0-0242c0a82002.jpg',
                },
                {
                    title: '临鹤入世',
                    publisher: '沈夕言',
                    publisher_id: '509923',
                    id: '515105',
                    user_avatar_image:
                        'https://cdn.ggac.com/media/avatar/2023/05/d5c8954a-ebec-11ed-a8c7-0242ac120002.jpg',
                    cover_image: 'https://cdn.ggac.com/media/work/cover/2023/06/a298994c-02bf-11ee-856b-0242ac120002.jpg',
                },
                {
                    title: '云香粼粼',
                    publisher: 'YFMX一三三三三三',
                    publisher_id: '640688',
                    id: '505433',
                    user_avatar_image:
                        'https://cdn.ggac.com/media/avatar/2023/03/bee64380-c947-11ed-88bc-0242ac120002.jpg',
                    cover_image: 'https://cdn-prd.ggac.com/ggac/work/cover/2023/10/9/36980a79-e954-4a91-bec6-9104101beae8-900x.webp',
                },
                {
                    title: '风湮笑醉',
                    publisher: '天策府纯情猛1',
                    publisher_id: '69741',
                    id: '487348',
                    user_avatar_image:
                        'https://cdn.ggac.com/media/avatar/2023/05/a6396d52-f60c-11ed-97ae-0242ac120002.jpg',
                    cover_image: 'https://cdn-prd.ggac.com/ggac/work/cover/2023/10/15/ee84bfb4-362d-45af-804f-e445dc1b2fed-900x.webp',
                },
                {
                    title: '所见之地',

                    publisher: '逢坂大河',
                    publisher_id: '644923',
                    id: '495776',
                    user_avatar_image:
                        'https://cdn-prd.ggac.com/user/detail/url/2ps4z6ZBFd8YhCGWRwAYQC58QPSaKpaY1695924436625.jpg',
                    cover_image: 'https://cdn-prd.ggac.com/ggac/work/cover/2023/10/12/d5461fc9-43e8-4525-9fb6-cfd3f8a02ae0.jpg',
                },
                {
                    title: '雾入松墨',
                    publisher: '唐木',
                    publisher_id: '454179',
                    id: '502113',
                    user_avatar_image:
                        'https://cdn-prd.ggac.com/user/detail/url/2ps4z6ZBFd8YhCGWRwAYQC58QPSaKpaY1695924436625.jpg',
                    cover_image: 'https://cdn-prd.ggac.com/ggac/work/cover/2023/9/29/bf44a596-7766-492d-89fb-c32fa4e6eeee-900x.webp',
                },
                {
                    title: '天外来客 （偏色已更新）',
                    publisher: '谷瑞恩',
                    publisher_id: '515038',
                    id: '522410',
                    user_avatar_image:
                        'https://cdn.ggac.com/media/avatar/2022/08/dfbca22c-2855-11ed-990f-0242ac120002.jpg',
                    cover_image: 'https://cdn.ggac.com/media/work/cover/2023/06/7eda268a-0ea7-11ee-97d2-0242ac120002.jpg',
                },
                {
                    title: '青鸾',
                    publisher: '230523886',
                    publisher_id: '647138',
                    id: '528392',
                    user_avatar_image:
                        'https://cdn.ggac.com/media/avatar/2019/10/2a746f3e-fb9f-11e9-9467-0242c0a84002.jpg',
                    cover_image: 'https://cdn-prd.ggac.com/ggac/work/cover/2023/10/15/58bf3266-3deb-4df8-83a0-29a9d1ae5529.jpg',
                },
                {
                    title: '藤华梦',
                    publisher: '一碗软饭',
                    publisher_id: '641891',
                    id: '488861',
                    user_avatar_image:
                        'https://cdn.ggac.com/media/images/default_180/134.jpg',
                    cover_image: 'https://cdn-prd.ggac.com/ggac/work/cover/2023/10/14/ff41ee8b-d7fd-47d3-baa0-29c7e972c36a-900x.webp',
                },
                {
                    title: '长安故梦',
                    publisher: '芸生',
                    publisher_id: '656437',
                    id: '520434',
                    user_avatar_image:
                        'https://cdn.ggac.com/media/images/default_180/62.jpg',
                    cover_image: 'https://cdn-prd.ggac.com/ggac/work/cover/2023/10/15/8219abca-b368-4d8a-9580-2cc8feb649e9-900x.webp',
                },
                {
                    title: '相见欢',
                    publisher: '烛卿',
                    publisher_id: '644117',
                    id: '493680',
                    user_avatar_image:
                        'https://cdn.ggac.com/media/avatar/2023/05/37e6d866-f405-11ed-bb35-0242ac120002.jpg',
                    cover_image: 'https://cdn-prd.ggac.com/ggac/work/cover/2023/10/13/3871d7ad-0962-4182-b7cf-7cedb26c9927-900x.webp',
                },
                {
                    title: '兰逢烟雨',
                    publisher: '鳞玖09',
                    publisher_id: '452014',
                    id: '489546',
                    user_avatar_image:
                        'https://cdn.ggac.com/media/avatar/2022/06/97e0d89e-e118-11ec-a603-0242c0a88002.jpg',
                    cover_image: 'https://cdn.ggac.com/media/work/cover/2023/04/7b962a5c-e15a-11ed-b37c-0242c0a88002.jpg',
                },
                {
                    title: '寒山倚梦',
                    publisher: '吟阿梦',
                    publisher_id: '457013',
                    id: '517305',
                    user_avatar_image:
                        'https://cdn.ggac.com/media/images/user_avatar.jpg',
                    cover_image: 'https://cdn-prd.ggac.com/ggac/work/cover/2023/10/11/fa10d8f2-f653-4ee6-82a7-f76f2b420b22.jpg',
                },
                {
                    title: '堕仙',
                    publisher: '好想喝COCO',
                    publisher_id: '114076',
                    id: '1578523',
                    user_avatar_image:
                        'https://cdn.ggac.com/media/avatar/2022/09/4f4e403c-3300-11ed-b7ed-0242ac120002.jpg',
                    cover_image: 'https://cdn-prd.ggac.com/ggac/work/cover/2023/10/15/c497fbe8-59b3-4a3d-8e1d-b9598346aea7.jpg',
                },
                {
                    title: '林间语',
                    publisher: '浮某',
                    publisher_id: '649700',
                    id: '505700',
                    user_avatar_image:
                        'https://cdn.ggac.com/media/avatar/2023/05/2d3b9b40-e906-11ed-99e9-0242c0a88002.jpg',
                    cover_image: 'https://cdn-prd.ggac.com/ggac/work/cover/2023/10/11/87f35ec0-5235-4ceb-bf05-1df921d7d01e.png',
                },
                {
                    title: '此间春',
                    publisher: '大大大米',
                    publisher_id: '525509',
                    id: '505920',
                    user_avatar_image:
                        'https://cdn.ggac.com/media/avatar/2023/04/8e417926-e723-11ed-9d0b-0242c0a88002.jpg',
                    cover_image: 'https://cdn-prd.ggac.com/ggac/work/cover/2023/10/13/1f3a2b01-a65a-4700-b252-11c0c788a2c4.jpg',
                },
                {
                    title: '蝶意莺情',
                    publisher: 'N尼二',
                    publisher_id: '275536',
                    id: '506933',
                    user_avatar_image:
                        'https://cdn.ggac.com/media/avatar/2023/05/9095c308-e9a8-11ed-827e-0242c0a88002.jpg',
                    cover_image: 'https://cdn-prd.ggac.com/ggac/work/cover/2023/10/6/a6cb484b-87c1-4338-a71c-7751c286dcb9.jpg',
                },
                {
                    title: '风月不羁',
                    publisher: '江寒青霜',
                    publisher_id: '315287',
                    id: '486634',
                    user_avatar_image:
                        'https://cdn.ggac.com/media/avatar/2021/10/91baaad4-28d7-11ec-b0b0-0242c0a82002.jpg',
                    cover_image: 'https://cdn-prd.ggac.com/ggac/work/cover/2023/10/13/b8242719-e6b2-4a61-ba12-930bcc3db408.jpg',
                },
                {
                    title: '江海孤云',
                    publisher: '东皋一鹤',
                    publisher_id: '505324',
                    id: '486234',
                    user_avatar_image:
                        'https://cdn.ggac.com/media/images/default_180/8.jpg',
                    cover_image: 'https://cdn-prd.ggac.com/ggac/work/cover/2023/10/14/c94002c6-ce79-410e-b515-df09cdd59ce2.jpg',
                },
                {
                    title: '探蝶花上',
                    publisher: '鸭头没有你我可怎么活啊',
                    publisher_id: '451527',
                    id: '517740',
                    user_avatar_image:
                        'https://cdn.ggac.com/media/avatar/2023/03/36fb46c8-c91a-11ed-ab40-0242ac120002.jpg',
                    cover_image: 'https://cdn.ggac.com/media/work/cover/2023/05/653c5b4c-fec8-11ed-986b-0242c0a88002.jpg',
                }
            ]
        },
        {
            id: 5,
            title: "奇思妙想",
            work: [
                {
                    title: '年年雪',
                    publisher: '好想学会画画啊',
                    publisher_id: '441402',
                    id: '485916',
                    user_avatar_image:
                        'https://cdn.ggac.com/media/avatar/2022/05/ba2eb4ae-d004-11ec-b919-0242c0a82002.jpg',
                    cover_image: 'https://cdn-prd.ggac.com/ggac/submatch/czwj/czwj-pc-workprize-9.jpg',
                },
                {
                    title: '衔云锦书',
                    publisher: '灰灰鹊',
                    publisher_id: '640375',
                    id: '485952',
                    user_avatar_image:
                        'https://cdn.ggac.com/media/avatar/2023/03/1afbe28a-cf09-11ed-9410-0242ac120002.jpg',
                    cover_image: 'https://cdn-prd.ggac.com/ggac/submatch/czwj/czwj-pc-workprize-10.jpg',
                },
                {
                    title: '青都神采',
                    publisher: '遥控薄荷盏',
                    publisher_id: '644110',
                    id: '493682',
                    user_avatar_image:
                        'https://cdn.ggac.com/media/avatar/2019/10/2a746f3e-fb9f-11e9-9467-0242c0a84002.jpg',
                    cover_image: 'https://cdn-prd.ggac.com/ggac/submatch/czwj/czwj-pc-workprize-11.jpg',
                }
            ]
        },
    ]


    const toUserDetail = (id: number) => {
        window.open(`/user/${id}`)
    }

    const toWorkDetail = (id: number) => {
        window.open(`/work/detail/${id}`)
    }

    return <CzwjWorkprizeStyle>
        <div className="prize">
            {
                prizeList.map(item => {
                    return <div key={item.id}>
                        <div className="title">
                            <div className="title-top">{item.title}</div>
                        </div>
                        {
                            item.id != 4 && <div className={`work work-${item.id}`}>
                                {
                                    item.work.map(work => {
                                        return <div className="work-item" key={work.id}>
                                            <div className="cover" onClick={() => toWorkDetail(Number(work.id))} style={{ backgroundImage: `url(${work.cover_image})` }}></div>
                                            <div className="info">
                                                <div className="user_avatar_image" onClick={() => toUserDetail(Number(work.publisher_id))} style={{ backgroundImage: `url(${work.user_avatar_image})` }}></div>
                                                <div className="user-name" onClick={() => toUserDetail(Number(work.publisher_id))}>{work.publisher} - 「{work.title}」</div>
                                            </div>
                                        </div>
                                    })
                                }
                            </div>
                        }
                        {
                            item.id === 4 && <div className={`work work-${item.id}`}>
                                {
                                    item.work.map(work => {
                                        return <div className="work-item" key={work.id}>
                                            <div className="cover" onClick={() => toWorkDetail(Number(work.id))} style={{ backgroundImage: `url(${work.cover_image})` }}></div>
                                            <div className="work-title" onClick={() => toWorkDetail(Number(work.id))}>
                                                「{work.title}」
                                            </div>
                                            <div className="info">
                                                <div className="user_avatar_image" onClick={() => toUserDetail(Number(work.publisher_id))} style={{ backgroundImage: `url(${work.user_avatar_image})` }}></div>
                                                <div className="user-name" onClick={() => toUserDetail(Number(work.publisher_id))}>{work.publisher}</div>
                                            </div>
                                        </div>
                                    })
                                }
                            </div>
                        }
                    </div>
                })
            }

        </div>
    </CzwjWorkprizeStyle>
}

export default CzwjWorkprize