import styled from "styled-components";

export const Ycwl2WorkprizeStyle = styled.div`
    .workprize {
    background: url('https://cdn.ggac.com/img/ycwl-pc-workprize-bg.png') center center no-repeat;
    background-size: 100% 100%;
    padding: 100px;
    background-attachment: fixed;
    position: relative;
    margin-top: -30px;

  .tab {
    position: relative;
    /* height: 100vh; */
    right: 50px;
    top: 50%;
    transform: translateY(-50%);
    position: fixed;

    &-item {
      font-size: 14px;
      color: #ffffff;
      opacity: 0.8;
      margin-bottom: 30px;
      text-align: right;
      position: relative;
      cursor: pointer;
      transition: all;

      &:hover,
      &.active {
        color: #48c9ff;

        &::after {
          width: 10px;
          height: 10px;
          background-color: #48c9ff;
        }
      }

      &::after {
        content: "";
        position: absolute;
        width: 5px;
        height: 5px;
        background-color: #fff;
        opacity: .8;
        border-radius: 50%;
        top: 50%;
        transform: translateY(-50%);
        right: -20px;
      }

      &-item:last-child {
        margin-bottom: 0;
      }
    }
  }

  .title {
    width: 987px;
    height: 90px;
    position: relative;
    margin: 0 auto 60px;

    .word1 {
      font-family: 'ShuHeiTi';
      font-size: 40px;
      letter-spacing: 5px;
      padding-left: 5px;
      color: #ffffff;
      margin-bottom: 30px;
      text-align: center;
    }

    .word2 {
      font-size: 16px;
      letter-spacing: 8px;
      padding-left: 8px;
      text-align: center;
      color: #48c9ff;
    }

    &::after {
      content: '';
      width: 987px;
      height: 27px;
      background: url('https://cdn.ggac.com/img/ycwl-pc-title.png') center center no-repeat;
      background-size: 100% 100%;
      position: absolute;
      bottom: 25px;
      left: 0;
    }
  }

  .work {
    margin: 0 auto 150px;
    width: 1200px;

    .sub-title {
      display: flex;
      justify-content: center;
      align-items: center;
      font-size: 30px;
      color: #ffffff;
      padding: 0 10px;
      margin-bottom: 50px;
      font-family: 'ShuHeiTi';

      img {
        height: 50px;
      }
    }

    //   多个作品
    &.more-work {
      display: flex;
      align-items: stretch;
      //   justify-content: center;
      flex-wrap: wrap;
      width: calc(1200px + 50px);
    }

    .work-item {
      border: solid 1px rgba(58, 199, 249, 0.28);
      border-radius: 4px;
      padding: 1px;

      &.single {
        width: 1200px;
        margin: 0 auto 30px;

        &:last-child {
          margin-bottom: 0;
        }
      }

      &.more {
        width: 577px;
        margin-bottom: 40px;
        margin-right: 46px;

        &:nth-child(2n) {
          margin-right: 0;
        }

        .cover {
          height: 340px;
        }
      }

      .work-item-top {
        // background: #62afb1;
        /* border-bottom: solid 1px rgba(58, 199, 249, 0.28); */

        .cover {
          display: block;
          width: 100%;
          height: 400px;
          background-size: cover;
          background-position: center center;
          cursor: pointer;
        }

        .name {
          padding: 12px 30px;
          font-size: 18px;
          a {
            color: #fff !important;
          }
          white-space: nowrap;
          text-overflow: ellipsis;
          overflow: hidden;
          display: flex;
          align-items: center;

          img {
            cursor: pointer;
          }

          .user-name {
              color: #fff;
          }

          .head {
            width: 45px;
            height: 45px;
            margin-right: 25px;
            border-radius: 50%;
            background-size: cover;

            img {
              width: 100%;
              height: 100%;
              border-radius: 50%;
              cursor: pointer;
            }
          }
        }
      }
    }
  }

  .border {
    display: flex;
    background: url('https://cdn.ggac.com/img/ycwl-pc-workprize-border.png') center center no-repeat;
    background-size: 100% 100%;
    width: 1200px;
    height: 287px;
    margin: 0 auto 60px;
    align-items: center;
    justify-content: center;
    padding: 50px 80px;
    flex-wrap: wrap;

    &-item {
      display: flex;
      align-items: center;
      padding: 0 5px;
      font-size: 14px;
      color: #ffffff;
      margin: 0 15px;

      img {
        height: 27px;
      }
    }
  }
}
`