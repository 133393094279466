import { Carousel } from "antd"
import { YlzyDetailStyle } from "./style"
import { useRef, useState } from "react"
import Invitecode from "../invitecode"

const YlzyDetail = () => {

    const swiperRef = useRef() as React.MutableRefObject<any>

    const [isShow, setIsShow] = useState(false)

    const openInvite = () => {
        setIsShow(true)
    }
    const toDetail = () => {
        window.open("https://www.ggac.com/official/371")
    }

    const prev = () => {
        swiperRef.current?.prev()
    }

    const close = ()=>{
        setIsShow(false)
    }

    const next = () => {
        swiperRef.current?.next()
    }

    const toRule = () => {
        window.open(`/contest/ylzy/rule`)
    }

    const toJudgeDetail = (id: string) => {
        if(!id){
            return
        }
        window.open(`/user/${id}/works`)
    }

    const ylzyJudgeList = [
        {
            name: '666K信譞',
            name_m: '666K信譞',
            avatar: 'https://cdn.ggac.com/img/ylzy-pc-judge2.png',
            desc: ['知名插画师', '拳头游戏首席插画师'],
            id: '114943',
        },
        {
            name: '歌倚酒',
            name_m: '歌倚酒',
            avatar: 'https://cdn.ggac.com/img/ylzy-pc-judge1.png',
            desc: ['知名概念设计师'],
            id: '192836',
        },
        {
            name: '若干爪',
            name_m: '若干爪',
            avatar: 'https://cdn.ggac.com/img/ylzy-pc-judge3.png',
            desc: ['资深角色原画师'],
            id: '110689',
        },
        {
            name: '《代号：捉妖》项目评审团',
            name_m: "《代号：捉妖》<br/>项目评审团",
            avatar: 'https://cdn.ggac.com/img/ylzy-pc-judge4.png',
            desc: [''],
            id: '',
        },
    ]

    const imageList = [
        { url: "https://cdn.ggac.com/img/ylzy-pc-swiper-image1.png", id: 1 },
        { url: "https://cdn.ggac.com/img/ylzy-pc-swiper-image2.png", id: 2 },
        { url: "https://cdn.ggac.com/img/ylzy-pc-swiper-image3.png", id: 3 },
        { url: "https://cdn.ggac.com/img/ylzy-pc-swiper-image4.png", id: 4 }
    ]
    return <YlzyDetailStyle>
        <div className="challenge">
            <div className="title title-one">
                <div className="number">壹</div>
                <div className="content">
                    <img className="title-left" src="https://cdn.ggac.com/img/ylzy-pc-title-left.png" alt=""/>
                    <div className="word">
                        大赛主题
                    </div>
                    <img className="title-right" src="https://cdn.ggac.com/img/ylzy-pc-title-left.png" alt=""/>
                </div>
            </div>
            <div className="challenge-one">
                <div className="italic">高接上清真境，雄吞万象森罗。</div>
                <div className="italic">道人星夜起玄科，飞佩振鸣珂。剑光灼烁冲牛斗，降伏邪魔。</div>
                <div className="italic">— —沈禧（元）</div>
                <div>自上古始，幽州与中州为玄海所隔，幽州灵气充沛，四野皆为妖灵所踞，中州海清河晏，境内人类繁衍生息；</div>
                <div>然玄海于300年间无故而竭，使得两界平衡出现动摇，灵妖慢慢开始渗入中州王土……</div>
                <div>为应对此境地，中州一神秘领袖，号集御灵遣将之能士，创“妖灵会馆”以辖入境妖灵，辨其善恶，引其驱策。</div>
                <div>职“妖灵会馆”者，谓“执灵师”，穿梭于两界七境，索灵缉妖，斩秽疏邪。</div>
                <div>你将作为一名“执灵师”，御座下众妖灵，逐一破解谜团，最终战胜邪恶之力。</div>
            </div>
            <div className="title">
                <div className="number">贰</div>
                <div className="content">
                    <img className="title-left" src="https://cdn.ggac.com/img/ylzy-pc-title-left.png" alt="" />
                    <div className="word">
                        赛事时间
                    </div>
                    <img className="title-right" src="https://cdn.ggac.com/img/ylzy-pc-title-left.png" alt=""/>
                </div>
            </div>
            <div className="challenge-two">
                <div className="date">
                    <div className="lines">
                        <img src="https://cdn.ggac.com/img/ylzy-pc-date-line1.png" alt=""/>
                        <div className="number">壹</div>
                        <img src="https://cdn.ggac.com/img/ylzy-pc-date-line2.png" alt=""/>
                        <div className="number">贰</div>
                        <img src="https://cdn.ggac.com/img/ylzy-pc-date-line2.png" alt=""/>
                        <div className="number">叁</div>
                        <img src="https://cdn.ggac.com/img/ylzy-pc-date-line3.png" alt=""/>
                    </div>
                    <div className="date-word">
                        <div className="date-word-item">
                            <div>投稿期</div>
                            <div>2023.08.30 - 2023.11.30</div>
                        </div>
                        <div className="date-word-item">
                            <div>评审期</div>
                            <div>2023.12 - 2024.01</div>
                        </div>
                        <div className="date-word-item">
                            <div>公示期</div>
                            <div>2024.01</div>
                        </div>
                    </div>
                </div>
            </div>
            <div className="title">
                <div className="number">叁</div>
                <div className="content">
                    <img className="title-left" src="https://cdn.ggac.com/img/ylzy-pc-title-left.png" alt=""/>
                    <div className="word">
                        赛事奖项
                    </div>
                    <img className="title-right" src="https://cdn.ggac.com/img/ylzy-pc-title-left.png" alt=""/>
                </div>
            </div>
            <div className="challenge-three">
                <div className="challenge-three-item">
                    <div className="prize-title">一等奖</div>
                    <div className="prize-number">1名</div>
                    <div className="count">
                        <div>¥</div>
                        100
                        <div>,</div>
                        000
                    </div>
                </div>
                <div className="challenge-three-item">
                    <div className="prize-title">二等奖</div>
                    <div className="prize-number">2名</div>
                    <div className="count">
                        <div>¥</div>
                        50
                        <div>,</div>
                        000
                    </div>
                </div>
                <div className="challenge-three-item">
                    <div className="prize-title">三等奖</div>
                    <div className="prize-number">3名</div>
                    <div className="count">
                        <div>¥</div>
                        15
                        <div>,</div>
                        000
                    </div>
                </div>
                <div className="challenge-three-item">
                    <div className="prize-title">人气奖</div>
                    <div className="prize-number">1名</div>
                    <div className="count">
                        <div>¥</div>
                        5
                        <div>,</div>
                        000
                    </div>
                </div>
                <div className="challenge-three-item">
                    <div className="prize-title">优秀奖</div>
                    <div className="prize-number">10名</div>
                    <div className="count">
                        <div>¥</div>
                        5
                        <div>,</div>
                        000
                    </div>
                </div>
            </div>
            <div className="title">
                <div className="number">肆</div>
                <div className="content">
                    <img className="title-left" src="https://cdn.ggac.com/img/ylzy-pc-title-left.png" alt=""/>
                    <div className="word">
                        携友共御妖
                    </div>
                    <img className="title-right" src="https://cdn.ggac.com/img/ylzy-pc-title-left.png" alt=""/>
                </div>
            </div>
            <div className="challenge-four">
                <div className="top">
                    <div>穿梭于两界七境，斩秽疏邪……这么重要的事情怎么可以只有你一人呢！</div>
                    <div>带上这个吧——你的好伙伴们！每位“执灵师”报名成功后均可邀请好友共同参赛，</div>
                    <div className="red">如好友成功报名赛事，你即可瓜分 <span>¥1000</span> 御妖能量池；</div>
                    <div className="red">如好友成功提交完成作品并通过审核，你和好友均可参与瓜分 <span>¥2000</span> 御妖赏金池。</div>
                    <div>快携手同伴，一同降伏邪魔吧~</div>
                </div>
                <div className="process">
                    <div className="process-item">报名赛事</div>
                    <div className="process-item">点击获取“邀请码”</div>
                    <div className="process-item">分享“邀请码”</div>
                    <div className="process-item">
                        <div>好友报名</div>
                        <div>并填写“邀请码”</div>
                    </div>
                    <img src="https://cdn.ggac.com/img/ylzy-pc-four-line.png" className="process-img" alt=""/>
                </div>
                <div className="tip">
                    <img src="https://cdn.ggac.com/img/ylzy-pc-word-icon.png" alt="" />
                    开赛福利：现金红包、精美礼品、贴心物资包……超多福利物资，快来参赛瓜分吧~
                </div>
                <div className="btn-wrap">
                    <div className="btn" onClick={openInvite}>邀请码</div>
                    <div className="btn" onClick={toDetail}>查看详情</div>
                </div>
            </div>
            <div className="title">
                <div className="number">伍</div>
                <div className="content">
                    <img className="title-left" src="https://cdn.ggac.com/img/ylzy-pc-title-left.png" alt=""/>
                    <div className="word">
                        创作说明
                    </div>
                    <img className="title-right" src="https://cdn.ggac.com/img/ylzy-pc-title-left.png" alt=""/>
                </div>
            </div>
            <div className="challenge-five">
                <div className="word">
                    <div>参赛者必须根据大赛主题，自主构想国风妖灵的生存环境（沙漠、海域、森林等），外貌锚点，基础性格，</div>
                    <div>元素属性（风、水、火等），战斗元素（可以是身体部位、器具等，具体不限），并创作与之相匹配的国风妖灵角色设计图。</div>
                </div>
                <div className="word">
                    <div>角色设计应该特点鲜明、风格统一，至少包含一处明显妖化特征，契合赛事主题，并能够充分展现创作者所设想的美术形态</div>
                    <div>及品质。</div>
                </div>
                <div className="word">
                    <div>需设计一组3-5个角色，性别不限。</div>
                </div>
                <div className="btn" onClick={toRule}>查看详细规则</div>
            </div >
            <div className="title" id="lookUp">
                <div className="number">陆</div>
                <div className="content">
                    <img className="title-left" src="https://cdn.ggac.com/img/ylzy-pc-title-left.png" alt=""/>
                    <div className="word">
                        作品参考
                    </div>
                    <img className="title-right" src="https://cdn.ggac.com/img/ylzy-pc-title-left.png" alt=""/>
                </div>
            </div>
            <div className="challenge-six">
                <Carousel ref={swiperRef} className="carousel" dots={false} autoplay={true}>
                    {
                        imageList.map(item => {
                            return <div key={item.id}>
                                <img className="images" src={item.url} alt="" />
                            </div>
                        })
                    }
                </Carousel>
                <div className="arrow">
                    <img className="arrow-left" onClick={prev} src="https://cdn.ggac.com/img/ylzy-pc-swiper-left.png" alt=""/>
                    <img className="arrow-right" onClick={next} src="https://cdn.ggac.com/img/ylzy-pc-swiper-right.png" alt=""/>
                </div>
            </div >
            <div className="title">
                <div className="number">柒</div>
                <div className="content">
                    <img className="title-left" src="https://cdn.ggac.com/img/ylzy-pc-title-left.png" alt=""/>
                    <div className="word">
                        大赛评委
                    </div>
                    <img className="title-right" src="https://cdn.ggac.com/img/ylzy-pc-title-left.png" alt=""/>
                </div>
            </div>
            <div className="challenge-seven">
                <div className="judge">
                    {
                        ylzyJudgeList.map((item, index) => {
                            return <div className="judge-item" key={item.id}>
                                <img className="avatar" onClick={() => toJudgeDetail(item.id)} src={item.avatar} alt="" />
                                <div className="name">{item.name}</div>
                                {
                                    item.desc.map(desc => {
                                        return <div className="desc" key={desc}>{desc}</div>
                                    })
                                }
                            </div>
                        })
                    }
                </div >
            </div >
            <div className="title">
                <div className="number">捌</div>
                <div className="content">
                    <img className="title-left" src="https://cdn.ggac.com/img/ylzy-pc-title-left.png" alt=""/>
                    <div className="word">
                        评审环节
                    </div>
                    <img className="title-right" src="https://cdn.ggac.com/img/ylzy-pc-title-left.png" alt=""/>
                </div>
            </div>
            <div className="list">
                <div className="list-item">
                    <div className="list-item-title">
                        晋级投票
                    </div>
                    <div className="content">
                        <div>截稿后开启，</div>
                        <div>进行为期1周，</div>
                        <div>由用户进行投票的</div>
                        <div>评选活动。</div>
                        <div>排名前40%的作品晋级。</div>
                    </div>
                </div>
                <div className="list-item to-top">
                    <div className="list-item-title">
                        一票晋级
                    </div>
                    <div className="content">
                        <div>晋级海选期及晋级</div>
                        <div>结束后，评委老师都</div>
                        <div>拥有对海选作品</div>
                        <div>一票晋级的权利。</div>
                    </div>
                </div>
                <div className="list-item to-top">
                    <div className="list-item-title">
                        人气评选
                    </div>
                    <div className="content">
                        <div>晋级作品全部出炉后，</div>
                        <div>票数排名前5的作品</div>
                        <div>进入人气奖候选评选，</div>
                        <div>由评委老师从中</div>
                        <div>评选出人气奖。</div>
                    </div>
                </div>
                <div className="list-item">
                    <div className="list-item-title">
                        奖项评选
                    </div>
                    <div className="content">
                        <div>晋级作品全部出炉后，</div>
                        <div>由评委老师从晋级</div>
                        <div>作品中评审出赛事</div>
                        <div>各奖项的归属。</div>
                    </div>
                </div>
            </div>
            <div className="title">
                <div className="number">玖</div>
                <div className="content">
                    <img className="title-left" src="https://cdn.ggac.com/img/ylzy-pc-title-left.png" alt=""/>
                    <div className="word">
                        评审标准
                    </div>
                    <img className="title-right" src="https://cdn.ggac.com/img/ylzy-pc-title-left.png" alt=""/>
                </div>
            </div>
            <div className="list nine">
                <div className="list-item">
                    <div className="list-item-title">
                        概念想法
                    </div>
                    <div className="content">
                        <div>对赛事主题的新颖</div>
                        <div>呈现作品设计构思</div>
                        <div>具有创造性</div>
                    </div>
                </div>
                <div className="list-item to-top">
                    <div className="list-item-title">
                        技法表现
                    </div>
                    <div className="content">
                        <div>作品具有较强的</div>
                        <div>设计基本功及较好的</div>
                        <div>美术表现效果</div>
                    </div>
                </div>
                <div className="list-item to-top">
                    <div className="list-item-title">
                        风格亮点
                    </div>
                    <div className="content">
                        <div>作品具有较独特的</div>
                        <div>创作风格及亮点</div>
                        <div>且整体创作风格统一</div>
                    </div>
                </div>
                <div className="list-item">
                    <div className="list-item-title">
                        实用价值
                    </div>
                    <div className="content">
                        <div>作品应用性较强</div>
                        <div>设计方案具有投入</div>
                        <div>实际使用可能性</div>
                    </div>
                </div>
            </div>
            <div className="title">
                <div className="number">拾</div>
                <div className="content">
                    <img className="title-left" src="https://cdn.ggac.com/img/ylzy-pc-title-left.png" alt=""/>
                    <div className="word">
                        参赛须知
                    </div>
                    <img className="title-right" src="https://cdn.ggac.com/img/ylzy-pc-title-left.png" alt=""/>
                </div>
            </div>
            <div className="challenge-ten">
                <div>
                    本赛事面向全球创作者公开接受报名，所有创作者均可免费参加本赛事。
                </div>
                <div>参赛者的所有作品严禁包含政治、低俗、色情等内容，不得违反相关法律法规。</div>
                <div>参赛者的所有作品需要保证原创性和完整性，不得侵犯他人的著作权、专利权、商标权、肖像权、隐私权等任何法定权利。</div>
                <div>参赛者的所有作品严禁抄袭、盗用他人作品，且参赛期间版权未移交他人，未被商用或授权他人使用。</div>
                <div>为保证比赛的公平公正，获奖作品公布后，官方将对参赛者个人信息及作品源文件进行审核。</div>
                <div>本赛事不接受任何形式的AI作品投递。</div>
                <div>本赛事的投稿最终提交时间为【2023年11月30日23：59】。</div>
                <div>本赛事的全部奖金金额均为税前金额，作品获奖后，将由赛事组委会（及关联公司）将为获奖作者代扣代缴个人所得税。</div>
                <div>参赛者需同意并遵守赛事的各项规则，赛事组委会有权对违反赛事规则的作品或作者采取作品下架、取消参赛资格等处理方式。</div>
                <div>赛事组委会对本赛事的各项规则拥有最终解释权。</div>
            </div>
            <img src="https://cdn.ggac.com/img/ylzy-pc-bottom.png" alt="" className="bottom" />
            <Invitecode close={close} isShow={isShow} />
        </div >
    </YlzyDetailStyle >
}

export default YlzyDetail