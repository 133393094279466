import { useNavigate, useOutletContext } from "react-router"
import { YdbzDetailStyle } from "./style"
import { ContestDetailResponse } from "types/contest"
import YdbzApplicant from "../applicant"
import { localUser } from "views/forum/fn"
import { useEffect, useState } from "react"
import { loginPathWithRedirect } from "utils/functions/user"
import { apiGetSubDetail, apiSchoolIsApply } from "api/sub"
import { Modal } from "antd"
import SchoolApplicant from "../school-applicant"
import { SchoolIsApplyResponse } from "types/sub/application"

const YdbzDetail = () => {
    const { detail } = useOutletContext<{ detail: ContestDetailResponse }>()
    const [isModalOpen, setIsModalOpen] = useState(false);
    const [isModalSchool, setIsModalSchool] = useState(false);
    const [schoolApplyDetail, setSchoolApplyDetail] = useState<SchoolIsApplyResponse>()
    const [isSchoolApply, setIsSchoolApply] = useState(false)
    const navigate = useNavigate()
    const handleCancel = () => {
        setIsModalOpen(false)
    }
    const showModal = () => {
        if (!localUser) {
            navigate(loginPathWithRedirect());
        }

        if (!detail?.signupId) {
            setIsModalOpen(true);
        } else {
            apiGetSubDetail(detail?.signupId).then((res) => {
                window.open(`/g-contest/upload/${detail?.id}?org=${res.organization}`)
            })

        }
    };

    useEffect(() => {
        if (!detail) {
            return
        }
        if (!localUser) {
            return
        }
        apiSchoolIsApply(Number(detail.id)).then(res => {
            setSchoolApplyDetail(res)
            if(res.apply === 1) {
                setIsSchoolApply(true)
            }
        })
    }, [detail])

    useEffect(() => {
        window.scrollTo({
            top: 0,
        })
    }, [])

    const toApply = () => {
        const element = document.getElementById('banner');
        const height = element?.clientHeight;
        window.scrollTo({
            top: Number(height) - 200,
        })
        navigate(`/contest/ydbz/shop`)
    }

    const toStoreList = () => {
        window.open("/user-center/shop/product-list?code=ydbz")
    }

    const SchoolApply = () => {
        if (!detail) {
            return
        }
        if (!localUser) {
            navigate(loginPathWithRedirect());
        }
        apiSchoolIsApply(Number(detail.id)).then(res => {
            setSchoolApplyDetail(res)
            setIsModalSchool(true)
        })
    }

    return <YdbzDetailStyle>
        {/* <img className="bg" src="https://cdn-prd.ggac.com/ggac/submatch/ydbz/ydbz-bg.jpg" alt="" /> */}
        <div className="content">
            <div className="part1">
                <div className="part1-content">
                    <div>艺术与设计不仅是审美的表达，更是创新与实践的融合</div>
                    <div>我们诚邀全国高校的学子们，展现你们的创意才华</div>
                    <div>用设计诠释未来，<span>让艺术的脉动不停息</span></div>
                </div>
                <div className="title">
                    <div className="top">
                        <img src="https://cdn-prd.ggac.com/ggac/submatch/ydbz/ydbz-title-icon1.png" />
                        <span>01</span>
                    </div>
                    <img className="arrow" src="https://cdn-prd.ggac.com/ggac/submatch/ydbz/ydbz-title-icon2.png" alt="" />
                    <div className="title-word">
                        活动<img src="https://cdn-prd.ggac.com/ggac/submatch/ydbz/ydbz-title-icon4.png" alt="" />安排
                    </div>
                    <img className="arrow" src="https://cdn-prd.ggac.com/ggac/submatch/ydbz/ydbz-title-icon3.png" alt="" />
                </div>
                <div className="time">
                    <div className="time-item">
                        <div className="text-wrap">
                            <div className="text">报名投稿</div>
                        </div>
                        <div className="date">2024.04.25 ~ 2024.08.31</div>
                    </div>
                    <div className="time-item">
                        <div className="text-wrap">
                            <div className="text">人气投票</div>
                        </div>
                        <div className="date">2024.09.01 ~ 09.08</div>
                    </div>
                    <div className="time-item">
                        <div className="text-wrap">
                            <div className="text">颁奖典礼</div>
                        </div>
                        <div className="date">与第六届GGAC大赛颁奖典礼<br />同步开启，尽请期待！</div>
                    </div>
                    <img src="https://cdn-prd.ggac.com/ggac/submatch/ydbz/ydbz-date-line.png" className="line"></img>
                </div>
            </div>
            <div className="part2">
                <div className="title">
                    <div className="top">
                        <img src="https://cdn-prd.ggac.com/ggac/submatch/ydbz/ydbz-title-icon1.png" />
                        <span>02</span>
                    </div>
                    <img className="arrow" src="https://cdn-prd.ggac.com/ggac/submatch/ydbz/ydbz-title-icon2.png" alt="" />
                    <div className="title-word">
                        活动<img src="https://cdn-prd.ggac.com/ggac/submatch/ydbz/ydbz-title-icon4.png" alt="" />奖励
                    </div>
                    <img className="arrow" src="https://cdn-prd.ggac.com/ggac/submatch/ydbz/ydbz-title-icon3.png" alt="" />
                </div>
                <div className="list">
                    <div className="list-item">
                        <div className="sub-title">最佳毕设奖</div>
                        <img src="https://cdn-prd.ggac.com/ggac/submatch/ydbz/ydbz-part2-icon1.png" alt="" className="img1" />
                        <div className="num">1名</div>
                        <div className="wrap">
                            <img src="https://cdn-prd.ggac.com/ggac/submatch/ydbz/ydbz-part2-img1.png" className="img2" />
                            <div className="text2">NVIDIA GeForce RTX 4080</div>
                            <div className="text3">
                                <div>实体荣誉证书</div>
                                <div>GGAC全流量曝光</div>
                                <div>受邀参与毕业生系列专访</div>
                                <div>免费版权登记次数*1</div>
                                <div>受邀参加颁奖典礼</div>
                            </div>
                        </div>
                    </div>
                    <div className="list-item">
                        <div className="sub-title">视觉盛宴奖</div>
                        <img src="https://cdn-prd.ggac.com/ggac/submatch/ydbz/ydbz-part2-icon1.png" alt="" className="img1" />
                        <div className="num">5名</div>
                        <div className="wrap">
                            <img src="https://cdn-prd.ggac.com/ggac/submatch/ydbz/ydbz-part2-img2.png" className="img2" />
                            <div className="text2">《黑神话：悟空》游戏</div>
                            <div className="text3">
                                <div>实体荣誉证书</div>
                                <div>GGAC全流量曝光</div>
                                <div>免费版权登记次数*1</div>
                                <div>受邀参加颁奖典礼</div>
                            </div>
                        </div>
                    </div>
                </div>
                <div className="list list2">
                    <div className="list-item">
                        <div className="sub-title">艺术启航奖</div>
                        <img src="https://cdn-prd.ggac.com/ggac/submatch/ydbz/ydbz-part2-icon1.png" alt="" className="img1" />
                        <div className="num">10名</div>
                        <div className="wrap">
                            <div className="text3">
                                <div>电子荣誉证书</div>
                                <div>GGAC全流量曝光</div>
                                <div>免费版权登记次数*1</div>
                            </div>
                        </div>
                    </div>
                    <div className="list-item">
                        <div className="sub-title">人气奖</div>
                        <img src="https://cdn-prd.ggac.com/ggac/submatch/ydbz/ydbz-part2-icon1.png" alt="" className="img1" />
                        <div className="num">3名</div>
                        <div className="wrap">
                            <div className="text3">
                                <div>电子荣誉证书</div>
                                <div>GGAC全流量曝光</div>
                                <div>免费版权登记次数*1</div>
                            </div>
                        </div>
                    </div>
                    <div className="list-item">
                        <div className="sub-title">优秀指导老师</div>
                        <img src="https://cdn-prd.ggac.com/ggac/submatch/ydbz/ydbz-part2-icon1.png" alt="" className="img1" />
                        <div className="num">10名</div>
                        <div className="wrap">
                            <div className="text3">
                                <div>奖金1000元</div>
                                <div>实体荣誉证书</div>
                                <div>受邀参加颁奖典礼</div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <div className="part3">
                <div className="title">
                    <div className="top">
                        <img src="https://cdn-prd.ggac.com/ggac/submatch/ydbz/ydbz-title-icon1.png" />
                        <span>03</span>
                    </div>
                    <img className="arrow" src="https://cdn-prd.ggac.com/ggac/submatch/ydbz/ydbz-title-icon2.png" alt="" />
                    <div className="title-word">
                        毕业生<img src="https://cdn-prd.ggac.com/ggac/submatch/ydbz/ydbz-title-icon4.png" alt="" />大本营
                    </div>
                    <img className="arrow" src="https://cdn-prd.ggac.com/ggac/submatch/ydbz/ydbz-title-icon3.png" alt="" />
                </div>
                <div className="border">
                    <img src="https://cdn-prd.ggac.com/ggac/submatch/ydbz/ydbz-part3-icon1.png" className="img1" />
                    <div className="sub-title">院校专题展</div>
                    <img src="https://cdn-prd.ggac.com/ggac/submatch/ydbz/ydbz-mobile-part3-icon.png" />
                    <div className="text">聚集创意，展现风采 —— 邀请校友共同创建独属于你们的院校专题展区</div>
                    <div className="text2">
                        <div>如何在G站快速发现校友组织？当然是——<span>开通独属你们的院校专题展区！</span></div>
                        <div>申请不设限，无论你是哪里的校友，点击下方链接<span>填写申请表</span>即可完成申请~再邀上同学们一起上传毕设作品，助力专题展区开通。</div>
                        <div>创建院校专区，享千元红包雨！前5个成功创建的院校专题展区，并上传毕设作品的所有同学，</div>
                        <div>将获得小G专属暗号，凭暗号进群后等待千元红包雨的降临吧！</div>
                        <div>同时，同一院校<span>作品数达50幅</span>，则该院校提交作品的同学<span>可瓜分500元奖池；作品数达100幅，</span>则该院校提交作品的同学<span>可瓜分1200元奖池</span>！</div>
                        <div>每个院校奖励单独发放，限前五个作品数量达到的院校。</div>
                    </div>
                    <div className="btns">
                        {
                            !isSchoolApply && <div className="btn" onClick={SchoolApply}>创建专题展</div>
                        }
                        {
                            isSchoolApply && <div className="btn" onClick={SchoolApply}>分享助力</div>
                        }
                        <div className="btn" onClick={showModal}>上传作品</div>
                        <div className="btn" onClick={() => window.open("/forum/index")}>嗑瓜子专用</div>
                    </div>
                    <div className="text3">
                        <div>小提示：上传作品时以【XXXX学院/大学】+作品名，可以更加快速的找到校友~</div>
                        <div>创建规则：当同一院校作品数达到30幅，即可开通G站院校专题展区。</div>
                    </div>
                </div>
                <div className="border border2">
                    <img src="https://cdn-prd.ggac.com/ggac/submatch/ydbz/ydbz-part3-icon2.png" className="img2" />
                    <div className="sub-title">毕业集市</div>
                    <img src="https://cdn-prd.ggac.com/ggac/submatch/ydbz/ydbz-part3-line.png" />
                    <div className="text">留下回忆，传递价值 —— 毕业集市，为即将毕业的同学提供一个便捷的线上交易平台</div>
                    <div className="text2">
                        <div>毕业集市，启动！快来开启创业之旅，赚取<span>“毕业的第一桶金”吧！</span></div>
                        <div><span>3步开店</span>有偿分享自制笔刷、源文件，出售壁纸、明信片、海报等作品周边，为自己的青春留下一份美好的回忆吧！</div>
                        <div>还可在此转手闲置的数位板、教材等创作用品，与需要它们的同学互利互惠~</div>
                        <div>限时福利：活动期间，<span>所有交易服务费降至1%</span>（不足1元的交易按0.01元收）。</div>
                    </div>
                    <div className="list">
                        <div className="list-item">
                            <div className="tip" onClick={toStoreList}>申请开店</div>
                            <div className="text4">
                                <div>完成实名认证后</div>
                                <div>点击开店申请即可成功开店</div>
                                <div>开店免费！</div>
                            </div>
                        </div>
                        <div className="list-item" onClick={toStoreList}>
                            <div className="tip">创建商品</div>
                            <div className="text4">
                                <div>前往【个人中心】—</div>
                                <div>【我的店铺】—【创建商品】</div>
                                <div>审核通过即可在店铺中上架商品。</div>
                            </div>
                        </div>
                        <div className="list-item">
                            <div className="tip">宣传店铺</div>
                            <div className="text4">
                                <div>在商品页点击分享商品，</div>
                                <div>即可获得专属于您的分享码。</div>
                            </div>
                        </div>
                    </div>
                    <div className="btn part2-btn" onClick={toApply}>毕业集市</div>
                    <div className="text3">
                        <div>结算说明：订单完成后，将在3个工作日内进行结算。</div>
                        <div>结算后，前往个人中心-我的钱包 可查看明细并提现。</div>
                    </div>
                </div>
            </div>
            <div className="part4">
                <div className="title">
                    <div className="top">
                        <img src="https://cdn-prd.ggac.com/ggac/submatch/ydbz/ydbz-title-icon1.png" />
                        <span>04</span>
                    </div>
                    <img className="arrow" src="https://cdn-prd.ggac.com/ggac/submatch/ydbz/ydbz-title-icon2.png" alt="" />
                    <div className="title-word">
                        作品<img src="https://cdn-prd.ggac.com/ggac/submatch/ydbz/ydbz-title-icon4.png" alt="" />要求
                    </div>
                    <img className="arrow" src="https://cdn-prd.ggac.com/ggac/submatch/ydbz/ydbz-title-icon3.png" alt="" />
                </div>
                <div className="text">
                    <div>1、作品需为原创，不得侵犯他人知识产权。</div>
                    <div>2、作品种类、形式不限，包括但不限于各类数字艺术（绘画/模型/动画/设计等），传统艺术作品（国板油雕），潮流艺术，互动装置，实验艺术等。</div>
                    <div>3、所有参赛作品一经提交，主办方有权用于宣传展示等非商业用途。</div>
                    <div>4、提交作品时，请附上作品说明，包括创作理念、制作过程及使用材料等。</div>
                    <div>5、团队共同创作作品可在创作人中添加其他参与者，每副作品最多可关联5位用户（其他用户需在GGAC完成注册）。</div>
                    <div>6、本次活动征集作品范围为2023年、2024年毕业设计。</div>
                </div>
                <img className="logo" src="https://cdn-prd.ggac.com/ggac/submatch/ydbz/ydbz-logo.png" alt="" />
            </div>
        </div>
        <Modal
            open={isModalSchool}
            onCancel={() => setIsModalSchool(false)}
            centered
            footer={<></>}
            destroyOnClose
        >
            <SchoolApplicant detail={detail} schoolApplyDetail={schoolApplyDetail} />
        </Modal>
        <YdbzApplicant detail={detail} isModalOpen={isModalOpen} handleCancel={handleCancel} />
    </YdbzDetailStyle>
}

export default YdbzDetail