import styled from "styled-components"

export const YdbzExhibitionStyle = styled.div`
    padding: ${props => props.theme.space.paddingLG * 2}px;
    .block {
        .title {
            text-align: center;
            font-weight: bold;
            font-size: 18px;
            margin-bottom: ${props => props.theme.space.marginLG}px;
        }
        .list {
            position: relative;
            margin-bottom: ${props => props.theme.space.margin}px;
            &-item {
                border: 1px solid rgb(228, 228, 228);
                border-radius: ${props => props.theme.border.borderRadius}px;
                .cover {
                    width: 100%;
                    background-size: cover;
                    aspect-ratio: 266 / 191;
                    background-position: center center;
                    cursor: pointer;
                }
                .list-bottom {
                    padding: ${props => props.theme.space.paddingXS}px ${props => props.theme.space.padding}px;
                    .name {
                        white-space: nowrap;
                        overflow: hidden;
                        text-overflow: ellipsis;
                        color: rgba(0, 0, 0, 0.88);
                        margin-bottom: ${props => props.theme.space.margin}px;
                        cursor: pointer;
                    }
                    .user {
                        display: flex;
                        gap: 10px;
                        align-items: center;
                        cursor: pointer;
                        &-avatar {
                            width: 30px;
                            height: 30px;
                            background-repeat: no-repeat;
                            background-size: cover;
                            background-position: center center;
                            border-radius: 50%;
                            z-index: 2;
                            flex: 0 0 auto;
                        }
                        &-name {
                            white-space: nowrap;
                            overflow: hidden;
                            text-overflow: ellipsis;
                            flex: 1;
                            min-width: 0;
                            font-size: 12px;
                        }
                    }
                }
            }
            .control {
                font-size: ${(props) => props.theme.fontSizes.fontSizeLG}px;
                border-radius: 50% 50%;
                width: 28px;
                height: 28px;
                background-color: #F1F1F3;
                display: flex;
                align-items: center;
                justify-content: center;
                cursor: pointer;
                position: absolute;
                top: 50%;
                transform: translateY(-50%);
                &.prev {
                  position: absolute;
                  top: 50%;
                  transform: translateY(-50%);
                  left: -35px;
                }
                &.next {
                  right: -35px;
                }
            }
        }

        .second-title {
            margin-top: 100px;
        }

        .exhibition {
            display: flex;
            gap: 40px;
            flex-wrap: wrap;
            &-item {
                width: calc((100% - 40px * 2) /3);
                border-radius: ${props => props.theme.border.borderRadius}px;
                cursor: pointer;
                .cover {
                    width: 100%;
                    aspect-ratio: 686 / 327;
                    background-size: cover;
                    background-position: center;
                    border-top-left-radius: ${props => props.theme.border.borderRadius}px;
                    border-top-right-radius: ${props => props.theme.border.borderRadius}px;
                }
                .bottom {
                    padding: ${props => props.theme.space.padding}px;
                    background: rgb(244, 244, 244);
                    border-bottom-left-radius: ${props => props.theme.border.borderRadius}px;
                    border-bottom-right-radius: ${props => props.theme.border.borderRadius}px;
                    .name {
                        color: rgb(0, 0, 0);
                        font-size: 16px;
                        white-space: nowrap;
                        overflow: hidden;
                        text-overflow: ellipsis;
                    }
                    .desc {
                        color: rgb(162, 162, 162);
                        box-sizing: border-box;
                        margin-top: 8px;
                        white-space: nowrap;
                        overflow: hidden;
                        text-overflow: ellipsis;
                        max-width: 70ch;
                        height: 25px;
                    }
                }
            }
        }
    }
`