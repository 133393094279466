import styled from "styled-components";

export const YlzyRuleStyle = styled.div`
.rule {
  background: url("https://cdn.ggac.com/img/ylzy-pc-rule-bg.png") center center no-repeat;
  background-size: 100% 100%;
  padding: 50px 0 270px;
  margin-top: -30px;
}

.title {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  margin-bottom: 100px;

  .number {
    width: 114px;
    height: 93px;

    display: flex;
    justify-content: center;
    align-items: center;
    background: linear-gradient(0deg, #FFFFFF 0%, #78CCFF 100%);
    -webkit-background-clip: text;
    -webkit-text-fill-color: transparent;
    font-size: 46px;
    font-family: 'XinYiJiXiangSong';
    position: relative;

    &::after {
      content: "";
      background: url("https://cdn.ggac.com/img/ylzy-pc-title-number.png") center center no-repeat;
      background-size: 100% 100%;
      width: 114px;
      height: 93px;
      position: absolute;
      top: 0;
      left: 0;
    }
  }

  .content {
    display: flex;
    justify-content: center;
    align-items: center;
    margin-top: -10px;

    .title-left,
    .title-right {
      width: 155px;
    }


    .word {
      margin: 0 50px;
      font-family: HGJXT;
      font-size: 70px;
      background: linear-gradient(0deg, #FFFFFF 0%, #78CCFF 100%);
      -webkit-background-clip: text;
      -webkit-text-fill-color: transparent;
    }
  }
}

.list {
  width: 1370px;
  height: 715px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  padding: 0 140px;
  background: url("https://cdn.ggac.com/img/ylzy-pc-rule-part-one.png") center center no-repeat;
  background-size: 100% 100%;
  margin: 0 auto 170px;

  span {
    font-weight: bold;
  }

  div {
    font-size: 20px;
    line-height: 40px;
    color: #002a5f;
    position: relative;
    margin-bottom: 20px;

    &:last-child {
      margin: 0;
    }

    span {
      cursor: pointer;
    }

    &::after {
      content: "";
      position: absolute;
      left: -20px;
      top: 15px;
      width: 12px;
      height: 11px;
      background: url("https://cdn.ggac.com/img/ylzy-pc-word-icon.png") center center no-repeat;
      background-size: 100% 100%;
    }
  }
}

.two {
  background: url("https://cdn.ggac.com/img/ylzy-pc-rule-part-two.png") center center no-repeat !important;
  background-size: 100% 100% !important;
  height: 585px !important;
  margin-bottom: 500px;
}

.three {
  background: url("https://cdn.ggac.com/img/ylzy-pc-rule-part-three.png") center center no-repeat !important;
  background-size: 100% 100% !important;
  height: 715px !important;
}

.four {
  background: url("https://cdn.ggac.com/img/ylzy-pc-rule-part-four.png") center center no-repeat !important;
  background-size: 100% 100% !important;
  height: 905px !important;
}
`