import { useOutletContext } from "react-router-dom"
import { Billboard2024WorkStyle } from "./style"
import { ContestDetailResponse } from "types/contest"
import { useEffect, useState } from "react"

const Billboard2024Work = () => {
    const { detail } = useOutletContext<{ detail: ContestDetailResponse }>()
    const [workList, setWorkList] = useState<{ name: string, id: number, list: { id: number, title: string, coverUrl: string, top: number }[] }[]>([])
    useEffect(() => {
        if (!detail) {
            return
        }
        const pageCfg = JSON.parse(detail.pageCfg)
        const work = pageCfg.billboard.work
        setWorkList(work)
    }, [detail])

    const toWorkDetail = (id: number) => {
        window.open(`https://www.ggac.com/work/detail/${id}`)
    }

    return <Billboard2024WorkStyle>
        <div className="inner">
            <div>
                {
                    workList.map((item) => {
                        return <div key={item.id}>
                            <div className="title-wrap">
                                <img src="https://cdn-prd.ggac.com/ggac/reg/2024/billboard/2024-billboard-title-left.png" />
                                <div className="title">{item.name}</div>
                                <img src="https://cdn-prd.ggac.com/ggac/reg/2024/billboard/2024-billboard-title-right.png" />
                            </div>
                            <div className="work">
                                {
                                    item.list.map(work => {
                                        return <div onClick={() => toWorkDetail(work.id)} className="work-item" key={work.id} style={{ backgroundImage: `url(${work.coverUrl})` }} />
                                    })
                                }
                            </div>
                        </div>
                    })
                }
            </div>
        </div>
    </Billboard2024WorkStyle>
}

export default Billboard2024Work