import styled from "styled-components";

export const YlzyDetailStyle = styled.div`
@font-face {
  font-family: 'BebasNeue';
  src: url('https://cdn-prd.ggac.com/ggac/font/BebasNeue-Regular.otf');
}

.challenge {
  background: url("https://cdn.ggac.com/img/ylzy-pc-bg.png") center center no-repeat;
  background-size: 100% 100%;
  padding-bottom: 200px;
  margin-top: -6px;

  .title {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    margin-bottom: 100px;

    .number {
      width: 114px;
      height: 93px;

      display: flex;
      justify-content: center;
      align-items: center;
      background: linear-gradient(0deg, #FFFFFF 0%, #78CCFF 100%);
      -webkit-background-clip: text;
      -webkit-text-fill-color: transparent;
      font-size: 46px;
      font-family: 'XinYiJiXiangSong';
      position: relative;

      &::after {
        content: "";
        background: url("https://cdn.ggac.com/img/ylzy-pc-title-number.png") center center no-repeat;
        background-size: 100% 100%;
        width: 114px;
        height: 93px;
        position: absolute;
        top: 0;
        left: 0;
      }
    }

    .content {
      display: flex;
      justify-content: center;
      align-items: center;
      margin-top: -10px;

      .title-left,
      .title-right {
        width: 155px;
      }

      .word {
        margin: 0 50px;
        font-family: HGJXT;
        font-size: 70px;
        background: linear-gradient(0deg, #FFFFFF 0%, #78CCFF 100%);
        -webkit-background-clip: text;
        -webkit-text-fill-color: transparent;
      }
    }
  }

  &-one {
    margin-bottom: 146px;

    .italic {
      font-style: italic;
      color: #fcffc8;
    }

    div:nth-child(3) {
      margin-bottom: 30px;
    }

    div {
      font-size: 22px;
      line-height: 45px;
      color: #c8ebff;
      text-align: center;
    }
  }

  &-two {
    margin-bottom: 130px;

    .date {
      .lines {
        display: flex;
        justify-content: center;
        align-items: center;

        img {
          height: 11px;
        }

        .number {
          width: 73px;
          height: 74px;
          background: url("https://cdn.ggac.com/img/ylzy-pc-date-number.png") center center no-repeat;
          background-size: 100% 100%;
          text-align: center;
          line-height: 64px;
          font-size: 25px;
          font-family: "XinYiJiXiangSong";
          color: #c8ebff;
          margin: 0 8px;
        }
      }

      .date-word {
        display: flex;
        justify-content: center;
        width: 885px;
        margin: 0 auto;

        &-item {
          flex: 1;
          text-align: center;
          font-size: 20px;
          color: #c8ebff;

          div {
            line-height: 34px;
            letter-spacing: 1px;
          }
        }
      }
    }
  }

  &-three {
    display: flex;
    justify-content: center;
    flex-wrap: wrap;
    gap: 150px;
    width: 1200px;
    margin: 0 auto 400px;

    &-item {
      width: 279px;
      height: 336px;
      background: url("https://cdn.ggac.com/img/ylzy-pc-prize.png") center center no-repeat;
      background-size: 100% 100%;
      padding-top: 60px;
      text-align: center;

      .prize-title {
        font-size: 38px;
        font-weight: bold;
        line-height: 34px;
        letter-spacing: 4px;
        color: #0c2b52;
        font-family: 'XinYiJiXiangSong';
        padding-left: 15px;
      }

      .prize-number {
        font-size: 24px;
        line-height: 34px;
        color: #0c2b52;
        margin: 26px 0 50px;
        padding-left: 15px;
      }

      .count {
        display: flex;
        font-family: 'BebasNeue';
        font-size: 66px;
        justify-content: center;
        background: linear-gradient(180deg, #07152B 0%, #124884 100%);
        -webkit-background-clip: text;
        -webkit-text-fill-color: transparent;

        div:first-child {
          font-size: 35px;
        }

        div {
          font-size: 46px;
          line-height: 34px;
          letter-spacing: 1px;
          color: #0f223e;
          margin-top: 20px;
        }
      }
    }
  }

  &-four {
    background: url("https://cdn.ggac.com/img/ylzy-pc-four-bg.png") center center no-repeat;
    background-size: 100% 100%;
    width: 1374px;
    height: 694px;
    margin: 0 auto 150px;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;

    .top {
      div {
        font-size: 20px;
        line-height: 42px;
        color: #002a5f;
        text-align: center;

        span {
          font-size: 28px;
        }
      }

      .red {
        color: #ba4600;
      }
    }

    .process {
      display: flex;
      justify-content: center;
      gap: 45px;
      margin: 65px 0 35px;
      position: relative;

      .process-img {
        height: 12px;
        position: absolute;
        top: 50%;
        transform: translateY(-50%);
        left: -74px;
        z-index: 1;
      }

      &>div {
        width: 206px;
        height: 48px;
        position: relative;
        z-index: 2;
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
        font-size: 17px;
        line-height: 25px;
        color: #002a5f;
        background: url("https://cdn.ggac.com/img/ylzy-pc-four-process.png") center center no-repeat;
        background-size: 100% 100%;

        div {
          line-height: 20px;
        }
      }

    }

    .tip {
      display: flex;
      justify-content: center;
      align-items: center;
      font-size: 20px;
      color: #002a5f;

      img {
        width: 12px;
        margin-right: 12px;
      }
    }

    .btn-wrap {
      display: flex;
      gap: 50px;
      justify-content: center;
    }

    .btn {
      margin-top: 48px;
      background: url("https://cdn.ggac.com/img/ylzy-pc-four-btn.png") center center no-repeat;
      background-size: 100% 100%;
      width: 231px;
      height: 60px;
      display: flex;
      justify-content: center;
      align-items: center;
      font-size: 24px;
      font-weight: bold;
      letter-spacing: 2px;
      color: #e1e4ed;
      font-family: 'XinYiJiXiangSong';
      cursor: pointer;
    }
  }

  &-five {
    display: flex;
    flex-direction: column;
    justify-content: center;
    width: 1374px;
    height: 576px;
    margin: 0 auto 150px;
    background: url("https://cdn.ggac.com/img/ylzy-pc-five-bg.png") center center no-repeat;
    background-size: 100% 100%;

    .word {
      padding-left: 125px;
      margin-bottom: 40px;
      position: relative;

      &::after {
        content: "";
        position: absolute;
        left: 105px;
        top: 12px;
        width: 12px;
        height: 11px;
        background: url("https://cdn.ggac.com/img/ylzy-pc-word-icon.png") center center no-repeat;
        background-size: 100% 100%;
      }

      div {
        font-size: 20px;
        line-height: 34px;
        color: #002a5f;
      }


    }

    .btn {
      margin-top: 48px;
      background: url("https://cdn.ggac.com/img/ylzy-pc-four-btn.png") center center no-repeat;
      background-size: 100% 100%;
      width: 231px;
      height: 60px;
      margin: 0 auto;
      display: flex;
      justify-content: center;
      align-items: center;
      font-size: 24px;
      font-weight: bold;
      letter-spacing: 2px;
      color: #e1e4ed;
      font-family: 'XinYiJiXiangSong';
      cursor: pointer;
    }
  }

  &-six {
    display: flex;
    justify-content: center;
    position: relative;
    margin-bottom: 150px;

    .images {
      height: 900px;
      display: block;
      margin: 0 auto;
      position: relative;
      z-index: 2;
    }

    &::after {
      content: "";
      width: 1273px;
      height: 430px;
      background: url("https://cdn.ggac.com/img/ylzy-pc-swiper-bg.png") center center no-repeat;
      background-size: 100% 100%;
      position: absolute;
      left: 50%;
      top: 50%;
      transform: translate(-50%, -50%);
    }

    .arrow {
      position: absolute;
      width: 1300px;
      display: flex;
      justify-content: space-between;
      align-items: center;
      top: 50%;
      transform: translateY(-50%);
      z-index: 999;

      img {
        height: 43px !important;
        cursor: pointer;
      }
    }

    .ant-carousel {
      height: 900px;
      width: 100%;
      position: relative;
      z-index: 1;
    }
  }

  &-seven {
    margin-bottom: 400px;

    .judge {
      display: flex;
      justify-content: center;
      gap: 120px;

      &-item {
        text-align: centere;

        .avatar {
          width: 240px;
          cursor: pointer;
          margin-bottom: 10px;
          cursor: pointer;
        }

        .name {
          font-size: 24px;
          line-height: 34px;
          color: #bce6ff;
          text-align: center;
          margin-bottom: 5px;
        }

        .desc {
          font-size: 20px;
          line-height: 26px;
          color: #fefefe;
          text-align: center;
        }
      }
    }
  }

  .list {
    display: flex;
    justify-content: center;
    gap: 45px;
    margin: 195px 0 170px;

    .to-top {
      margin-top: -126px;
    }

    &-item {
      padding-top: 65px;
      background: url("https://cdn.ggac.com/img/ylzy-pc-eight-border.png") center center no-repeat;
      background-size: 100% 100%;
      width: 327px;
      height: 424px;
      text-align: center;

      &-title {
        font-size: 38px;
        font-weight: bold;
        line-height: 34px;
        letter-spacing: 4px;
        color: #002a5f;
        font-family: "XinYiJiXiangSong";
      }

      .content {
        margin-top: 60px;

        div {
          font-size: 20px;
          line-height: 34px;
          color: #002a5f;
        }
      }
    }
  }

  .nine {
    .list-item {
      padding-top: 55px !important;
      height: 394px !important;
    }
  }

  &-ten {
    width: 1370px;
    height: 815px;
    display: flex;
    flex-direction: column;
    justify-content: center;
    padding-left: 140px;
    background: url("https://cdn.ggac.com/img/ylzy-pc-ten-bg.png") center center no-repeat;
    background-size: 100% 100%;
    margin: 0 auto;

    div {
      font-size: 20px;
      line-height: 56px;
      color: #002a5f;
      position: relative;

      &::after {
        content: "";
        position: absolute;
        left: -20px;
        top: 50%;
        transform: translateY(-50%);
        width: 12px;
        height: 11px;
        background: url("https://cdn.ggac.com/img/ylzy-pc-word-icon.png") center center no-repeat;
        background-size: 100% 100%;
      }
    }
  }

  .bottom {
    display: block;
    margin: 120px auto 0;
  }
}
`