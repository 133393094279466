import { Button, Input, message } from "antd"
import { SchoolApplicantStyle } from "./style"
import { useEffect, useState } from "react"
import { apiPostSubSchool } from "api/sub"
import { ContestDetailResponse } from "types/contest"
import copy from "copy-to-clipboard"
import { SchoolIsApplyResponse } from "types/sub/application"
import { getLocalUserInfo } from "utils/functions/common"


const SchoolApplicant = ({ detail, schoolApplyDetail }: { detail?: ContestDetailResponse, schoolApplyDetail?: SchoolIsApplyResponse }) => {

    const [school, setSchool] = useState("")

    const [success, setSuccess] = useState(false)

    const [isLoading, setIsLoading] = useState(false)

    const changeSchool = (e: string) => {
        setSchool(e)
    }

    const submit = () => {
        if (!school) {
            message.error("请填写要申请开通专题展的院校")
            return
        }
        setIsLoading(true)
        apiPostSubSchool({ contestId: Number(detail?.id), applyAcademy: school }).then(res => {
            setSuccess(true)
        }).finally(() => {
            setIsLoading(false)
        })
    }

    useEffect(() => {
        if (schoolApplyDetail && schoolApplyDetail.apply === 1) {
            setSuccess(true)
        }
        if (schoolApplyDetail && !!schoolApplyDetail.applyAcademy) {
            setSchool(schoolApplyDetail.applyAcademy)
        }
    }, [schoolApplyDetail])

    const copyText = () => {
        copy(`我正在申请${school}毕设作品专题展，快来上传作品助力开通! https://www.ggac.com/contest/ydbz/detail`)
        message.success("复制成功")
    }

    return <SchoolApplicantStyle>
        {
            (!success) && <div>
                <div className="tip">请填写要申请开通专题展的院校</div>
                <Input maxLength={35} onChange={(e) => changeSchool(e.target.value)} type="text" placeholder="例：XXXX学院/大学" />
                <Button type="primary" loading={isLoading} className="school-btn" onClick={submit}>提交</Button>
            </div>
        }
        {
            (success) && <div>
                <div className="tip">申请已提交，分享这段文案，为你院校助力吧</div>
                <div className="tip2">我正在申请{school}毕设作品专题展，快来上传作品助力开通!<br />https://www.ggac.com/contest/ydbz/detail</div>
                <Button type="primary" onClick={copyText} className="school-btn">一键复制</Button>
            </div>
        }
    </SchoolApplicantStyle>
}


export default SchoolApplicant