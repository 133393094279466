import { useEffect, useState } from "react"
import { UserNoticeStyle } from "./style"
import { getCollectNotice, postSaveNotice } from "api/user"
import { InfoCollectConfigItem } from "types/user"
import { Button, Form, Input, message } from "antd"
import { NoData } from "components/no-data"

const UserNotice = () => {

    type FormEdutParams = {
        name: string;
        key: string;
        value: string
    };


    const [list, setList] = useState<InfoCollectConfigItem[]>([])
    const [detail, setDetail] = useState<InfoCollectConfigItem>()
    const [currentId, setCurrentId] = useState(1)
    const [form] = Form.useForm<FormEdutParams>();
    useEffect(() => {
        getDetail()
    }, [])

    const getDetail = () => {
        getCollectNotice().then(res => {
            setList(res)
        })
    }

    useEffect(() => {
        const result = list.find((item) => item.id === currentId)
        if (!result) {
            return
        }
        setDetail(result)
        if (!result.info) {
            form.resetFields()
            return
        }
        const array = result.info.map(item => {
            return [item.key, item.value]
        })
        let content = Object.fromEntries(array);
        form.setFieldsValue({
            ...content
        })
    }, [currentId, list])



    const onFinish = (e: any) => {
        if (!detail) {
            return
        }
        const arr: { key: string, value: string, name: string }[] = []
        const labels = Object.keys(e)
        const values: string[] = Object.values(e)
        labels.forEach((item, index) => {
            arr.push({ key: item, name: detail.content[index].name, value: values[index] })
        })
        postSaveNotice({
            infoCollectConfigId: detail.id,
            info: arr
        }).then(() => {
            message.success("提交成功")
            getDetail()
        })
    }

    return <UserNoticeStyle>
        {
            list.length > 0 && <div className="tab">
                {
                    list.map(item => {
                        return <div onClick={() => setCurrentId(item.id)} className={`tab-item ${item.id === currentId ? 'active' : ''}`} key={item.id}>{item.name}</div>
                    })
                }
            </div>
        }
        {
            list.length === 0 && <>
                <NoData tips={"暂无收集信息"} />
            </>
        }

        {
            detail && <Form className="form" form={form} onFinish={onFinish}>
                {
                    detail.content.map(sub => {
                        return <Form.Item key={sub.key} name={sub.key} rules={[{ required: true, message: `${sub.name}必填` }]} label={sub.name}>
                            <Input.TextArea placeholder={`请输入${sub.name}`} />
                        </Form.Item>
                    })
                }
                <div className="btn">
                    <Button type="primary" htmlType="submit">{detail.isSubmited === 1 ? '修改' : '提交'}</Button>
                </div>
            </Form>
        }

    </UserNoticeStyle>
}

export default UserNotice