import styled from "styled-components"

export const CzwjWorkprizeStyle = styled.div`
@font-face {
  font-family: "FZXiJinLJW";
  src: url("https://cdn.ggac.com/v2/fonts/FZXiJinLJW.33937684.ttf");
}

@font-face {
  font-family: "FZXBSJW";
  src: url("https://cdn.ggac.com/v2/fonts/FZXBSJW.63b535d7.ttf");
}

@font-face {
  font-family: "SIMFANG";
  src: url("https://cdn.ggac.com/v2/fonts/SIMFANG.059f3a57.ttf");
}
.prize {
    font-family: "FZXIJINLJW";
    background: url("https://cdn-prd.ggac.com/ggac/submatch/czwj/czwj-pc-workprize-bg.jpg") center center
      no-repeat;
    background-size: 100% 100%;
    padding: 100px 0 150px;
    margin-top: -8px;
    .work-2 {
      flex-direction: column;
      align-items: center;
    }
    .work-3{
      .cover {
        width: 573px !important;
        height: 340px !important;
      }
    }
    .work-4 {
      gap: 20px !important;
      .work-item {
        width: 222px !important;
      }
      .work-title {
        padding: 15px;
        border-bottom: 1px solid #c08138;
      }
      .info {
        height: 56px !important;
        gap: 10px !important;
        padding: 0 10px !important;
      }
      .user_avatar_image {
        width: 24px !important;
        height: 24px !important;
      }
      .cover {
        width: 220px !important;
        height: 222px !important;
        background-repeat: no-repeat;
        background-size: cover !important;
      }
    }
    .work-5 {
      gap: 35px !important;
      margin-bottom: 0 !important;
      .cover {
        width: 374px !important;
        height: 330px !important;
      }
    }
    .work {
      display: flex;
      justify-content: center;
      gap: 50px;
      width: 1200px;
      margin: 0 auto 140px;
      flex-wrap: wrap;
      .work-item {
        border: solid 1px #c08138;
        border-radius: 8px;
        box-sizing: border-box;
      }
      .cover {
        width: 1200px;
	      height: 500px;
        background-size: 100%;
        border-top-right-radius: 8px;
        border-top-left-radius: 8px;
        cursor: pointer;
      }
      .info {
        height: 72px;
        display: flex;
        padding: 0 26px;
        align-items: center;
        gap: 20px;
        .user_avatar_image {
          width: 40px;
	        height: 40px;
          border-radius: 50%;
          background-size: cover;
          cursor: pointer;
        }
        .user-name {
          font-size: 16px;
          color: #a93c1d;
          cursor: pointer;
        }
      }
    }
}
.title {
    width: 854px;
    text-align: center;
    height: 161px;
    background: url("https://cdn.ggac.com/img/czwj_pc_title1.png") center center
      no-repeat;
    background-size: 100% 100%;
    padding-top: 55px;
    margin: 0 auto 36px;
    &-top {
      font-size: 58px;
      font-family: FZXBSJW;
      font-weight: normal;
      color: #a93c1d;
      letter-spacing: 17px;
      text-align: center;
      padding-left: 17px;
    }
    &-bottom {
      font-size: 19px;
      line-height: 38px;
      letter-spacing: 19px;
      color: #8d5b2b;
      padding-left: 19px;
      font-family: "SIMFANG";
    }
}
`