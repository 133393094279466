import styled from "styled-components";

export const CzwjRuleStyle = styled.div`

@font-face {
  font-family: "FZXiJinLJW";
  src: url("https://cdn.ggac.com/v2/fonts/FZXiJinLJW.33937684.ttf");
}

.rule {
  margin-top: -7px;
  font-family: "FZXiJinLJW";
  &-top {
    background: url("https://cdn.ggac.com/img/czwj_pc_bg1.jpg") center center
      no-repeat;
    background-size: 100% 100%;
    padding: 1px 0 250px;
    & > div {
      margin: 140px 0;
      text-align: center;
      .img {
        width: 1120px;
      }
    }
  }
  .rule-bottom {
    background: url("https://cdn.ggac.com/img/czwj_pc_bg2.png") center center
      no-repeat;
    background-size: 100% 100%;
    margin-top: -570px;
    padding: 600px 0 106px;
    .border {
      color: #0c180d !important;
      div div {
        color: #0c180d !important;
      }
    }
  }

  .title {
    width: 854px;
    text-align: center;
    height: 161px;
    background: url("https://cdn.ggac.com/img/czwj_pc_title1.png") center center
      no-repeat;
    background-size: 100% 100%;
    padding-top: 55px;
    margin: 100px auto 65px;
    &-top {
      font-size: 58px;
      font-family: FZXBSJW;
      font-weight: normal;
      color: #a93c1d;
      letter-spacing: 17px;
      text-align: center;
      padding-left: 17px;
    }
    &-bottom {
      font-size: 19px;
      line-height: 38px;
      letter-spacing: 19px;
      color: #8d5b2b;
      padding-left: 19px;
      font-family: "SIMFANG";
    }
  }

  .title2 {
    background: url("https://cdn.ggac.com/img/czwj_pc_title2.png") center center
      no-repeat !important;
    background-size: 100% 100% !important;
    .title-top {
      color: #3e4f40 !important;
    }
    .title-bottom {
      color: #3e4f40 !important;
    }
  }
  .border {
    width: 1120px;
    height: 600px;
    background: url("https://cdn.ggac.com/img/czwj_pc_rule_border1.png") center
      center no-repeat;
    background-size: 100% 100%;
    margin: 0 auto;
    display: flex;
    flex-direction: column;
    justify-content: center;
    padding: 0 35px;
    font-size: 18px;
    color: #703515;
    & > div {
      display: flex;
      line-height: 40px;
      text-align: left;
      div {
        line-height: 40px;
        color: #703515;
      }
      span {
        color: #a93c1d;
      }
      .bold {
        font-weight: bold;
        cursor: pointer;
      }
    }
    .word {
      background: url("https://cdn.ggac.com/img/czwj_pc_word1.png") center
        center no-repeat;
      background-size: 100% 100%;
      font-size: 18px;
      color: #a93c1d;
      line-height: 35px;
      display: flex;
      justify-content: center;
      width: 100%;
      margin-top: 35px;
      align-items: center;
    }
    .word3 {
      background: url("https://cdn.ggac.com/img/czwj_pc_word3.png") center
        center no-repeat !important;
      background-size: 100% 100% !important;
      flex-direction: column;
      height: 80px;
      div {
        line-height: 30px;
      }
    }
  }
  .border2 {
    background: url("https://cdn.ggac.com/img/czwj_pc_rule_border2.png") center
      center no-repeat !important;
    background-size: 100% 100% !important;
    height: 503px !important;
  }
  .border3 {
    height: auto !important;
    text-align: center;
    background: none !important;
    img {
      width: 1120px;
    }
  }
  .border4 {
    background: url("https://cdn.ggac.com/img/czwj_pc_rule_border4.png") center
      center no-repeat !important;
    background-size: 100% 100% !important;
    height: 731px;
  }
  .border5 {
    background: url("https://cdn.ggac.com/img/czwj_pc_rule_border5.png") center
      center no-repeat !important;
    background-size: 100% 100% !important;
    height: 661px;
  }
  .border6 {
    background: url("https://cdn.ggac.com/img/czwj_pc_rule_border6.png") center
      center no-repeat !important;
    background-size: 100% 100% !important;
    height: 651px;
  }
}
`