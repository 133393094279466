import { matchRoutes } from "./contest";
import { homeRoutes } from "./home";
import { shopRoutes } from "./shop";
import { workRoutes } from "./work";
import { articleRoutes } from "./article";
import { userRoutes } from "./user";
import { CreatorCenterRoutes } from "./user-creator-center";
import { GGAC } from '../views/ggac-base/index'
import { staticRoutes } from './static'
import { UserCenterRoutes } from "./user-center";
import { searchRoutes } from "./search";
import { discoverRoutes } from "./explore";
import { UserFrontPageRoutes } from "./user-front-page";
import { authWriterRoutes } from "./auth-writer";
import { eventRoutes } from "./events";
import { adminRoutes } from './admin'
import { mallRoutes } from "./mall";
import { scoreRoutes } from "./score";
import { albumRoutes } from "./album";
import { forumRoutes } from "./forum";
import { match2024Routes } from "./regulation/2024";

export const routes = [
  ...adminRoutes,
  ...homeRoutes,
  ...matchRoutes,
  ...shopRoutes,
  ...workRoutes,
  ...userRoutes,
  ...CreatorCenterRoutes,
  ...articleRoutes,
  ...searchRoutes,
  ...discoverRoutes,
  ...staticRoutes,
  ...UserCenterRoutes,
  ...UserFrontPageRoutes,
  ...authWriterRoutes,
  ...eventRoutes,
  ...mallRoutes,
  ...scoreRoutes,
  ...albumRoutes,
  ...forumRoutes,
  ...match2024Routes,
  {
    path: "/v3/*",
    element: <GGAC></GGAC>,
    // errorElement: <div></div>
  },
];
