import styled from "styled-components";

export const Billboard2024Style = styled.div`
    @font-face {
        font-family: 'nzgrKangxi';
        src: url('https://cdn-prd.ggac.com/ggac/event/custom-event/20231201-xnwf/rank/nzgrKangxi.ttf');
    }
    min-width: 1400px;
    .cover {
        img {
            width: 100%;
        }
    }

    background: url("https://cdn-prd.ggac.com/ggac/reg/2024/billboard/2024-billboard-bg.jpg") center center no-repeat;
    background-size: cover;
    margin-top: -6px;

    .bottom {
        img{
            width: 100%;
        }
    }
`