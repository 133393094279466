import { useNavigate } from "react-router-dom"
import { Billboard2024BannerStyle } from "./style"

const Billboard2024Banner = () => {
    const navigate = useNavigate()
    const toDetail = (value: string) => {
        navigate(value)
    }

    return <Billboard2024BannerStyle>
        <div className="cover">
            <img src="https://cdn-prd.ggac.com/ggac/reg/2024/billboard/2024-billboard-banner.jpg" alt="" />
        </div>
        <div className="content">
            <div className="tab">
                <div onClick={() => toDetail("/2024/billboard/work")} className={`${window.location.pathname === '/2024/billboard/work' ? 'active' : ''} tab-item`}>年度作品榜单</div>
                <div onClick={() => toDetail("/2024/billboard/user")} className={`${window.location.pathname === '/2024/billboard/user' ? 'active' : ''} tab-item`}>年度用户榜单</div>
            </div>
        </div>
    </Billboard2024BannerStyle>
}

export default Billboard2024Banner