import { useNavigate, useOutletContext } from "react-router-dom"
import { Ycwl2DetailStyle } from "./style"
import { ContestDetailResponse } from "types/contest"
const Ycwl2Detail = () => {
    const { detail } = useOutletContext<{ detail: ContestDetailResponse }>()
    const contestJSON = JSON.parse(detail?.pageCfg || "{}")
    const dataList: {
        dateList: { name: string[], date: string, id: number }[],
        themeList: { name: string[], icon: string, id: number }[],
        themeList2: { name: string[], id: number }[],
        priceList: { name: string, tip: string, tip2: string, id: number, list: { name: string[], size: string, num: string, id: number, tip: string }[] }[]
        ruleList: { name: string, id: number, list: { id: number, name: string[], des: string[] }[] }[],
        judgeList: { name: string, id: number, list: { name: string[], id: string, coverImage: string, desc: string[], desc_m: string[] }[] }[]
    } = contestJSON?.detail

    const navigate = useNavigate()

    const toRule = (id: number, sId: number) => {
        // const offsetTop = document.getElementById('tab')?.offsetTop!
        if (id === 1 && sId === 3) {
            window.open("http://ycwl.cheeringtech.cn:8000/main/home")
            return
        }
        window.scrollTo({
            top: 1700,
            behavior: "smooth"
        })
        navigate(`/contest/ycwl2/rule?id=${id}&subId=${sId}`)
    }

    const toDetail = (id: string) => {
        if (!id) {
            return
        }
        window.open(`/user/${id}`)
    }


    return <Ycwl2DetailStyle>
        <div className="detail">
            <div className="part-one">
                <div className="title">赛事简介</div>
                <div className="content">
                    <div>元宇宙让人类可以通过更加丰富多元的数字技术来表达梦想，</div>
                    <div>一个源于实体世界、却又高于实体世界的虚拟世界正在加速构建，虚实之间相生相融，改变着我们的日常生活与思想观念。</div>
                    <div>本届大赛以架空畅想的  <div className="bg">“元创未来”</div>  和紧扣现实的 <div className="bg"> “虚实共生” </div> 为主题，分设</div>
                    <div><span>「应用场景」</span>、<span>「视觉呈现」</span>、<span>「音乐创作」</span>、<span>「故事文学」</span>、<span>「元宇宙创新实验场」</span>等多维度、全方位的元宇宙创意赛题。</div>
                    <div style={{ marginTop: '60px' }}>旨在通过“产品+场景+设计”有机融合，与创作者们共同探索未来的可能，</div>
                    <div>为参赛者提供一个表达自我、对话行业、洞察未来的交流平台，</div>
                    <div>表彰一批在元宇宙设计创新领域具有标杆意义的顶尖人才，着力打造“元宇宙界的普利兹克奖”。</div>
                    <div className="tip">
                        <div>※ 所有参赛者均可免费参与本大赛，参赛者可自主选择参与单个/多个赛题。</div>
                        <div>参赛者可选择结合赛题全新创作作品，同时，凡符合赛题规则作品皆可参赛。</div>
                    </div>
                </div>
                <div className="date" id="date">
                    <div className="date-title">赛事赛程</div>
                </div>
                <div className="date-list">
                    {
                        dataList?.dateList.map(item => {
                            return <div className="date-list-item" key={item.id}>
                                {
                                    item.name.map(name => {
                                        return <div className="name" key={name}>{name}</div>
                                    })
                                }
                                <div className="time">{item.date}</div>
                            </div>
                        })
                    }
                </div>
                <div className="date-tip">注:元宇宙创新实验场参赛作品上传截止时间为:2024/01/31</div>
            </div>
            <div className="part-two" id="part-two">
                <div className="title">赛事组别</div>
                <div className="content">
                    <div className="border">
                        <div className="border-title">主题赛道</div>
                        <div className="border-list">
                            {
                                dataList?.themeList.map(item => {
                                    return <div className="border-item border-item2" onClick={() => toRule(1, item.id)} key={item.id}>
                                        {
                                            item.icon && <img src={item.icon} alt="" />
                                        }
                                        {
                                            item.name.map(name => {
                                                return <div className="name" key={name}>{name}</div>
                                            })
                                        }
                                        {
                                            item.id === 3 && <div className="word">
                                                <div>音乐主题赛道需前往元音声律官网报名及提交。</div>
                                                <div>点击上述赛道图标，一键前往。</div>
                                            </div>
                                        }
                                    </div>
                                })
                            }
                        </div>
                    </div>
                    <div className="border border2">
                        <div className="border-title">元宇宙创新实验场</div>
                        <div className="border-list">
                            {
                                dataList?.themeList2.map(item => {
                                    return <div className="border-item" onClick={() => toRule(2, item.id)} key={item.id}>
                                        {
                                            item.name.map(name => {
                                                return <div className="name" key={name}>{name}</div>
                                            })
                                        }
                                    </div>
                                })
                            }
                        </div>
                    </div>
                    <div className="border-tip">注:元宇宙创新实验场参赛作品上传截止时间为:2024/01/31</div>
                </div>
            </div>
            <div className="part-three">
                <div className="title">赛事奖金</div>
                <div className="list">
                    <div className="prize-list prize-one">
                        <div className="prize-list-item">
                            <div className="prize-title">WMDC元点奖 * 1名</div>
                            <div className="prize-num"><span>￥</span>200,000</div>
                            <div className="prize-tip2">※全赛题参赛作品共同角逐</div>
                        </div>
                    </div>
                    {
                        dataList?.priceList.map(item => {
                            return <div className="list-item" key={item.id}>
                                <div className="prize-name">{item.name}</div>
                                {
                                    item.tip && <div className="prize-tip">{item.tip}</div>
                                }
                                <div className={`prize-list prize${item.id}`}>
                                    {
                                        item.list.map(prize => {
                                            return <div className="prize-list-item" key={prize.id}>
                                                <div className="prize-title">{prize.name}</div>
                                                {
                                                    prize.size && <div className="prize-size">{prize.size}</div>
                                                }

                                                {
                                                    item.id === 4 && prize.id === 1 && <div className="prize-num"><span>￥</span>{prize.num}</div>
                                                }
                                                {
                                                    item.id === 4 && prize.id !== 1 && <div className="prize-num2" dangerouslySetInnerHTML={{ __html: prize.num }}></div>
                                                }
                                                {
                                                    item.id !== 4 && <div className="prize-num"><span>￥</span>{prize.num}</div>
                                                }
                                                {
                                                    prize.tip && <div className="prize-tip2">{prize.tip}</div>
                                                }
                                            </div>
                                        })
                                    }

                                </div>
                                {
                                    item.tip2 && <div className="prize-tip3" dangerouslySetInnerHTML={{ __html: item.tip2 }}></div>
                                }
                            </div>
                        })
                    }
                </div>
                <div className="prize-tip4">※奖项信息如有新增或调整，将另行公告</div>
            </div>
            <div className="part-four">
                <div className="title">赛事评委</div>
                <div className="judge">
                    {
                        dataList?.judgeList.map(item => {
                            return <div>
                                <div className="judge-title">
                                    {item.name}
                                </div>
                                <div className="judge-list">
                                    {
                                        item.list.map(sub => {
                                            return <div className="judge-list-item">
                                                <div className="cover-image" onClick={() => toDetail(sub.id)} style={{ backgroundImage: `url(${sub.coverImage})` }}>
                                                    {
                                                        sub.id !== '88573' && sub.coverImage !== 'https://cdn.ggac.com/img/ycwl2-judge500.png' && sub.name.map(name => {
                                                            return <div className="name">{name}</div>
                                                        })
                                                    }
                                                    {
                                                        sub.coverImage === 'https://cdn.ggac.com/img/ycwl2-judge500.png' && <div className="name second">加里·希曼<br />(Garry Schyman)</div>
                                                    }
                                                    {
                                                        sub.id === '88573' && <div className="name second">石头 · Crab<br />董一夫</div>
                                                    }
                                                </div>
                                                {
                                                    sub.desc.map(desc => {
                                                        return <div className="desc">
                                                            {desc}
                                                        </div>
                                                    })
                                                }
                                            </div>
                                        })
                                    }
                                </div>
                            </div>
                        })
                    }
                </div>
            </div>
            <div className="part-five">
                {
                    dataList?.ruleList.map(rule => {
                        return <div key={rule.id}>
                            <div className="title">{rule.name}</div>
                            <div className="rule">
                                {
                                    rule.list.map(item => {
                                        return <div className="rule-item" key={item.id}>
                                            <div className="label">
                                                {item.name && <div className="name">{item.name}</div>}
                                                {!item.name && <div className="num">{item.id}.</div>}
                                            </div>
                                            <div> {item.des}</div>
                                        </div>
                                    })
                                }
                            </div>
                        </div>
                    })
                }
                <div className="bottom">
                    <img src="https://cdn.ggac.com/img/ycwl2-pc-bottom-logo.png" alt="" />
                </div>
            </div>

        </div>
    </Ycwl2DetailStyle>
}

export default Ycwl2Detail