import { useOutletContext } from "react-router-dom"
import { YdbzExhibitionStyle } from "./style"
import { ContestDetailResponse } from "types/contest"
import { useKeenSlider } from "keen-slider/react"
import { RiArrowLeftSLine, RiArrowRightSLine } from "react-icons/ri"

const YdbzExhibition = () => {

    const { detail } = useOutletContext<{ detail: ContestDetailResponse }>()
    const contestJSON = JSON.parse(detail?.pageCfg || "{}")
    var dataList: {
        YdbzArticleList: { name: string, coverUrl: string, id: number, userInfo: { avatarUrl: string, id: number, username: string } }[],
        exhibitionList: { name: string, coverUrl: string, id: number, desc: string, isWebUrl: string, webUrl: string }[],
    } = {
        YdbzArticleList: contestJSON?.YdbzArticleList,
        exhibitionList: contestJSON?.exhibitionList
    }

    const [sliderRef, instanceRef] = useKeenSlider<HTMLDivElement>({
        mode: "free-snap",
        initial: 0,
        slides: {
            perView: 6,
            spacing: 20,
        }
    });

    const move = (type: string) => {
        if (type === "prev") {
            instanceRef.current?.prev();
        }
        if (type === "next") {
            console.log(instanceRef.current);
            instanceRef.current?.next();
        }
    };

    return <YdbzExhibitionStyle>
        {
            !!detail && <div className="block">
                <div className="title">GGAC毕业季专访</div>
                <div className="list">

                    <div ref={sliderRef} className=" keen-slider">
                        {
                            dataList.YdbzArticleList.map(item => {
                                return <div key={item.id} className="keen-slider__slide list-item">
                                    <div className="cover" onClick={() => window.open(`/article/detail/${item.id}`)} style={{ backgroundImage: `url(${item.coverUrl})` }} />
                                    <div className="list-bottom">
                                        <div className="name" onClick={() => window.open(`/article/detail/${item.id}`)} title={item.name}>{item.name}</div>
                                        <div className="user" onClick={() => window.open(`/user/${item.userInfo.id}`)}>
                                            <div className="user-avatar" style={{ backgroundImage: `url(${item.userInfo.avatarUrl})` }} />
                                            <div className="user-name" title={item.userInfo.username}>{item.userInfo.username}</div>
                                        </div>
                                    </div>
                                </div>
                            })
                        }
                    </div>
                    {
                        dataList.YdbzArticleList.length > 6 && <>
                            <div className="control prev" onClick={() => move("prev")}>
                                <RiArrowLeftSLine></RiArrowLeftSLine>
                            </div>
                            <div className="control next" onClick={() => move("next")}>
                                <RiArrowRightSLine></RiArrowRightSLine>
                            </div>
                        </>
                    }
                </div>
                <div className="title second-title">高校毕设线上展</div>
                <div className="exhibition">
                    {
                        dataList.exhibitionList.map(item => {
                            return <div className="exhibition-item" onClick={() => window.open(item.webUrl)} key={item.id}>
                                <div className="cover" style={{ backgroundImage: `url(${item.coverUrl})` }} />
                                <div className="bottom">
                                    <div className="name">{item.name}</div>
                                </div>
                            </div>
                        })
                    }
                </div>
            </div>
        }
    </YdbzExhibitionStyle>
}

export default YdbzExhibition