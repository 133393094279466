import { ContestDetailResponse } from "types/contest"
import { Ycwl2WorkprizeStyle } from "./style"
import { useEffect, useState } from "react"
import { useOutletContext } from "react-router-dom"

const Ycwl2Workprize = () => {
    const [tabList, setTabList] = useState<{ title: string, id: number }[]>([])
    const [selected, setSelected] = useState(0)
    const { detail } = useOutletContext<{ detail: ContestDetailResponse }>()
    const contestJSON = JSON.parse(detail?.pageCfg || "{}")
    const workprizeList: {
        title: string, id: number,
        prize: {
            name: string, id: number,
            work: { id: number, title: string, userId: number, username: string, coverUrl: string, avatarUrl: string }[]
        }[]
    }[] = contestJSON?.workprize || []


    const toPoint = (item: { title: string, id: number, }) => {
        const element = document.getElementById(`prize-${item.id}`) as HTMLElement;
        const workprize = document.querySelector(".workprize") as HTMLElement
        let scrollItem = element.offsetTop + workprize.offsetTop - 80

        window.scrollTo({
            top: scrollItem,
            behavior: "smooth"
        })
    }

    const onScroll = (e: any) => {
        let scrollItems = document.querySelectorAll("prize");
        let scrollTop = (document.documentElement.scrollTop || document.body.scrollTop || 0) - (window?.document?.getElementById("workprize")?.offsetTop || 0)
        if (scrollTop < 0) {
            setSelected(0)
            return
        }
        for (let i = scrollItems.length - 1; i >= 0; i--) {
            // 判断滚动条滚动距离是否大于当前滚动项可滚动距离
            console.log(i)
            let judge =
                scrollTop >=
                (scrollItems[i] as HTMLElement)?.offsetTop - (scrollItems[0] as HTMLElement)?.offsetTop - 20;
            if (judge) {
                setSelected(i + 1)
                break;
            }
        }
    }

    useEffect(() => {
        window.addEventListener('scroll', onScroll);
        return function () {
            window.removeEventListener('scroll', onScroll);
        };
    }, [])

    useEffect(() => {
        if (!detail) {
            return
        }
        const result = workprizeList.map(item => {
            return {
                title: item.title,
                id: item.id
            }
        })
        setTabList(result)
    }, [detail])

    return <Ycwl2WorkprizeStyle>
        {
            workprizeList && workprizeList.length > 0 && <div className="workprize">
                <div className="prize">
                    {
                        workprizeList.map(item => {
                            return <div className={`prize prize-${item.id}`} key={item.id} id={`prize-${item.id}`}>
                                <div className="title">
                                    <div className="word1">{item.title}</div>
                                </div>
                                {
                                    item.prize.map(sub => {
                                        return <div className="work" key={sub.id}>
                                            <div className={`${sub.work.length > 1 ? 'more-work' : ''}`}>
                                                <div className="sub-title">
                                                    <img src="https://cdn.ggac.com/img/ycwl-workprize-sub-zuo.png" alt="" />
                                                    {sub.name}
                                                    <img src="https://cdn.ggac.com/img/ycwl-workprize-sub-you.png" alt="" />
                                                </div>
                                                <div style={{ display: "flex", flexWrap: "wrap" }}>
                                                    {
                                                        sub.work.map(work => {
                                                            return <div className={`work-item ${sub.work.length === 1 ? 'single' : 'more'}`} key={work.id}>
                                                                <div className="work-item-top">
                                                                    <a href={`https://www.ggac.com/work/detail/${work.id}`} className="cover" style={{ backgroundImage: `url(${work.coverUrl})` }}></a>
                                                                    <div className="name">
                                                                        <a href={`https://www.ggac.com/user/${work.userId}`} style={{ backgroundImage: `url(${work.avatarUrl})` }} className="head" />
                                                                        <div className="user-name">
                                                                            <a href={`https://www.ggac.com/user/${work.userId}`} target="_blank">
                                                                                {work.username} 
                                                                            </a> - 
                                                                            <a href={`https://www.ggac.com/work/detail/${work.id}`} target="_blank"> 「{work.title}」</a>
                                                                        </div>
                                                                    </div>
                                                                </div>
                                                            </div>
                                                        })
                                                    }
                                                </div>
                                            </div>
                                        </div>
                                    })
                                }
                            </div>
                        })
                    }
                </div>
                <div className="tab">
                    {
                        tabList.map(item => {
                            return <div onClick={() => toPoint(item)} key={item.id} className={`tab-item ${selected == item.id ? 'active' : ''}`}>
                                {item.title}
                            </div>
                        })
                    }
                </div>
                {/* <div className="page-fjhScreenReport">
                    <div className="con-wrapper">
                        <PdfViewer filePath={"https://galaxy-prd-1257468630.cos.ap-shanghai.myqcloud.com/copyright/202408161430000184.pdf"} />
                    </div>
                </div>; */}
            </div>
        }
    </Ycwl2WorkprizeStyle>
}

export default Ycwl2Workprize